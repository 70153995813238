
import { Canvas } from '@react-three/fiber'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Suspense } from "react";
import { Model } from './Model1';
import { OrbitControls} from '@react-three/drei';

function Home1() {
  return (
    <div >
      <Canvas camera={{ position: [0, 20, 20]}} zoom={false}>
      <ambientLight />
      <pointLight position={[10, 10, 10]} intensity={3}/>
        <OrbitControls />
           <Suspense>
              <Model />
           </Suspense>
      </Canvas>
    </div>
  );
}

export default Home1;
