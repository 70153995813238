import logo from './logo.svg';
import './App.css';
import { Canvas } from '@react-three/fiber'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Suspense } from "react";
import { Model } from './pages/Model';
import { OrbitControls} from '@react-three/drei';
import { BrowserRouter as Router, Routes, Route, Navigate}
from 'react-router-dom';
import Navbar from './Components/Navbar';

import Home from './pages';
import Home1 from './pages/home1';
import Home2 from './pages/home2';


function App() {
  return (
    <Router >
   <Navbar />
      <Routes >
            
          <Route exact path='/' element={<Home/>}  />
          <Route path='/home1' element={<Home1/>} />
          <Route path='/home2' element={<Home2/>} />
          
          
          
      </Routes>
      </Router>
  );
}

export default App;
