
import { Canvas } from '@react-three/fiber'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Suspense } from "react";
import { Model } from './Model';
import { OrbitControls} from '@react-three/drei';

function Home() {
  return (
    <div >
      <Canvas camera={{ position: [0, 5, 30]}} zoom={false}>
      <ambientLight intensity={0.7}/>
      {/* <pointLight position={[10, 10, 10]} intensity={3}/> */}
      <OrbitControls enablePan={false}
                                         enableZoom={false}
                                         enableRotate={true}
                                         autoRotate={true}/>
           <Suspense>
              <Model />
           </Suspense>
      </Canvas>
    </div>
  );
}

export default Home;
