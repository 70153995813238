import { Canvas } from '@react-three/fiber'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Suspense } from "react";
import { useGLTF, PerspectiveCamera } from '@react-three/drei'


export function Model(props) {
  const { nodes, materials } = useGLTF('/1234567.gltf')
  return (
    <group {...props} dispose={null}>
      <group position={[3.27, 1.38, 19.26]} rotation={[0, 1.57, 0]} scale={[1, 0.99, 0.99]}>
        <mesh geometry={nodes['Door-case-A_011'].geometry} material={nodes['Door-case-A_011'].material} position={[0, -0.05, -0.01]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
        <mesh geometry={nodes.Object5606.geometry} material={nodes.Object5606.material} position={[0, -0.16, -0.11]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
      </group>
      <group position={[-0.32, 1.38, 19.26]} rotation={[0, 1.57, 0]} scale={[1, 0.99, 0.9]}>
        <mesh geometry={nodes['Door-case-A_014'].geometry} material={nodes['Door-case-A_014'].material} position={[0, -0.05, -0.01]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
        <mesh geometry={nodes.Object5609.geometry} material={nodes.Object5609.material} position={[0, -0.16, -0.11]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
      </group>
      <group position={[-2.4, 1.38, 19.26]} rotation={[0, 1.57, 0]} scale={[1, 0.99, 0.9]}>
        <mesh geometry={nodes['Door-case-A_015'].geometry} material={nodes['Door-case-A_015'].material} position={[0, -0.05, -0.01]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
        <mesh geometry={nodes.Object5610.geometry} material={nodes.Object5610.material} position={[0, -0.16, -0.11]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
      </group>
      <group position={[-3.69, 1.38, 20.81]} rotation={[0, 0, -Math.PI]} scale={[1, -0.99, 0.9]}>
        <mesh geometry={nodes['Door-case-A_018'].geometry} material={nodes['Door-case-A_018'].material} position={[-0.01, -0.05, -0.05]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
        <mesh geometry={nodes.Object5613.geometry} material={nodes.Object5613.material} position={[0, -0.16, -0.11]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
        <mesh geometry={nodes['Door-case-A_019'].geometry} material={nodes['Door-case-A_019'].material} position={[-0.01, -0.05, -0.01]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
        <mesh geometry={nodes['Door-case-A_020'].geometry} material={nodes['Door-case-A_020'].material} position={[-0.01, -0.05, -1.06]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
      </group>
      <group position={[4.9, 3.16, 20.53]} scale={[1.31, 1, 1]}>
        <mesh geometry={nodes.Box7289.geometry} material={nodes.Box7289.material} position={[0, 0.05, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973328.geometry} material={nodes.Box1578973328.material} position={[0, 0.04, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973329.geometry} material={nodes.Box1578973329.material} position={[0, 0.03, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973330.geometry} material={nodes.Box1578973330.material} position={[0, 0.01, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973331.geometry} material={nodes.Box1578973331.material} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973332.geometry} material={nodes.Box1578973332.material} position={[0, -0.01, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973333.geometry} material={nodes.Box1578973333.material} position={[0, -0.03, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973334.geometry} material={nodes.Box1578973334.material} position={[0, -0.04, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973335.geometry} material={nodes.Box1578973335.material} position={[0, -0.05, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box7293.geometry} material={nodes.Box7293.material} position={[0, 0, 0.64]} rotation={[0, 0, -Math.PI / 2]} />
        <mesh geometry={nodes.Box7294.geometry} material={nodes.Box7294.material} position={[0, 0, 0.32]} rotation={[0, 0, -Math.PI / 2]} />
        <mesh geometry={nodes.Box7295.geometry} material={nodes.Box7295.material} position={[0, 0, -0.34]} rotation={[0, 0, -Math.PI / 2]} />
        <mesh geometry={nodes.Box7296.geometry} material={nodes.Box7296.material} position={[0, 0, -0.66]} rotation={[0, 0, -Math.PI / 2]} />
        <mesh geometry={nodes.Rectangle2817.geometry} material={nodes.Rectangle2817.material} rotation={[0, 0, -Math.PI / 2]} />
      </group>
      <group position={[4.29, 0.69, 18.79]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.02, 1, 1]}>
        <mesh geometry={nodes.ARCLINEA_KITCHEN_RDdesign.geometry} material={nodes.ARCLINEA_KITCHEN_RDdesign.material} position={[-0.08, -0.1, -0.26]} rotation={[Math.PI / 2, 0, 0]} scale={[1.45, 0.46, 1.45]} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(1)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(1)'].material} position={[0, 0.14, 0.3]} rotation={[Math.PI / 2, 0, 0]} scale={9.98} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(2)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(2)'].material} position={[-0.08, 0.07, -0.27]} rotation={[Math.PI / 2, 0, 0]} scale={[1.45, 0.46, 1.45]} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(3)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(3)'].material} position={[0, 0.24, 0.29]} rotation={[Math.PI / 2, 0, 0]} scale={9.98} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(4)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(4)'].material} position={[0, -0.03, 0.28]} rotation={[Math.PI / 2, 0, 0]} scale={9.98} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(5)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(5)'].material} position={[0.18, 0.12, -0.27]} rotation={[Math.PI / 2, 0, 0]} scale={9.98} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(6)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(6)'].material} position={[0, -0.02, -0.27]} rotation={[-Math.PI / 2, 0, Math.PI]} scale={[2.32, 2.08, 2.32]} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(7)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(7)'].material} position={[0.08, -0.1, -0.26]} rotation={[Math.PI / 2, 0, 0]} scale={[1.45, 0.46, 1.45]} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(8)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(8)'].material} position={[0.08, 0.07, -0.27]} rotation={[Math.PI / 2, 0, 0]} scale={[1.45, 0.46, 1.45]} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(9)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(9)'].material} position={[0, -0.02, -0.27]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={[2.48, 2.66, 2.48]} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(10)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(10)'].material} position={[0, -0.01, 0]} scale={[9.98, 9.98, 7.04]} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(11)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(11)'].material} position={[0.05, 0.21, 0.29]} rotation={[Math.PI / 2, 0, 0]} scale={9.98} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(12)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(12)'].material} position={[0.11, 0.24, 0.28]} rotation={[Math.PI / 2, 0, 0]} scale={9.98} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(13)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(13)'].material} position={[0, 0.22, 0.27]} rotation={[Math.PI / 2, 0, 0]} scale={9.98} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(14)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(14)'].material} position={[0, -0.02, 0.28]} rotation={[Math.PI / 2, 0, 0]} scale={9.98} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(15)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(15)'].material} position={[-0.11, 0.24, 0.28]} rotation={[Math.PI / 2, 0, 0]} scale={9.98} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(16)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(16)'].material} position={[0, 0.24, 0.29]} rotation={[Math.PI / 2, 0, 0]} scale={9.98} />
        <mesh geometry={nodes['ARCLINEA_KITCHEN_RDdesign_(17)'].geometry} material={nodes['ARCLINEA_KITCHEN_RDdesign_(17)'].material} position={[0, 0, 0.27]} rotation={[Math.PI / 2, 0, 0]} scale={9.98} />
      </group>
      <group position={[-2.72, 1.65, 15.06]} scale={[0.95, 1, 0.95]}>
        <mesh geometry={nodes.Box7182.geometry} material={nodes.Box7182.material} position={[4.2, -0.74, 1.37]} />
      </group>
      <group position={[3.29, 2.07, 16.38]}>
        <group position={[0.48, -1.55, 0.02]} rotation={[0, -1.57, 0]} scale={[1.14, 1, 1]}>
          <mesh geometry={nodes.nogi.geometry} material={nodes.nogi.material} position={[0.08, -0.3, 0.47]} rotation={[Math.PI / 2, 0, 1.57]} />
          <mesh geometry={nodes.side_wood.geometry} material={nodes.side_wood.material} position={[0, 0.25, 0.02]} />
          <mesh geometry={nodes.char_side.geometry} material={nodes.char_side.material} position={[0, 0.25, 0.02]} />
        </group>
        <group position={[0.48, -1.55, -0.6]} rotation={[0, -1.57, 0]} scale={[1.14, 1, 1]}>
          <mesh geometry={nodes.nogi001.geometry} material={nodes.nogi001.material} position={[0.08, -0.3, 0.47]} rotation={[Math.PI / 2, 0, 1.57]} />
          <mesh geometry={nodes.side_wood001.geometry} material={nodes.side_wood001.material} position={[0, 0.25, 0.02]} />
          <mesh geometry={nodes.char_side001.geometry} material={nodes.char_side001.material} position={[0, 0.25, 0.02]} />
        </group>
        <group position={[0.48, -1.55, 0.64]} rotation={[0, -1.57, 0]} scale={[1.14, 1, 1]}>
          <mesh geometry={nodes.nogi002.geometry} material={nodes.nogi002.material} position={[0.08, -0.3, 0.47]} rotation={[Math.PI / 2, 0, 1.57]} />
          <mesh geometry={nodes.side_wood002.geometry} material={nodes.side_wood002.material} position={[0, 0.25, 0.02]} />
          <mesh geometry={nodes.char_side002.geometry} material={nodes.char_side002.material} position={[0, 0.25, 0.02]} />
        </group>
        <mesh geometry={nodes.Box7327.geometry} material={nodes.Box7327.material} position={[-0.25, -2.07, -1.07]} />
        <mesh geometry={nodes.Box7328.geometry} material={nodes.Box7328.material} position={[-0.25, -2.07, 0.98]} />
        <mesh geometry={nodes.Box7329.geometry} material={nodes.Box7329.material} position={[-0.25, -1.17, -0.05]} />
        <mesh geometry={nodes.Plane2446.geometry} material={nodes.Plane2446.material} position={[-0.26, -1.12, -0.06]} rotation={[-Math.PI, 0, 0]} scale={[1, -1, 1]} />
        <mesh geometry={nodes.Glass003.geometry} material={nodes.Glass003.material} position={[-0.19, -1.12, -0.67]} scale={0.84} />
        <mesh geometry={nodes.Glass004.geometry} material={nodes.Glass004.material} position={[-0.16, -1.12, -0.48]} scale={0.84} />
        <mesh geometry={nodes.Bottle002.geometry} material={nodes.Bottle002.material} position={[-0.23, -1.12, -0.52]} rotation={[Math.PI / 2, 0, Math.PI / 6]} scale={1.15} />
        <mesh geometry={nodes.Object10424685.geometry} material={nodes.Object10424685.material} position={[0.12, -2.02, -0.05]} rotation={[-Math.PI, 0, 0]} scale={[1, -1, 1]} />
        <mesh geometry={nodes.Garnet_032.geometry} material={nodes.Garnet_032.material} position={[-0.29, -1.02, 0.29]} rotation={[-2.59, 0.32, 2.75]} scale={1.26} />
        <mesh geometry={nodes.Sphere7293.geometry} material={nodes.Sphere7293.material} position={[-0.18, 0.88, 0.12]} rotation={[-1.33, 0.28, -2.55]} scale={0.76} />
      </group>
      <group position={[4.9, 3.16, 16.35]} scale={[1.31, 1, 1]}>
        <mesh geometry={nodes.Box1578973336.geometry} material={nodes.Box1578973336.material} position={[0, 0.04, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973337.geometry} material={nodes.Box1578973337.material} position={[0, 0.03, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973338.geometry} material={nodes.Box1578973338.material} position={[0, 0.01, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973339.geometry} material={nodes.Box1578973339.material} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973340.geometry} material={nodes.Box1578973340.material} position={[0, -0.01, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973341.geometry} material={nodes.Box1578973341.material} position={[0, -0.03, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973342.geometry} material={nodes.Box1578973342.material} position={[0, -0.04, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973343.geometry} material={nodes.Box1578973343.material} position={[0, -0.05, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973344.geometry} material={nodes.Box1578973344.material} position={[0, 0.05, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973345.geometry} material={nodes.Box1578973345.material} position={[0, 0, 0.64]} rotation={[0, 0, -Math.PI / 2]} />
        <mesh geometry={nodes.Box1578973346.geometry} material={nodes.Box1578973346.material} position={[0, 0, 0.32]} rotation={[0, 0, -Math.PI / 2]} />
        <mesh geometry={nodes.Box1578973347.geometry} material={nodes.Box1578973347.material} position={[0, 0, -0.34]} rotation={[0, 0, -Math.PI / 2]} />
        <mesh geometry={nodes.Box1578973348.geometry} material={nodes.Box1578973348.material} position={[0, 0, -0.66]} rotation={[0, 0, -Math.PI / 2]} />
        <mesh geometry={nodes.Rectangle65396.geometry} material={nodes.Rectangle65396.material} rotation={[0, 0, -Math.PI / 2]} />
      </group>
      <group position={[4.9, 3.16, 18.42]} scale={[1.31, 1, 1]}>
        <mesh geometry={nodes.Box1578973350.geometry} material={nodes.Box1578973350.material} position={[0, 0.04, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973351.geometry} material={nodes.Box1578973351.material} position={[0, 0.03, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973352.geometry} material={nodes.Box1578973352.material} position={[0, 0.01, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973353.geometry} material={nodes.Box1578973353.material} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973354.geometry} material={nodes.Box1578973354.material} position={[0, -0.01, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973355.geometry} material={nodes.Box1578973355.material} position={[0, -0.03, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973356.geometry} material={nodes.Box1578973356.material} position={[0, -0.04, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973357.geometry} material={nodes.Box1578973357.material} position={[0, -0.05, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973358.geometry} material={nodes.Box1578973358.material} position={[0, 0.05, 0]} rotation={[0, 0, -1.04]} scale={[1.17, 1.06, 1]} />
        <mesh geometry={nodes.Box1578973359.geometry} material={nodes.Box1578973359.material} position={[0, 0, 0.64]} rotation={[0, 0, -Math.PI / 2]} />
        <mesh geometry={nodes.Box1578973360.geometry} material={nodes.Box1578973360.material} position={[0, 0, 0.32]} rotation={[0, 0, -Math.PI / 2]} />
        <mesh geometry={nodes.Box1578973361.geometry} material={nodes.Box1578973361.material} position={[0, 0, -0.34]} rotation={[0, 0, -Math.PI / 2]} />
        <mesh geometry={nodes.Box1578973362.geometry} material={nodes.Box1578973362.material} position={[0, 0, -0.66]} rotation={[0, 0, -Math.PI / 2]} />
        <mesh geometry={nodes.Rectangle65397.geometry} material={nodes.Rectangle65397.material} rotation={[0, 0, -Math.PI / 2]} />
      </group>
      <group position={[1.35, 1.12, 16.68]}>
        <group position={[0, 0.02, 0.07]}>
          <mesh geometry={nodes.Object10424803.geometry} material={nodes.Object10424803.material} position={[-0.3, 0.35, 2.41]} />
          <mesh geometry={nodes.Object10424787.geometry} material={nodes.Object10424787.material} position={[-0.4, 0.35, -1.62]} rotation={[0, -1.57, 0]} />
          <mesh geometry={nodes.Plane1864.geometry} material={nodes.Plane1864.material} position={[-0.39, 0.83, -0.32]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh geometry={nodes.Plane4255.geometry} material={nodes.Plane4255.material} position={[0.55, 0.83, 2.42]} rotation={[Math.PI / 2, 0, Math.PI]} />
        </group>
        <group position={[0, -0.3, 0.02]}>
          <mesh geometry={nodes.Object10424814.geometry} material={nodes.Object10424814.material} position={[-0.16, 0.09, 1.17]} rotation={[Math.PI, Math.PI / 2, 0]} scale={[0.88, -1, 0.88]} />
          <mesh geometry={nodes.Object10424815.geometry} material={nodes.Object10424815.material} position={[-0.16, 0.09, 1.55]} rotation={[Math.PI, Math.PI / 2, 0]} scale={[0.88, -1, 0.88]} />
          <mesh geometry={nodes.Object10424725.geometry} material={nodes.Object10424725.material} position={[-0.11, -0.82, -0.52]} />
          <mesh geometry={nodes.Object10424802.geometry} material={nodes.Object10424802.material} position={[1.54, -0.82, 2.17]} rotation={[0, 1.57, 0]} />
        </group>
      </group>
      <mesh geometry={nodes.Walls.geometry} material={nodes.Walls.material} position={[2.29, 0, 22.78]} />
      <mesh geometry={nodes.Plane2309.geometry} material={nodes.Plane2309.material} position={[4.48, 0, 10.19]} scale={[0.89, 1, 0.93]} />
      <mesh geometry={nodes.Box7246.geometry} material={nodes.Box7246.material} position={[4.84, 0, 16.35]} />
      <mesh geometry={nodes.Box7247.geometry} material={nodes.Box7247.material} position={[4.73, 0, 16.35]} />
      <mesh geometry={nodes.Line1789534682.geometry} material={nodes.Line1789534682.material} position={[3.58, 0, 15.83]} />
      <mesh geometry={nodes.Plane2313.geometry} material={nodes.Plane2313.material} position={[3.85, 0, 10.24]} scale={[0.98, 1, 0.67]} />
      <mesh geometry={nodes.Plane2314.geometry} material={nodes.Plane2314.material} position={[3.23, 0, 10.27]} scale={[0.22, 1, 0.29]} />
      <mesh geometry={nodes.Object5615.geometry} material={nodes.Object5615.material} position={[4.91, 1.26, 16.12]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[1, -1, 1]} />
      <mesh geometry={nodes.door_knob_006.geometry} material={nodes.door_knob_006.material} position={[2.98, 1.13, 19.24]} rotation={[-Math.PI, 0, 0]} scale={[0.13, -0.13, 0.13]} />
      <mesh geometry={nodes.door_knob_007.geometry} material={nodes.door_knob_007.material} position={[2.98, 1.13, 19.27]} scale={0.13} />
      <mesh geometry={nodes.door_knob_008.geometry} material={nodes.door_knob_008.material} position={[-0.59, 1.13, 19.26]} scale={0.13} />
      <mesh geometry={nodes.door_knob_009.geometry} material={nodes.door_knob_009.material} position={[-2.67, 1.13, 19.26]} scale={0.13} />
      <mesh geometry={nodes.door_knob_011.geometry} material={nodes.door_knob_011.material} position={[-3.68, 1.13, 20.37]} rotation={[-Math.PI, 1.57, 0]} scale={[0.13, -0.13, 0.13]} />
      <mesh geometry={nodes.door_knob_012.geometry} material={nodes.door_knob_012.material} position={[-2.67, 1.13, 21.78]} rotation={[-Math.PI, 0, 0]} scale={[0.13, -0.13, 0.13]} />
      <mesh geometry={nodes.door_knob_014.geometry} material={nodes.door_knob_014.material} position={[1.65, 1.13, 21.78]} rotation={[Math.PI, 0, Math.PI]} scale={0.13} />
      <mesh geometry={nodes.door_knob_015.geometry} material={nodes.door_knob_015.material} position={[5.79, 1.13, 23.18]} rotation={[Math.PI, 0, Math.PI]} scale={0.13} />
      <mesh geometry={nodes.door_knob_017.geometry} material={nodes.door_knob_017.material} position={[4.87, 1.28, 26.51]} rotation={[0, Math.PI / 2, 0]} scale={0.13} />
      <mesh geometry={nodes['Door-case-A_021'].geometry} material={nodes['Door-case-A_021'].material} position={[4.8, 1.32, 16.69]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.89, -1, 0.99]} />
      <mesh geometry={nodes['Door-case-A_022'].geometry} material={nodes['Door-case-A_022'].material} position={[4.8, 1.32, 16.01]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.89, 1, 0.99]} />
      <mesh geometry={nodes.floor.geometry} material={nodes.floor.material} position={[-2.33, 0, 22.71]} rotation={[0, -1.57, 0]} />
      <mesh geometry={nodes.Object5591.geometry} material={nodes.Object5591.material} position={[3.45, 1.87, 12.71]} rotation={[Math.PI / 2, 0, 0]} scale={[1.12, 1.25, 1.02]} />
      <mesh geometry={nodes.Box3837.geometry} material={nodes.Box3837.material} position={[3.59, 1.9, 12.72]} rotation={[Math.PI / 2, 0, 0]} scale={[1.12, 1.25, 1.02]} />
      <mesh geometry={nodes.Plane2442.geometry} material={nodes.Plane2442.material} position={[2.46, 0, 10.24]} scale={[0.98, 1, 0.67]} />
      <mesh geometry={nodes.Plane2443.geometry} material={nodes.Plane2443.material} position={[2.36, 0, 10.75]} scale={[0.98, 1, 0.67]} />
      <mesh geometry={nodes.Line1789534794.geometry} material={nodes.Line1789534794.material} position={[4.82, 3.04, 16.39]} rotation={[0, -1.57, 0]} />
      <mesh geometry={nodes.Line1789534795.geometry} material={nodes.Line1789534795.material} position={[4.82, 3.04, 20.56]} rotation={[0, -1.57, 0]} />
      <mesh geometry={nodes.Box7307.geometry} material={nodes.Box7307.material} position={[4.9, 3.16, 20.53]} rotation={[Math.PI / 2, 0, 1.57]} />
      <mesh geometry={nodes.Box1578973349.geometry} material={nodes.Box1578973349.material} position={[4.9, 3.16, 16.36]} rotation={[Math.PI / 2, 0, 1.57]} />
      <mesh geometry={nodes.Box1578973363.geometry} material={nodes.Box1578973363.material} position={[4.9, 3.16, 18.42]} rotation={[Math.PI / 2, 0, 1.57]} />
      <mesh geometry={nodes['234236'].geometry} material={nodes['234236'].material} position={[4.29, 1.24, 18.63]} rotation={[Math.PI / 2, 0, -Math.PI]} scale={[1.02, 1, 1]} />
      <mesh geometry={nodes.reh_3a001.geometry} material={nodes.reh_3a001.material} position={[-0.7, 0.23, 13.37]} rotation={[0, Math.PI / 2, 0]} />
      <mesh geometry={nodes.Eichholtz_Architecture_01_frame001.geometry} material={nodes.Eichholtz_Architecture_01_frame001.material} position={[4.66, 2.26, 14.86]} rotation={[0, -1.57, 0]} scale={[0.78, 0.78, 1]} />
      <mesh geometry={nodes.Eichholtz_Architecture_01_glass001.geometry} material={nodes.Eichholtz_Architecture_01_glass001.material} position={[4.66, 2.26, 14.86]} rotation={[0, -1.57, 0]} />
      <mesh geometry={nodes.Eichholtz_Architecture_02_glass001.geometry} material={nodes.Eichholtz_Architecture_02_glass001.material} position={[4.66, 2.26, 17.88]} rotation={[0, -1.57, 0]} />
      <mesh geometry={nodes.Eichholtz_Architecture_02_frame001.geometry} material={nodes.Eichholtz_Architecture_02_frame001.material} position={[4.66, 2.26, 17.88]} rotation={[0, -1.57, 0]} scale={[0.78, 0.78, 1]} />
      <mesh geometry={nodes.Eichholtz_Architecture_03_glass001.geometry} material={nodes.Eichholtz_Architecture_03_glass001.material} position={[4.66, 1.4, 14.86]} rotation={[0, -1.57, 0]} />
      <mesh geometry={nodes.Eichholtz_Architecture_04_glass001.geometry} material={nodes.Eichholtz_Architecture_04_glass001.material} position={[4.66, 1.4, 17.88]} rotation={[0, -1.57, 0]} />
      <mesh geometry={nodes.Eichholtz_Architecture_04_frame001.geometry} material={nodes.Eichholtz_Architecture_04_frame001.material} position={[4.66, 1.4, 17.88]} rotation={[0, -1.57, 0]} scale={[0.78, 0.78, 1]} />
      <mesh geometry={nodes.Eichholtz_Architecture_03_frame001.geometry} material={nodes.Eichholtz_Architecture_03_frame001.material} position={[4.66, 1.4, 14.86]} rotation={[0, -1.57, 0]} scale={[0.78, 0.78, 1]} />
      <mesh geometry={nodes.Object10424674.geometry} material={nodes.Object10424674.material} position={[1.28, 1, 17.41]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={1.06} />
      <mesh geometry={nodes.AM109_019_016.geometry} material={nodes.AM109_019_016.material} position={[4.91, 0.04, 12.79]} rotation={[0, 1.57, 0]} scale={[0.96, 1, 1]} />
      <mesh geometry={nodes.Box7311.geometry} material={nodes.Box7311.material} position={[4.97, 0.08, 13.19]} scale={[1, 1, 0.96]} />
      <mesh geometry={nodes.Line1789534797.geometry} material={nodes.Line1789534797.material} position={[3.4, 3.06, 17.4]} />
      <mesh geometry={nodes.Box7315.geometry} material={nodes.Box7315.material} position={[2.72, 2.81, 18.84]} />
      <mesh geometry={nodes.Box7319.geometry} material={nodes.Box7319.material} position={[2.25, 0, 18.83]} />
      <mesh geometry={nodes.Box7320.geometry} material={nodes.Box7320.material} position={[4.32, 0, 18.83]} />
      <mesh geometry={nodes.Box7322.geometry} material={nodes.Box7322.material} position={[-0.16, 0, 18.83]} />
      <mesh geometry={nodes.Box7323.geometry} material={nodes.Box7323.material} position={[1.29, 1.52, 18.83]} />
      <mesh geometry={nodes.Box7325.geometry} material={nodes.Box7325.material} position={[1.06, 0, 16.34]} />
      <mesh geometry={nodes.Box7326.geometry} material={nodes.Box7326.material} position={[1.17, 1.47, 16.36]} />
      <mesh geometry={nodes.ARCLINEA_KITCHEN_RDdesign047.geometry} material={nodes.ARCLINEA_KITCHEN_RDdesign047.material} position={[1, 0.93, 18.23]} rotation={[0, 1.57, 0]} scale={1.03} />
      <mesh geometry={nodes.Plane4258.geometry} material={nodes.Plane4258.material} position={[3.91, 0, 9.83]} scale={[0.89, 1, 0.93]} />
      <mesh geometry={nodes.Box1578973364.geometry} material={nodes.Box1578973364.material} position={[7.56, 0, 22.64]} />
      <mesh geometry={nodes.Line1789534866.geometry} material={nodes.Line1789534866.material} position={[4.84, 0.99, 16.41]} rotation={[Math.PI / 2, 0, -Math.PI]} />
      <mesh geometry={nodes.Line1789534867.geometry} material={nodes.Line1789534867.material} position={[4.84, 0.99, 16.29]} rotation={[Math.PI / 2, 0, -Math.PI]} />
      <mesh geometry={nodes.Line1789534868.geometry} material={nodes.Line1789534868.material} position={[4.78, 0.99, 16.41]} rotation={[Math.PI / 2, 0, 0]} scale={[1, -1, 1]} />
      <mesh geometry={nodes.Line1789534869.geometry} material={nodes.Line1789534869.material} position={[4.78, 0.99, 16.29]} rotation={[Math.PI / 2, 0, 0]} scale={[1, -1, 1]} />
      <mesh geometry={nodes.Plane4328.geometry} material={nodes.Plane4328.material} position={[4.88, 2.77, 13.18]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[2.26, 1.52, 2.26]} />
      <mesh geometry={nodes.Box1578973694.geometry} material={nodes.Box1578973694.material} position={[2.25, 0, 13.45]} />
      <mesh geometry={nodes.Plane4329.geometry} material={nodes.Plane4329.material} position={[1.25, 1.2, 14.23]} rotation={[Math.PI / 2, 0, -Math.PI]} scale={[1, -1, 1]} />
      <mesh geometry={nodes.Plane4330.geometry} material={nodes.Plane4330.material} position={[2.46, 0, 15.91]} />
      <mesh geometry={nodes.FLOOR.geometry} material={nodes.FLOOR.material} position={[3.69, 0, 17.14]} rotation={[0, 0, -Math.PI]} scale={[1, -1, 1]} />
      <mesh geometry={nodes.Object10424804.geometry} material={nodes.Object10424804.material} position={[2.25, 0.96, 18.5]} rotation={[0, 1.57, 0]} />
      <mesh geometry={nodes.Object10424810.geometry} material={nodes.Object10424810.material} position={[4.11, 1.68, 18.5]} rotation={[0, 1.57, 0]} />
      <mesh geometry={nodes.Line1789534923.geometry} material={nodes.Line1789534923.material} position={[1.59, 0.84, 17.65]} />
      <mesh geometry={nodes.Line1789534924.geometry} material={nodes.Line1789534924.material} position={[3.4, 0.84, 17.65]} />
      <mesh geometry={nodes.Line1789534925.geometry} material={nodes.Line1789534925.material} position={[2.7, 0.84, 17.65]} />
      <mesh geometry={nodes.Box1578973698.geometry} material={nodes.Box1578973698.material} position={[2.63, 0.86, 17.04]} />
      <mesh geometry={nodes.Box1578973699.geometry} material={nodes.Box1578973699.material} position={[0.84, 2.81, 18.84]} />
      <mesh geometry={nodes.Box1578973700.geometry} material={nodes.Box1578973700.material} position={[3.18, 2.86, 18.84]} />
      <mesh geometry={nodes.Box1578973701.geometry} material={nodes.Box1578973701.material} position={[4.36, 0, 16.34]} />
      <mesh geometry={nodes.Object10424873.geometry} material={nodes.Object10424873.material} position={[3.04, 0.95, 16.33]} rotation={[-Math.PI, 0, 0]} scale={[1, -1, 1]} />
      <mesh geometry={nodes.hydrangea_042.geometry} material={nodes.hydrangea_042.material} position={[1.29, 1.15, 14.68]} rotation={[Math.PI, -Math.PI / 6, Math.PI]} />
      <mesh geometry={nodes.Line1789534969.geometry} material={nodes.Line1789534969.material} position={[3.34, 0.44, 13.57]} rotation={[Math.PI, -0.7, Math.PI]} scale={[1, 1, 0.91]} />
      <mesh geometry={nodes.Object10424876.geometry} material={nodes.Object10424876.material} position={[1.65, 0.5, 16.39]} rotation={[0, 1.57, 0]} />
      <mesh geometry={nodes.Box1578973702.geometry} material={nodes.Box1578973702.material} position={[2.25, -0.13, 18.83]} />
      <mesh geometry={nodes.Box1578973703.geometry} material={nodes.Box1578973703.material} position={[4.9, 1.32, 16.35]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
      <mesh geometry={nodes['Stellanova_(part_3)_159'].geometry} material={nodes['Stellanova_(part_3)_159'].material} position={[2.85, 3.06, 16.4]} rotation={[0, -1.57, 0]} />
      <mesh geometry={nodes.Plane4331.geometry} material={nodes.Plane4331.material} position={[5, 1.45, 13.17]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
    </group>
  )
}