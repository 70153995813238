import React, { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Suspense } from "react";
import { useGLTF, PerspectiveCamera, useAnimations } from '@react-three/drei'


export function Model(props) {
    const group = useRef()
    const { nodes, materials, animations } = useGLTF('/123.gltf')
    const { actions } = useAnimations(animations, group)
    return (
      <group ref={group} {...props} dispose={null}>
        <group>
          <group name="Window015" position={[-0.12, 2.57, 2.37]} scale={0.68}>
            <mesh name="Object5570" geometry={nodes.Object5570.geometry} material={nodes.Object5570.material} position={[0.41, 0.33, 0.03]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, 1.25, 1]} />
            <mesh name="Object5571" geometry={nodes.Object5571.geometry} material={nodes.Object5571.material} position={[0.45, 1.71, 0]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1.25, 1]} />
            <mesh name="Object5572" geometry={nodes.Object5572.geometry} material={nodes.Object5572.material} position={[0.34, 1.67, 0.03]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={[1, 1.25, 1]} />
          </group>
          <group name="Stellanova_(part_1)_029" position={[1.24, 3.34, 1.3]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh name="Stellanova_(part_1)_015" geometry={nodes['Stellanova_(part_1)_015'].geometry} material={nodes['Stellanova_(part_1)_015'].material} position={[0, 0, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_014" geometry={nodes['Stellanova_(part_1)_014'].geometry} material={nodes['Stellanova_(part_1)_014'].material} position={[0, 0, -0.02]} />
            <mesh name="Stellanova_(part_1)_012" geometry={nodes['Stellanova_(part_1)_012'].geometry} material={nodes['Stellanova_(part_1)_012'].material} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_011" geometry={nodes['Stellanova_(part_1)_011'].geometry} material={nodes['Stellanova_(part_1)_011'].material} position={[0, 0, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_010" geometry={nodes['Stellanova_(part_1)_010'].geometry} material={nodes['Stellanova_(part_1)_010'].material} position={[0, 0, 0.03]} rotation={[-Math.PI / 2, 0, 0]} />
          </group>
          <group name="Stellanova_(part_1)_040" position={[-1.66, 3.34, 1.37]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh name="Stellanova_(part_1)_038" geometry={nodes['Stellanova_(part_1)_038'].geometry} material={nodes['Stellanova_(part_1)_038'].material} position={[0, 0, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_037" geometry={nodes['Stellanova_(part_1)_037'].geometry} material={nodes['Stellanova_(part_1)_037'].material} position={[0, 0, -0.02]} />
            <mesh name="Stellanova_(part_1)_035" geometry={nodes['Stellanova_(part_1)_035'].geometry} material={nodes['Stellanova_(part_1)_035'].material} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_034" geometry={nodes['Stellanova_(part_1)_034'].geometry} material={nodes['Stellanova_(part_1)_034'].material} position={[0, 0, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_033" geometry={nodes['Stellanova_(part_1)_033'].geometry} material={nodes['Stellanova_(part_1)_033'].material} position={[0, 0, 0.03]} rotation={[-Math.PI / 2, 0, 0]} />
          </group>
          <group name="Stellanova_(part_1)_048" position={[1.24, 3.34, -0.04]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh name="Stellanova_(part_1)_046" geometry={nodes['Stellanova_(part_1)_046'].geometry} material={nodes['Stellanova_(part_1)_046'].material} position={[0, 0, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_045" geometry={nodes['Stellanova_(part_1)_045'].geometry} material={nodes['Stellanova_(part_1)_045'].material} position={[0, 0, -0.02]} />
            <mesh name="Stellanova_(part_1)_043" geometry={nodes['Stellanova_(part_1)_043'].geometry} material={nodes['Stellanova_(part_1)_043'].material} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_042" geometry={nodes['Stellanova_(part_1)_042'].geometry} material={nodes['Stellanova_(part_1)_042'].material} position={[0, 0, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_041" geometry={nodes['Stellanova_(part_1)_041'].geometry} material={nodes['Stellanova_(part_1)_041'].material} position={[0, 0, 0.03]} rotation={[-Math.PI / 2, 0, 0]} />
          </group>
          <group name="Stellanova_(part_1)_064" position={[1.24, 3.34, -1.49]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh name="Stellanova_(part_1)_062" geometry={nodes['Stellanova_(part_1)_062'].geometry} material={nodes['Stellanova_(part_1)_062'].material} position={[0, 0, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_061" geometry={nodes['Stellanova_(part_1)_061'].geometry} material={nodes['Stellanova_(part_1)_061'].material} position={[0, 0, -0.02]} />
            <mesh name="Stellanova_(part_1)_059" geometry={nodes['Stellanova_(part_1)_059'].geometry} material={nodes['Stellanova_(part_1)_059'].material} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_058" geometry={nodes['Stellanova_(part_1)_058'].geometry} material={nodes['Stellanova_(part_1)_058'].material} position={[0, 0, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_057" geometry={nodes['Stellanova_(part_1)_057'].geometry} material={nodes['Stellanova_(part_1)_057'].material} position={[0, 0, 0.03]} rotation={[-Math.PI / 2, 0, 0]} />
          </group>
          <group name="Stellanova_(part_1)_072" position={[-1.66, 3.34, -1.49]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh name="Stellanova_(part_1)_070" geometry={nodes['Stellanova_(part_1)_070'].geometry} material={nodes['Stellanova_(part_1)_070'].material} position={[0, 0, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_069" geometry={nodes['Stellanova_(part_1)_069'].geometry} material={nodes['Stellanova_(part_1)_069'].material} position={[0, 0, -0.02]} />
            <mesh name="Stellanova_(part_1)_067" geometry={nodes['Stellanova_(part_1)_067'].geometry} material={nodes['Stellanova_(part_1)_067'].material} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_066" geometry={nodes['Stellanova_(part_1)_066'].geometry} material={nodes['Stellanova_(part_1)_066'].material} position={[0, 0, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_065" geometry={nodes['Stellanova_(part_1)_065'].geometry} material={nodes['Stellanova_(part_1)_065'].material} position={[0, 0, 0.03]} rotation={[-Math.PI / 2, 0, 0]} />
          </group>
          <group name="CoronaCamera003Target" position={[-1.57, 1.63, 0.92]} rotation={[1.56, -0.02, -2.05]} />
          <group name="CoronaCamera005Target" position={[-2.13, 1.6, 0.14]} rotation={[Math.PI / 2, 0, -1.57]} />
          <group name="Stellanova_(part_1)_086" position={[-0.6, 3.34, -1.49]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh name="Stellanova_(part_1)_084" geometry={nodes['Stellanova_(part_1)_084'].geometry} material={nodes['Stellanova_(part_1)_084'].material} position={[0, 0, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_083" geometry={nodes['Stellanova_(part_1)_083'].geometry} material={nodes['Stellanova_(part_1)_083'].material} position={[0, 0, -0.02]} />
            <mesh name="Stellanova_(part_1)_081" geometry={nodes['Stellanova_(part_1)_081'].geometry} material={nodes['Stellanova_(part_1)_081'].material} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_080" geometry={nodes['Stellanova_(part_1)_080'].geometry} material={nodes['Stellanova_(part_1)_080'].material} position={[0, 0, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_079" geometry={nodes['Stellanova_(part_1)_079'].geometry} material={nodes['Stellanova_(part_1)_079'].material} position={[0, 0, 0.03]} rotation={[-Math.PI / 2, 0, 0]} />
          </group>
          <group name="Stellanova_(part_1)_094" position={[0.47, 3.34, -1.49]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh name="Stellanova_(part_1)_092" geometry={nodes['Stellanova_(part_1)_092'].geometry} material={nodes['Stellanova_(part_1)_092'].material} position={[0, 0, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_091" geometry={nodes['Stellanova_(part_1)_091'].geometry} material={nodes['Stellanova_(part_1)_091'].material} position={[0, 0, -0.02]} />
            <mesh name="Stellanova_(part_1)_089" geometry={nodes['Stellanova_(part_1)_089'].geometry} material={nodes['Stellanova_(part_1)_089'].material} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_088" geometry={nodes['Stellanova_(part_1)_088'].geometry} material={nodes['Stellanova_(part_1)_088'].material} position={[0, 0, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_087" geometry={nodes['Stellanova_(part_1)_087'].geometry} material={nodes['Stellanova_(part_1)_087'].material} position={[0, 0, 0.03]} rotation={[-Math.PI / 2, 0, 0]} />
          </group>
          <group name="Window014" position={[7.52, 1.94, -5.29]} rotation={[0, Math.PI / 2, 0]} scale={0.68}>
            <mesh name="Object5567" geometry={nodes.Object5567.geometry} material={nodes.Object5567.material} position={[0.41, 0.33, 0.03]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, 1.25, 1]} />
            <mesh name="Object5568" geometry={nodes.Object5568.geometry} material={nodes.Object5568.material} position={[0.45, 1.71, 0]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1.25, 1]} />
            <mesh name="Object5569" geometry={nodes.Object5569.geometry} material={nodes.Object5569.material} position={[0.34, 1.67, 0.03]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={[1, 1.25, 1]} />
          </group>
          <group name="Window016" position={[7.52, 1.94, -2.82]} rotation={[0, Math.PI / 2, 0]} scale={0.68}>
            <mesh name="Object5570_(1)" geometry={nodes['Object5570_(1)'].geometry} material={nodes['Object5570_(1)'].material} position={[0.41, 0.33, 0.03]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, 1.25, 1]} />
            <mesh name="Object5571_(1)" geometry={nodes['Object5571_(1)'].geometry} material={nodes['Object5571_(1)'].material} position={[0.45, 1.71, 0]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1.25, 1]} />
            <mesh name="Object5572_(1)" geometry={nodes['Object5572_(1)'].geometry} material={nodes['Object5572_(1)'].material} position={[0.34, 1.67, 0.03]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={[1, 1.25, 1]} />
          </group>
          <group name="Window016_(1)" position={[7.52, 1.94, -0.34]} rotation={[0, Math.PI / 2, 0]} scale={0.68}>
            <mesh name="Object5573" geometry={nodes.Object5573.geometry} material={nodes.Object5573.material} position={[0.41, 0.33, 0.03]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, 1.25, 1]} />
            <mesh name="Object5574" geometry={nodes.Object5574.geometry} material={nodes.Object5574.material} position={[0.45, 1.71, 0]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1.25, 1]} />
            <mesh name="Object5575" geometry={nodes.Object5575.geometry} material={nodes.Object5575.material} position={[0.34, 1.67, 0.03]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={[1, 1.25, 1]} />
          </group>
          <group name="Karniz" position={[4.67, 3.26, -2.88]}>
            <mesh name="Rectangle002" geometry={nodes.Rectangle002.geometry} material={nodes.Rectangle002.material} position={[-0.02, -0.05, 0.04]} />
            <mesh name="Plane1860" geometry={nodes.Plane1860.geometry} material={nodes.Plane1860.material} position={[-2.51, -0.08, -4.34]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1861" geometry={nodes.Plane1861.geometry} material={nodes.Plane1861.material} position={[-2.51, -0.08, -4.26]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1862" geometry={nodes.Plane1862.geometry} material={nodes.Plane1862.material} position={[-2.51, -0.08, -4.18]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1863" geometry={nodes.Plane1863.geometry} material={nodes.Plane1863.material} position={[-2.51, -0.08, -4.1]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1864" geometry={nodes.Plane1864.geometry} material={nodes.Plane1864.material} position={[-2.51, -0.08, -4.02]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1865" geometry={nodes.Plane1865.geometry} material={nodes.Plane1865.material} position={[-2.51, -0.08, -3.94]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1866" geometry={nodes.Plane1866.geometry} material={nodes.Plane1866.material} position={[-2.51, -0.08, -3.86]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1867" geometry={nodes.Plane1867.geometry} material={nodes.Plane1867.material} position={[-2.51, -0.08, -3.78]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1868" geometry={nodes.Plane1868.geometry} material={nodes.Plane1868.material} position={[-2.51, -0.08, -3.7]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1869" geometry={nodes.Plane1869.geometry} material={nodes.Plane1869.material} position={[-2.51, -0.08, -3.62]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1870" geometry={nodes.Plane1870.geometry} material={nodes.Plane1870.material} position={[-2.51, -0.08, -3.54]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1871" geometry={nodes.Plane1871.geometry} material={nodes.Plane1871.material} position={[-2.51, -0.08, -3.46]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1872" geometry={nodes.Plane1872.geometry} material={nodes.Plane1872.material} position={[-2.51, -0.08, -3.38]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1873" geometry={nodes.Plane1873.geometry} material={nodes.Plane1873.material} position={[-2.51, -0.08, -3.3]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1874" geometry={nodes.Plane1874.geometry} material={nodes.Plane1874.material} position={[-2.51, -0.08, -3.22]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1875" geometry={nodes.Plane1875.geometry} material={nodes.Plane1875.material} position={[-2.51, -0.08, -3.14]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1876" geometry={nodes.Plane1876.geometry} material={nodes.Plane1876.material} position={[-2.51, -0.08, -3.06]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1877" geometry={nodes.Plane1877.geometry} material={nodes.Plane1877.material} position={[-2.51, -0.08, -2.98]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1878" geometry={nodes.Plane1878.geometry} material={nodes.Plane1878.material} position={[-2.51, -0.08, -2.9]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1879" geometry={nodes.Plane1879.geometry} material={nodes.Plane1879.material} position={[-2.51, -0.08, -2.82]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1880" geometry={nodes.Plane1880.geometry} material={nodes.Plane1880.material} position={[-2.51, -0.08, -2.74]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1881" geometry={nodes.Plane1881.geometry} material={nodes.Plane1881.material} position={[-2.51, -0.08, -2.66]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1882" geometry={nodes.Plane1882.geometry} material={nodes.Plane1882.material} position={[-2.51, -0.08, -2.58]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1883" geometry={nodes.Plane1883.geometry} material={nodes.Plane1883.material} position={[-2.51, -0.08, -2.5]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1884" geometry={nodes.Plane1884.geometry} material={nodes.Plane1884.material} position={[-2.51, -0.08, -2.42]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1885" geometry={nodes.Plane1885.geometry} material={nodes.Plane1885.material} position={[-2.51, -0.08, -2.34]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1886" geometry={nodes.Plane1886.geometry} material={nodes.Plane1886.material} position={[-2.51, -0.08, -2.26]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1887" geometry={nodes.Plane1887.geometry} material={nodes.Plane1887.material} position={[-2.51, -0.08, -2.18]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1888" geometry={nodes.Plane1888.geometry} material={nodes.Plane1888.material} position={[-2.51, -0.08, -2.1]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1889" geometry={nodes.Plane1889.geometry} material={nodes.Plane1889.material} position={[-2.51, -0.08, -2.02]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1890" geometry={nodes.Plane1890.geometry} material={nodes.Plane1890.material} position={[-2.51, -0.08, -1.94]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1891" geometry={nodes.Plane1891.geometry} material={nodes.Plane1891.material} position={[-2.51, -0.08, -1.86]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1892" geometry={nodes.Plane1892.geometry} material={nodes.Plane1892.material} position={[-2.51, -0.08, -1.78]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1893" geometry={nodes.Plane1893.geometry} material={nodes.Plane1893.material} position={[-2.51, -0.08, -1.7]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1894" geometry={nodes.Plane1894.geometry} material={nodes.Plane1894.material} position={[-2.51, -0.08, -1.62]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1895" geometry={nodes.Plane1895.geometry} material={nodes.Plane1895.material} position={[-2.51, -0.08, -1.54]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1896" geometry={nodes.Plane1896.geometry} material={nodes.Plane1896.material} position={[-2.51, -0.08, -1.46]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1897" geometry={nodes.Plane1897.geometry} material={nodes.Plane1897.material} position={[-2.51, -0.08, -1.38]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1898" geometry={nodes.Plane1898.geometry} material={nodes.Plane1898.material} position={[-2.51, -0.08, -1.3]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1899" geometry={nodes.Plane1899.geometry} material={nodes.Plane1899.material} position={[-2.51, -0.08, -1.22]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1900" geometry={nodes.Plane1900.geometry} material={nodes.Plane1900.material} position={[-2.51, -0.08, -1.14]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1901" geometry={nodes.Plane1901.geometry} material={nodes.Plane1901.material} position={[-2.51, -0.08, -1.07]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1902" geometry={nodes.Plane1902.geometry} material={nodes.Plane1902.material} position={[-2.51, -0.08, -0.99]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1903" geometry={nodes.Plane1903.geometry} material={nodes.Plane1903.material} position={[-2.51, -0.08, -0.91]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1904" geometry={nodes.Plane1904.geometry} material={nodes.Plane1904.material} position={[-2.51, -0.08, -0.83]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1905" geometry={nodes.Plane1905.geometry} material={nodes.Plane1905.material} position={[-2.51, -0.08, -0.75]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1906" geometry={nodes.Plane1906.geometry} material={nodes.Plane1906.material} position={[-2.51, -0.08, -0.67]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1907" geometry={nodes.Plane1907.geometry} material={nodes.Plane1907.material} position={[-2.51, -0.08, -0.59]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1908" geometry={nodes.Plane1908.geometry} material={nodes.Plane1908.material} position={[-2.51, -0.08, -0.51]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1909" geometry={nodes.Plane1909.geometry} material={nodes.Plane1909.material} position={[-2.51, -0.08, -0.43]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1910" geometry={nodes.Plane1910.geometry} material={nodes.Plane1910.material} position={[-2.51, -0.08, -0.35]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1911" geometry={nodes.Plane1911.geometry} material={nodes.Plane1911.material} position={[-2.51, -0.08, -0.27]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1912" geometry={nodes.Plane1912.geometry} material={nodes.Plane1912.material} position={[-2.51, -0.08, -0.19]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1913" geometry={nodes.Plane1913.geometry} material={nodes.Plane1913.material} position={[-2.51, -0.08, -0.11]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1914" geometry={nodes.Plane1914.geometry} material={nodes.Plane1914.material} position={[-2.51, -0.08, -0.03]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1915" geometry={nodes.Plane1915.geometry} material={nodes.Plane1915.material} position={[-2.51, -0.08, 0.05]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1916" geometry={nodes.Plane1916.geometry} material={nodes.Plane1916.material} position={[-2.51, -0.08, 0.13]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1917" geometry={nodes.Plane1917.geometry} material={nodes.Plane1917.material} position={[-2.51, -0.08, 0.21]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1918" geometry={nodes.Plane1918.geometry} material={nodes.Plane1918.material} position={[-2.51, -0.08, 0.29]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1919" geometry={nodes.Plane1919.geometry} material={nodes.Plane1919.material} position={[-2.51, -0.08, 0.37]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1920" geometry={nodes.Plane1920.geometry} material={nodes.Plane1920.material} position={[-2.51, -0.08, 0.45]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1921" geometry={nodes.Plane1921.geometry} material={nodes.Plane1921.material} position={[-2.51, -0.08, 0.53]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1922" geometry={nodes.Plane1922.geometry} material={nodes.Plane1922.material} position={[-2.51, -0.08, 0.61]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1923" geometry={nodes.Plane1923.geometry} material={nodes.Plane1923.material} position={[-2.51, -0.08, 0.69]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1924" geometry={nodes.Plane1924.geometry} material={nodes.Plane1924.material} position={[-2.51, -0.08, 0.77]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1925" geometry={nodes.Plane1925.geometry} material={nodes.Plane1925.material} position={[-2.51, -0.08, 0.85]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1926" geometry={nodes.Plane1926.geometry} material={nodes.Plane1926.material} position={[-2.51, -0.08, 0.93]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1927" geometry={nodes.Plane1927.geometry} material={nodes.Plane1927.material} position={[-2.51, -0.08, 1.01]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1928" geometry={nodes.Plane1928.geometry} material={nodes.Plane1928.material} position={[-2.51, -0.08, 1.09]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1929" geometry={nodes.Plane1929.geometry} material={nodes.Plane1929.material} position={[-2.51, -0.08, 1.17]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1930" geometry={nodes.Plane1930.geometry} material={nodes.Plane1930.material} position={[-2.51, -0.08, 1.25]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1931" geometry={nodes.Plane1931.geometry} material={nodes.Plane1931.material} position={[-2.51, -0.08, 1.33]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1932" geometry={nodes.Plane1932.geometry} material={nodes.Plane1932.material} position={[-2.51, -0.08, 1.41]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1933" geometry={nodes.Plane1933.geometry} material={nodes.Plane1933.material} position={[-2.51, -0.08, 1.49]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1934" geometry={nodes.Plane1934.geometry} material={nodes.Plane1934.material} position={[-2.51, -0.08, 1.57]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1935" geometry={nodes.Plane1935.geometry} material={nodes.Plane1935.material} position={[-2.51, -0.08, 1.65]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1936" geometry={nodes.Plane1936.geometry} material={nodes.Plane1936.material} position={[-2.51, -0.08, 1.73]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1937" geometry={nodes.Plane1937.geometry} material={nodes.Plane1937.material} position={[-2.51, -0.08, 1.81]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1938" geometry={nodes.Plane1938.geometry} material={nodes.Plane1938.material} position={[-2.51, -0.08, 1.89]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1939" geometry={nodes.Plane1939.geometry} material={nodes.Plane1939.material} position={[-2.51, -0.08, 1.97]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1940" geometry={nodes.Plane1940.geometry} material={nodes.Plane1940.material} position={[-2.51, -0.08, 2.05]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1941" geometry={nodes.Plane1941.geometry} material={nodes.Plane1941.material} position={[-2.51, -0.08, 2.13]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1942" geometry={nodes.Plane1942.geometry} material={nodes.Plane1942.material} position={[-2.51, -0.08, 2.2]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1943" geometry={nodes.Plane1943.geometry} material={nodes.Plane1943.material} position={[-2.51, -0.08, 2.28]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1944" geometry={nodes.Plane1944.geometry} material={nodes.Plane1944.material} position={[-2.51, -0.08, 2.36]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1945" geometry={nodes.Plane1945.geometry} material={nodes.Plane1945.material} position={[-2.51, -0.08, 2.44]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1946" geometry={nodes.Plane1946.geometry} material={nodes.Plane1946.material} position={[-2.51, -0.08, 2.52]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1947" geometry={nodes.Plane1947.geometry} material={nodes.Plane1947.material} position={[-2.51, -0.08, 2.6]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1948" geometry={nodes.Plane1948.geometry} material={nodes.Plane1948.material} position={[-2.51, -0.08, 2.68]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1949" geometry={nodes.Plane1949.geometry} material={nodes.Plane1949.material} position={[-2.51, -0.08, 2.76]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1950" geometry={nodes.Plane1950.geometry} material={nodes.Plane1950.material} position={[-2.51, -0.08, 2.84]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1951" geometry={nodes.Plane1951.geometry} material={nodes.Plane1951.material} position={[-2.51, -0.08, 2.92]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1952" geometry={nodes.Plane1952.geometry} material={nodes.Plane1952.material} position={[-2.51, -0.08, 3]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1953" geometry={nodes.Plane1953.geometry} material={nodes.Plane1953.material} position={[-2.51, -0.08, 3.08]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1954" geometry={nodes.Plane1954.geometry} material={nodes.Plane1954.material} position={[-2.51, -0.08, 3.16]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1955" geometry={nodes.Plane1955.geometry} material={nodes.Plane1955.material} position={[-2.51, -0.08, 3.24]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1956" geometry={nodes.Plane1956.geometry} material={nodes.Plane1956.material} position={[-2.51, -0.08, 3.32]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1957" geometry={nodes.Plane1957.geometry} material={nodes.Plane1957.material} position={[-2.51, -0.08, 3.4]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1958" geometry={nodes.Plane1958.geometry} material={nodes.Plane1958.material} position={[-2.51, -0.08, 3.48]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1959" geometry={nodes.Plane1959.geometry} material={nodes.Plane1959.material} position={[-2.51, -0.08, 3.56]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1960" geometry={nodes.Plane1960.geometry} material={nodes.Plane1960.material} position={[-2.51, -0.08, 3.64]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1961" geometry={nodes.Plane1961.geometry} material={nodes.Plane1961.material} position={[-2.51, -0.08, 3.72]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1962" geometry={nodes.Plane1962.geometry} material={nodes.Plane1962.material} position={[-2.51, -0.08, 3.8]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1963" geometry={nodes.Plane1963.geometry} material={nodes.Plane1963.material} position={[-2.51, -0.08, 3.88]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1964" geometry={nodes.Plane1964.geometry} material={nodes.Plane1964.material} position={[-2.51, -0.08, 3.96]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1965" geometry={nodes.Plane1965.geometry} material={nodes.Plane1965.material} position={[-2.51, -0.08, 4.04]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1966" geometry={nodes.Plane1966.geometry} material={nodes.Plane1966.material} position={[-2.51, -0.08, 4.12]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1967" geometry={nodes.Plane1967.geometry} material={nodes.Plane1967.material} position={[-2.51, -0.08, 4.2]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1968" geometry={nodes.Plane1968.geometry} material={nodes.Plane1968.material} position={[-2.51, -0.08, 4.28]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1969" geometry={nodes.Plane1969.geometry} material={nodes.Plane1969.material} position={[-2.51, -0.08, 4.36]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1970" geometry={nodes.Plane1970.geometry} material={nodes.Plane1970.material} position={[-2.51, -0.08, 4.44]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1971" geometry={nodes.Plane1971.geometry} material={nodes.Plane1971.material} position={[-2.51, -0.08, 4.52]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1972" geometry={nodes.Plane1972.geometry} material={nodes.Plane1972.material} position={[-2.51, -0.08, 4.6]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1973" geometry={nodes.Plane1973.geometry} material={nodes.Plane1973.material} position={[-2.51, -0.08, 4.68]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1974" geometry={nodes.Plane1974.geometry} material={nodes.Plane1974.material} position={[-2.51, -0.08, 4.75]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1975" geometry={nodes.Plane1975.geometry} material={nodes.Plane1975.material} position={[2.42, -0.08, -4.34]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1976" geometry={nodes.Plane1976.geometry} material={nodes.Plane1976.material} position={[2.42, -0.08, -4.26]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1977" geometry={nodes.Plane1977.geometry} material={nodes.Plane1977.material} position={[2.42, -0.08, -4.18]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1978" geometry={nodes.Plane1978.geometry} material={nodes.Plane1978.material} position={[2.42, -0.08, -4.1]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1979" geometry={nodes.Plane1979.geometry} material={nodes.Plane1979.material} position={[2.42, -0.08, -4.02]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1980" geometry={nodes.Plane1980.geometry} material={nodes.Plane1980.material} position={[2.42, -0.08, -3.94]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1981" geometry={nodes.Plane1981.geometry} material={nodes.Plane1981.material} position={[2.42, -0.08, -3.86]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1982" geometry={nodes.Plane1982.geometry} material={nodes.Plane1982.material} position={[2.42, -0.08, -3.78]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1983" geometry={nodes.Plane1983.geometry} material={nodes.Plane1983.material} position={[2.42, -0.08, -3.7]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1984" geometry={nodes.Plane1984.geometry} material={nodes.Plane1984.material} position={[2.42, -0.08, -3.62]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1985" geometry={nodes.Plane1985.geometry} material={nodes.Plane1985.material} position={[2.42, -0.08, -3.54]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1986" geometry={nodes.Plane1986.geometry} material={nodes.Plane1986.material} position={[2.42, -0.08, -3.46]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1987" geometry={nodes.Plane1987.geometry} material={nodes.Plane1987.material} position={[2.42, -0.08, -3.38]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1988" geometry={nodes.Plane1988.geometry} material={nodes.Plane1988.material} position={[2.42, -0.08, -3.3]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1989" geometry={nodes.Plane1989.geometry} material={nodes.Plane1989.material} position={[2.42, -0.08, -3.22]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1990" geometry={nodes.Plane1990.geometry} material={nodes.Plane1990.material} position={[2.42, -0.08, -3.14]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1991" geometry={nodes.Plane1991.geometry} material={nodes.Plane1991.material} position={[2.42, -0.08, -3.06]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1992" geometry={nodes.Plane1992.geometry} material={nodes.Plane1992.material} position={[2.42, -0.08, -2.98]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1993" geometry={nodes.Plane1993.geometry} material={nodes.Plane1993.material} position={[2.42, -0.08, -2.9]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1994" geometry={nodes.Plane1994.geometry} material={nodes.Plane1994.material} position={[2.42, -0.08, -2.82]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1995" geometry={nodes.Plane1995.geometry} material={nodes.Plane1995.material} position={[2.42, -0.08, -2.74]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1996" geometry={nodes.Plane1996.geometry} material={nodes.Plane1996.material} position={[2.42, -0.08, -2.66]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1997" geometry={nodes.Plane1997.geometry} material={nodes.Plane1997.material} position={[2.42, -0.08, -2.58]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1998" geometry={nodes.Plane1998.geometry} material={nodes.Plane1998.material} position={[2.42, -0.08, -2.5]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane1999" geometry={nodes.Plane1999.geometry} material={nodes.Plane1999.material} position={[2.42, -0.08, -2.42]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2000" geometry={nodes.Plane2000.geometry} material={nodes.Plane2000.material} position={[2.42, -0.08, -2.34]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2001" geometry={nodes.Plane2001.geometry} material={nodes.Plane2001.material} position={[2.42, -0.08, -2.26]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2002" geometry={nodes.Plane2002.geometry} material={nodes.Plane2002.material} position={[2.42, -0.08, -2.18]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2003" geometry={nodes.Plane2003.geometry} material={nodes.Plane2003.material} position={[2.42, -0.08, -2.1]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2004" geometry={nodes.Plane2004.geometry} material={nodes.Plane2004.material} position={[2.42, -0.08, -2.02]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2005" geometry={nodes.Plane2005.geometry} material={nodes.Plane2005.material} position={[2.42, -0.08, -1.94]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2006" geometry={nodes.Plane2006.geometry} material={nodes.Plane2006.material} position={[2.42, -0.08, -1.86]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2007" geometry={nodes.Plane2007.geometry} material={nodes.Plane2007.material} position={[2.42, -0.08, -1.78]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2008" geometry={nodes.Plane2008.geometry} material={nodes.Plane2008.material} position={[2.42, -0.08, -1.7]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2009" geometry={nodes.Plane2009.geometry} material={nodes.Plane2009.material} position={[2.42, -0.08, -1.62]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2010" geometry={nodes.Plane2010.geometry} material={nodes.Plane2010.material} position={[2.42, -0.08, -1.54]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2011" geometry={nodes.Plane2011.geometry} material={nodes.Plane2011.material} position={[2.42, -0.08, -1.46]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2012" geometry={nodes.Plane2012.geometry} material={nodes.Plane2012.material} position={[2.42, -0.08, -1.38]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2013" geometry={nodes.Plane2013.geometry} material={nodes.Plane2013.material} position={[2.42, -0.08, -1.3]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2014" geometry={nodes.Plane2014.geometry} material={nodes.Plane2014.material} position={[2.42, -0.08, -1.22]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2015" geometry={nodes.Plane2015.geometry} material={nodes.Plane2015.material} position={[2.42, -0.08, -1.14]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2016" geometry={nodes.Plane2016.geometry} material={nodes.Plane2016.material} position={[2.42, -0.08, -1.07]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2017" geometry={nodes.Plane2017.geometry} material={nodes.Plane2017.material} position={[2.42, -0.08, -0.99]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2018" geometry={nodes.Plane2018.geometry} material={nodes.Plane2018.material} position={[2.42, -0.08, -0.91]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2019" geometry={nodes.Plane2019.geometry} material={nodes.Plane2019.material} position={[2.42, -0.08, -0.83]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2020" geometry={nodes.Plane2020.geometry} material={nodes.Plane2020.material} position={[2.42, -0.08, -0.75]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2021" geometry={nodes.Plane2021.geometry} material={nodes.Plane2021.material} position={[2.42, -0.08, -0.67]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2022" geometry={nodes.Plane2022.geometry} material={nodes.Plane2022.material} position={[2.42, -0.08, -0.59]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2023" geometry={nodes.Plane2023.geometry} material={nodes.Plane2023.material} position={[2.42, -0.08, -0.51]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2024" geometry={nodes.Plane2024.geometry} material={nodes.Plane2024.material} position={[2.42, -0.08, -0.43]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2025" geometry={nodes.Plane2025.geometry} material={nodes.Plane2025.material} position={[2.42, -0.08, -0.35]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2026" geometry={nodes.Plane2026.geometry} material={nodes.Plane2026.material} position={[2.42, -0.08, -0.27]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2027" geometry={nodes.Plane2027.geometry} material={nodes.Plane2027.material} position={[2.42, -0.08, -0.19]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2028" geometry={nodes.Plane2028.geometry} material={nodes.Plane2028.material} position={[2.42, -0.08, -0.11]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2029" geometry={nodes.Plane2029.geometry} material={nodes.Plane2029.material} position={[2.42, -0.08, -0.03]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2030" geometry={nodes.Plane2030.geometry} material={nodes.Plane2030.material} position={[2.42, -0.08, 0.05]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2031" geometry={nodes.Plane2031.geometry} material={nodes.Plane2031.material} position={[2.42, -0.08, 0.13]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2032" geometry={nodes.Plane2032.geometry} material={nodes.Plane2032.material} position={[2.42, -0.08, 0.21]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2033" geometry={nodes.Plane2033.geometry} material={nodes.Plane2033.material} position={[2.42, -0.08, 0.29]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2034" geometry={nodes.Plane2034.geometry} material={nodes.Plane2034.material} position={[2.42, -0.08, 0.37]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2035" geometry={nodes.Plane2035.geometry} material={nodes.Plane2035.material} position={[2.42, -0.08, 0.45]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2036" geometry={nodes.Plane2036.geometry} material={nodes.Plane2036.material} position={[2.42, -0.08, 0.53]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2037" geometry={nodes.Plane2037.geometry} material={nodes.Plane2037.material} position={[2.42, -0.08, 0.61]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2038" geometry={nodes.Plane2038.geometry} material={nodes.Plane2038.material} position={[2.42, -0.08, 0.69]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2039" geometry={nodes.Plane2039.geometry} material={nodes.Plane2039.material} position={[2.42, -0.08, 0.77]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2040" geometry={nodes.Plane2040.geometry} material={nodes.Plane2040.material} position={[2.42, -0.08, 0.85]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2041" geometry={nodes.Plane2041.geometry} material={nodes.Plane2041.material} position={[2.42, -0.08, 0.93]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2042" geometry={nodes.Plane2042.geometry} material={nodes.Plane2042.material} position={[2.42, -0.08, 1.01]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2043" geometry={nodes.Plane2043.geometry} material={nodes.Plane2043.material} position={[2.42, -0.08, 1.09]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2044" geometry={nodes.Plane2044.geometry} material={nodes.Plane2044.material} position={[2.42, -0.08, 1.17]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2045" geometry={nodes.Plane2045.geometry} material={nodes.Plane2045.material} position={[2.42, -0.08, 1.25]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2046" geometry={nodes.Plane2046.geometry} material={nodes.Plane2046.material} position={[2.42, -0.08, 1.33]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2047" geometry={nodes.Plane2047.geometry} material={nodes.Plane2047.material} position={[2.42, -0.08, 1.41]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2048" geometry={nodes.Plane2048.geometry} material={nodes.Plane2048.material} position={[2.42, -0.08, 1.49]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2049" geometry={nodes.Plane2049.geometry} material={nodes.Plane2049.material} position={[2.42, -0.08, 1.57]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2050" geometry={nodes.Plane2050.geometry} material={nodes.Plane2050.material} position={[2.42, -0.08, 1.65]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2051" geometry={nodes.Plane2051.geometry} material={nodes.Plane2051.material} position={[2.42, -0.08, 1.73]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2052" geometry={nodes.Plane2052.geometry} material={nodes.Plane2052.material} position={[2.42, -0.08, 1.81]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2053" geometry={nodes.Plane2053.geometry} material={nodes.Plane2053.material} position={[2.42, -0.08, 1.89]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2054" geometry={nodes.Plane2054.geometry} material={nodes.Plane2054.material} position={[2.42, -0.08, 1.97]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2055" geometry={nodes.Plane2055.geometry} material={nodes.Plane2055.material} position={[2.42, -0.08, 2.05]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2056" geometry={nodes.Plane2056.geometry} material={nodes.Plane2056.material} position={[2.42, -0.08, 2.13]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2057" geometry={nodes.Plane2057.geometry} material={nodes.Plane2057.material} position={[2.42, -0.08, 2.2]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2058" geometry={nodes.Plane2058.geometry} material={nodes.Plane2058.material} position={[2.42, -0.08, 2.28]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2059" geometry={nodes.Plane2059.geometry} material={nodes.Plane2059.material} position={[2.42, -0.08, 2.36]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2060" geometry={nodes.Plane2060.geometry} material={nodes.Plane2060.material} position={[2.42, -0.08, 2.44]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2061" geometry={nodes.Plane2061.geometry} material={nodes.Plane2061.material} position={[2.42, -0.08, 2.52]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2062" geometry={nodes.Plane2062.geometry} material={nodes.Plane2062.material} position={[2.42, -0.08, 2.6]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2063" geometry={nodes.Plane2063.geometry} material={nodes.Plane2063.material} position={[2.42, -0.08, 2.68]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2064" geometry={nodes.Plane2064.geometry} material={nodes.Plane2064.material} position={[2.42, -0.08, 2.76]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2065" geometry={nodes.Plane2065.geometry} material={nodes.Plane2065.material} position={[2.42, -0.08, 2.84]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2066" geometry={nodes.Plane2066.geometry} material={nodes.Plane2066.material} position={[2.42, -0.08, 2.92]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2067" geometry={nodes.Plane2067.geometry} material={nodes.Plane2067.material} position={[2.42, -0.08, 3]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2068" geometry={nodes.Plane2068.geometry} material={nodes.Plane2068.material} position={[2.42, -0.08, 3.08]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2069" geometry={nodes.Plane2069.geometry} material={nodes.Plane2069.material} position={[2.42, -0.08, 3.16]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2070" geometry={nodes.Plane2070.geometry} material={nodes.Plane2070.material} position={[2.42, -0.08, 3.24]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2071" geometry={nodes.Plane2071.geometry} material={nodes.Plane2071.material} position={[2.42, -0.08, 3.32]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2072" geometry={nodes.Plane2072.geometry} material={nodes.Plane2072.material} position={[2.42, -0.08, 3.4]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2073" geometry={nodes.Plane2073.geometry} material={nodes.Plane2073.material} position={[2.42, -0.08, 3.48]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2074" geometry={nodes.Plane2074.geometry} material={nodes.Plane2074.material} position={[2.42, -0.08, 3.56]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2075" geometry={nodes.Plane2075.geometry} material={nodes.Plane2075.material} position={[2.42, -0.08, 3.64]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2076" geometry={nodes.Plane2076.geometry} material={nodes.Plane2076.material} position={[2.42, -0.08, 3.72]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2077" geometry={nodes.Plane2077.geometry} material={nodes.Plane2077.material} position={[2.42, -0.08, 3.8]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2078" geometry={nodes.Plane2078.geometry} material={nodes.Plane2078.material} position={[2.42, -0.08, 3.88]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2079" geometry={nodes.Plane2079.geometry} material={nodes.Plane2079.material} position={[2.42, -0.08, 3.96]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2080" geometry={nodes.Plane2080.geometry} material={nodes.Plane2080.material} position={[2.42, -0.08, 4.04]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2081" geometry={nodes.Plane2081.geometry} material={nodes.Plane2081.material} position={[2.42, -0.08, 4.12]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2082" geometry={nodes.Plane2082.geometry} material={nodes.Plane2082.material} position={[2.42, -0.08, 4.2]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2083" geometry={nodes.Plane2083.geometry} material={nodes.Plane2083.material} position={[2.42, -0.08, 4.28]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2084" geometry={nodes.Plane2084.geometry} material={nodes.Plane2084.material} position={[2.42, -0.08, 4.36]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2085" geometry={nodes.Plane2085.geometry} material={nodes.Plane2085.material} position={[2.42, -0.08, 4.44]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2086" geometry={nodes.Plane2086.geometry} material={nodes.Plane2086.material} position={[2.42, -0.08, 4.52]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2087" geometry={nodes.Plane2087.geometry} material={nodes.Plane2087.material} position={[2.42, -0.08, 4.6]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2088" geometry={nodes.Plane2088.geometry} material={nodes.Plane2088.material} position={[2.42, -0.08, 4.68]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2089" geometry={nodes.Plane2089.geometry} material={nodes.Plane2089.material} position={[2.42, -0.08, 4.75]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Plane2144" geometry={nodes.Plane2144.geometry} material={nodes.Plane2144.material} position={[-2.46, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2145" geometry={nodes.Plane2145.geometry} material={nodes.Plane2145.material} position={[-2.38, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2146" geometry={nodes.Plane2146.geometry} material={nodes.Plane2146.material} position={[-2.3, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2147" geometry={nodes.Plane2147.geometry} material={nodes.Plane2147.material} position={[-2.22, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2148" geometry={nodes.Plane2148.geometry} material={nodes.Plane2148.material} position={[-2.14, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2149" geometry={nodes.Plane2149.geometry} material={nodes.Plane2149.material} position={[-2.06, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2150" geometry={nodes.Plane2150.geometry} material={nodes.Plane2150.material} position={[-1.98, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2151" geometry={nodes.Plane2151.geometry} material={nodes.Plane2151.material} position={[-1.9, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2152" geometry={nodes.Plane2152.geometry} material={nodes.Plane2152.material} position={[-1.82, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2153" geometry={nodes.Plane2153.geometry} material={nodes.Plane2153.material} position={[-1.74, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2154" geometry={nodes.Plane2154.geometry} material={nodes.Plane2154.material} position={[-1.66, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2155" geometry={nodes.Plane2155.geometry} material={nodes.Plane2155.material} position={[-1.58, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2156" geometry={nodes.Plane2156.geometry} material={nodes.Plane2156.material} position={[-1.5, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2157" geometry={nodes.Plane2157.geometry} material={nodes.Plane2157.material} position={[-1.42, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2158" geometry={nodes.Plane2158.geometry} material={nodes.Plane2158.material} position={[-1.34, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2159" geometry={nodes.Plane2159.geometry} material={nodes.Plane2159.material} position={[-1.26, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2160" geometry={nodes.Plane2160.geometry} material={nodes.Plane2160.material} position={[-1.18, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2161" geometry={nodes.Plane2161.geometry} material={nodes.Plane2161.material} position={[-1.1, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2162" geometry={nodes.Plane2162.geometry} material={nodes.Plane2162.material} position={[-1.02, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2163" geometry={nodes.Plane2163.geometry} material={nodes.Plane2163.material} position={[-0.94, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2164" geometry={nodes.Plane2164.geometry} material={nodes.Plane2164.material} position={[-0.86, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2165" geometry={nodes.Plane2165.geometry} material={nodes.Plane2165.material} position={[-0.78, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2166" geometry={nodes.Plane2166.geometry} material={nodes.Plane2166.material} position={[-0.7, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2167" geometry={nodes.Plane2167.geometry} material={nodes.Plane2167.material} position={[-0.62, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2168" geometry={nodes.Plane2168.geometry} material={nodes.Plane2168.material} position={[-0.54, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2169" geometry={nodes.Plane2169.geometry} material={nodes.Plane2169.material} position={[-0.46, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2170" geometry={nodes.Plane2170.geometry} material={nodes.Plane2170.material} position={[-0.38, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2171" geometry={nodes.Plane2171.geometry} material={nodes.Plane2171.material} position={[-0.3, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2172" geometry={nodes.Plane2172.geometry} material={nodes.Plane2172.material} position={[-0.23, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2173" geometry={nodes.Plane2173.geometry} material={nodes.Plane2173.material} position={[-0.15, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2174" geometry={nodes.Plane2174.geometry} material={nodes.Plane2174.material} position={[-0.07, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2175" geometry={nodes.Plane2175.geometry} material={nodes.Plane2175.material} position={[0.01, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2176" geometry={nodes.Plane2176.geometry} material={nodes.Plane2176.material} position={[0.09, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2177" geometry={nodes.Plane2177.geometry} material={nodes.Plane2177.material} position={[0.17, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2178" geometry={nodes.Plane2178.geometry} material={nodes.Plane2178.material} position={[0.25, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2179" geometry={nodes.Plane2179.geometry} material={nodes.Plane2179.material} position={[0.33, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2180" geometry={nodes.Plane2180.geometry} material={nodes.Plane2180.material} position={[0.41, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2181" geometry={nodes.Plane2181.geometry} material={nodes.Plane2181.material} position={[0.49, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2182" geometry={nodes.Plane2182.geometry} material={nodes.Plane2182.material} position={[0.57, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2183" geometry={nodes.Plane2183.geometry} material={nodes.Plane2183.material} position={[0.65, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2184" geometry={nodes.Plane2184.geometry} material={nodes.Plane2184.material} position={[0.73, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2185" geometry={nodes.Plane2185.geometry} material={nodes.Plane2185.material} position={[0.81, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2186" geometry={nodes.Plane2186.geometry} material={nodes.Plane2186.material} position={[0.89, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2187" geometry={nodes.Plane2187.geometry} material={nodes.Plane2187.material} position={[0.97, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2188" geometry={nodes.Plane2188.geometry} material={nodes.Plane2188.material} position={[1.05, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2189" geometry={nodes.Plane2189.geometry} material={nodes.Plane2189.material} position={[1.13, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2190" geometry={nodes.Plane2190.geometry} material={nodes.Plane2190.material} position={[1.21, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2191" geometry={nodes.Plane2191.geometry} material={nodes.Plane2191.material} position={[1.29, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2192" geometry={nodes.Plane2192.geometry} material={nodes.Plane2192.material} position={[1.37, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2193" geometry={nodes.Plane2193.geometry} material={nodes.Plane2193.material} position={[1.45, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2194" geometry={nodes.Plane2194.geometry} material={nodes.Plane2194.material} position={[1.53, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2195" geometry={nodes.Plane2195.geometry} material={nodes.Plane2195.material} position={[1.61, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2196" geometry={nodes.Plane2196.geometry} material={nodes.Plane2196.material} position={[1.69, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2197" geometry={nodes.Plane2197.geometry} material={nodes.Plane2197.material} position={[1.77, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2198" geometry={nodes.Plane2198.geometry} material={nodes.Plane2198.material} position={[1.85, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2199" geometry={nodes.Plane2199.geometry} material={nodes.Plane2199.material} position={[1.93, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2200" geometry={nodes.Plane2200.geometry} material={nodes.Plane2200.material} position={[2.01, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2201" geometry={nodes.Plane2201.geometry} material={nodes.Plane2201.material} position={[2.09, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2202" geometry={nodes.Plane2202.geometry} material={nodes.Plane2202.material} position={[2.17, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2203" geometry={nodes.Plane2203.geometry} material={nodes.Plane2203.material} position={[2.25, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2204" geometry={nodes.Plane2204.geometry} material={nodes.Plane2204.material} position={[2.33, -0.08, 4.73]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2207" geometry={nodes.Plane2207.geometry} material={nodes.Plane2207.material} position={[-2.46, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2208" geometry={nodes.Plane2208.geometry} material={nodes.Plane2208.material} position={[-2.38, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2209" geometry={nodes.Plane2209.geometry} material={nodes.Plane2209.material} position={[-2.3, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2210" geometry={nodes.Plane2210.geometry} material={nodes.Plane2210.material} position={[-2.22, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2211" geometry={nodes.Plane2211.geometry} material={nodes.Plane2211.material} position={[-2.14, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2212" geometry={nodes.Plane2212.geometry} material={nodes.Plane2212.material} position={[-2.06, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2213" geometry={nodes.Plane2213.geometry} material={nodes.Plane2213.material} position={[-1.98, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2214" geometry={nodes.Plane2214.geometry} material={nodes.Plane2214.material} position={[-1.9, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2215" geometry={nodes.Plane2215.geometry} material={nodes.Plane2215.material} position={[-1.82, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2216" geometry={nodes.Plane2216.geometry} material={nodes.Plane2216.material} position={[-1.74, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2217" geometry={nodes.Plane2217.geometry} material={nodes.Plane2217.material} position={[-1.66, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2218" geometry={nodes.Plane2218.geometry} material={nodes.Plane2218.material} position={[-1.58, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2219" geometry={nodes.Plane2219.geometry} material={nodes.Plane2219.material} position={[-1.5, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2220" geometry={nodes.Plane2220.geometry} material={nodes.Plane2220.material} position={[-1.42, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2221" geometry={nodes.Plane2221.geometry} material={nodes.Plane2221.material} position={[-1.34, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2222" geometry={nodes.Plane2222.geometry} material={nodes.Plane2222.material} position={[-1.26, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2223" geometry={nodes.Plane2223.geometry} material={nodes.Plane2223.material} position={[-1.18, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2224" geometry={nodes.Plane2224.geometry} material={nodes.Plane2224.material} position={[-1.1, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2225" geometry={nodes.Plane2225.geometry} material={nodes.Plane2225.material} position={[-1.02, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2226" geometry={nodes.Plane2226.geometry} material={nodes.Plane2226.material} position={[-0.94, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2227" geometry={nodes.Plane2227.geometry} material={nodes.Plane2227.material} position={[-0.86, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2228" geometry={nodes.Plane2228.geometry} material={nodes.Plane2228.material} position={[-0.78, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2229" geometry={nodes.Plane2229.geometry} material={nodes.Plane2229.material} position={[-0.7, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2230" geometry={nodes.Plane2230.geometry} material={nodes.Plane2230.material} position={[-0.62, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2231" geometry={nodes.Plane2231.geometry} material={nodes.Plane2231.material} position={[-0.54, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2232" geometry={nodes.Plane2232.geometry} material={nodes.Plane2232.material} position={[-0.46, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2233" geometry={nodes.Plane2233.geometry} material={nodes.Plane2233.material} position={[-0.38, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2234" geometry={nodes.Plane2234.geometry} material={nodes.Plane2234.material} position={[-0.3, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2235" geometry={nodes.Plane2235.geometry} material={nodes.Plane2235.material} position={[-0.23, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2236" geometry={nodes.Plane2236.geometry} material={nodes.Plane2236.material} position={[-0.15, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2237" geometry={nodes.Plane2237.geometry} material={nodes.Plane2237.material} position={[-0.07, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2238" geometry={nodes.Plane2238.geometry} material={nodes.Plane2238.material} position={[0.01, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2239" geometry={nodes.Plane2239.geometry} material={nodes.Plane2239.material} position={[0.09, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2240" geometry={nodes.Plane2240.geometry} material={nodes.Plane2240.material} position={[0.17, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2241" geometry={nodes.Plane2241.geometry} material={nodes.Plane2241.material} position={[0.25, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2242" geometry={nodes.Plane2242.geometry} material={nodes.Plane2242.material} position={[0.33, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2243" geometry={nodes.Plane2243.geometry} material={nodes.Plane2243.material} position={[0.41, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2244" geometry={nodes.Plane2244.geometry} material={nodes.Plane2244.material} position={[0.49, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2245" geometry={nodes.Plane2245.geometry} material={nodes.Plane2245.material} position={[0.57, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2246" geometry={nodes.Plane2246.geometry} material={nodes.Plane2246.material} position={[0.65, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2247" geometry={nodes.Plane2247.geometry} material={nodes.Plane2247.material} position={[0.73, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2248" geometry={nodes.Plane2248.geometry} material={nodes.Plane2248.material} position={[0.81, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2249" geometry={nodes.Plane2249.geometry} material={nodes.Plane2249.material} position={[0.89, -0.08, -4.08]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2250" geometry={nodes.Plane2250.geometry} material={nodes.Plane2250.material} position={[0.97, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2251" geometry={nodes.Plane2251.geometry} material={nodes.Plane2251.material} position={[1.05, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2252" geometry={nodes.Plane2252.geometry} material={nodes.Plane2252.material} position={[1.13, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2253" geometry={nodes.Plane2253.geometry} material={nodes.Plane2253.material} position={[1.21, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2254" geometry={nodes.Plane2254.geometry} material={nodes.Plane2254.material} position={[1.29, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2255" geometry={nodes.Plane2255.geometry} material={nodes.Plane2255.material} position={[1.37, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2256" geometry={nodes.Plane2256.geometry} material={nodes.Plane2256.material} position={[1.45, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2257" geometry={nodes.Plane2257.geometry} material={nodes.Plane2257.material} position={[1.53, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2258" geometry={nodes.Plane2258.geometry} material={nodes.Plane2258.material} position={[1.61, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2259" geometry={nodes.Plane2259.geometry} material={nodes.Plane2259.material} position={[1.69, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2260" geometry={nodes.Plane2260.geometry} material={nodes.Plane2260.material} position={[1.77, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2261" geometry={nodes.Plane2261.geometry} material={nodes.Plane2261.material} position={[1.85, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2262" geometry={nodes.Plane2262.geometry} material={nodes.Plane2262.material} position={[1.93, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2263" geometry={nodes.Plane2263.geometry} material={nodes.Plane2263.material} position={[2.01, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2264" geometry={nodes.Plane2264.geometry} material={nodes.Plane2264.material} position={[2.09, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2265" geometry={nodes.Plane2265.geometry} material={nodes.Plane2265.material} position={[2.17, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2266" geometry={nodes.Plane2266.geometry} material={nodes.Plane2266.material} position={[2.25, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2267" geometry={nodes.Plane2267.geometry} material={nodes.Plane2267.material} position={[2.33, -0.08, -4.36]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Plane2269" geometry={nodes.Plane2269.geometry} material={nodes.Plane2269.material} position={[-1.26, -0.08, -4.11]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
            <mesh name="Plane2270" geometry={nodes.Plane2270.geometry} material={nodes.Plane2270.material} position={[-1.26, -0.08, -4.19]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
            <mesh name="Plane2271" geometry={nodes.Plane2271.geometry} material={nodes.Plane2271.material} position={[-1.26, -0.08, -4.27]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
            <mesh name="Plane2272" geometry={nodes.Plane2272.geometry} material={nodes.Plane2272.material} position={[0.95, -0.08, -4.11]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
            <mesh name="Plane2273" geometry={nodes.Plane2273.geometry} material={nodes.Plane2273.material} position={[0.95, -0.08, -4.19]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
            <mesh name="Plane2274" geometry={nodes.Plane2274.geometry} material={nodes.Plane2274.material} position={[0.95, -0.08, -4.27]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          </group>
          <group name="Stellanova_(part_1)_185" position={[4.65, 3.32, 1.55]} rotation={[Math.PI, 0, Math.PI]}>
            <mesh name="Stellanova_(part_1)_178" geometry={nodes['Stellanova_(part_1)_178'].geometry} material={nodes['Stellanova_(part_1)_178'].material} position={[0, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_186" geometry={nodes['Stellanova_(part_1)_186'].geometry} material={nodes['Stellanova_(part_1)_186'].material} position={[0.06, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_179" geometry={nodes['Stellanova_(part_1)_179'].geometry} material={nodes['Stellanova_(part_1)_179'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_187" geometry={nodes['Stellanova_(part_1)_187'].geometry} material={nodes['Stellanova_(part_1)_187'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_180" geometry={nodes['Stellanova_(part_1)_180'].geometry} material={nodes['Stellanova_(part_1)_180'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_188" geometry={nodes['Stellanova_(part_1)_188'].geometry} material={nodes['Stellanova_(part_1)_188'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_182" geometry={nodes['Stellanova_(part_1)_182'].geometry} material={nodes['Stellanova_(part_1)_182'].material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_190" geometry={nodes['Stellanova_(part_1)_190'].geometry} material={nodes['Stellanova_(part_1)_190'].material} position={[0.06, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_183" geometry={nodes['Stellanova_(part_1)_183'].geometry} material={nodes['Stellanova_(part_1)_183'].material} position={[0, -0.04, 0]} />
          </group>
          <group name="Stellanova_(part_1)_199" position={[6.39, 3.32, 1.55]} rotation={[Math.PI, 0, Math.PI]}>
            <mesh name="Stellanova_(part_1)_192" geometry={nodes['Stellanova_(part_1)_192'].geometry} material={nodes['Stellanova_(part_1)_192'].material} position={[0, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_193" geometry={nodes['Stellanova_(part_1)_193'].geometry} material={nodes['Stellanova_(part_1)_193'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_194" geometry={nodes['Stellanova_(part_1)_194'].geometry} material={nodes['Stellanova_(part_1)_194'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_196" geometry={nodes['Stellanova_(part_1)_196'].geometry} material={nodes['Stellanova_(part_1)_196'].material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_197" geometry={nodes['Stellanova_(part_1)_197'].geometry} material={nodes['Stellanova_(part_1)_197'].material} position={[0, -0.04, 0]} />
          </group>
          <group name="Stellanova_(part_1)_207" position={[2.78, 3.32, 1.55]} rotation={[Math.PI, 0, Math.PI]}>
            <mesh name="Stellanova_(part_1)_200" geometry={nodes['Stellanova_(part_1)_200'].geometry} material={nodes['Stellanova_(part_1)_200'].material} position={[0, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_201" geometry={nodes['Stellanova_(part_1)_201'].geometry} material={nodes['Stellanova_(part_1)_201'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_202" geometry={nodes['Stellanova_(part_1)_202'].geometry} material={nodes['Stellanova_(part_1)_202'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_204" geometry={nodes['Stellanova_(part_1)_204'].geometry} material={nodes['Stellanova_(part_1)_204'].material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_205" geometry={nodes['Stellanova_(part_1)_205'].geometry} material={nodes['Stellanova_(part_1)_205'].material} position={[0, -0.04, 0]} />
          </group>
          <group name="Stellanova_(part_1)_215" position={[5.34, 3.32, -4.08]} rotation={[Math.PI, 0, Math.PI]}>
            <mesh name="Stellanova_(part_1)_208" geometry={nodes['Stellanova_(part_1)_208'].geometry} material={nodes['Stellanova_(part_1)_208'].material} position={[0, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_216" geometry={nodes['Stellanova_(part_1)_216'].geometry} material={nodes['Stellanova_(part_1)_216'].material} position={[0.06, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_209" geometry={nodes['Stellanova_(part_1)_209'].geometry} material={nodes['Stellanova_(part_1)_209'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_217" geometry={nodes['Stellanova_(part_1)_217'].geometry} material={nodes['Stellanova_(part_1)_217'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_210" geometry={nodes['Stellanova_(part_1)_210'].geometry} material={nodes['Stellanova_(part_1)_210'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_218" geometry={nodes['Stellanova_(part_1)_218'].geometry} material={nodes['Stellanova_(part_1)_218'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_212" geometry={nodes['Stellanova_(part_1)_212'].geometry} material={nodes['Stellanova_(part_1)_212'].material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_220" geometry={nodes['Stellanova_(part_1)_220'].geometry} material={nodes['Stellanova_(part_1)_220'].material} position={[0.06, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_213" geometry={nodes['Stellanova_(part_1)_213'].geometry} material={nodes['Stellanova_(part_1)_213'].material} position={[0, -0.04, 0]} />
          </group>
          <group name="Stellanova_(part_1)_243" position={[5.34, 3.32, -5.47]} rotation={[Math.PI, 0, Math.PI]}>
            <mesh name="Stellanova_(part_1)_236" geometry={nodes['Stellanova_(part_1)_236'].geometry} material={nodes['Stellanova_(part_1)_236'].material} position={[0, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_244" geometry={nodes['Stellanova_(part_1)_244'].geometry} material={nodes['Stellanova_(part_1)_244'].material} position={[0.06, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_237" geometry={nodes['Stellanova_(part_1)_237'].geometry} material={nodes['Stellanova_(part_1)_237'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_245" geometry={nodes['Stellanova_(part_1)_245'].geometry} material={nodes['Stellanova_(part_1)_245'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_238" geometry={nodes['Stellanova_(part_1)_238'].geometry} material={nodes['Stellanova_(part_1)_238'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_246" geometry={nodes['Stellanova_(part_1)_246'].geometry} material={nodes['Stellanova_(part_1)_246'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_240" geometry={nodes['Stellanova_(part_1)_240'].geometry} material={nodes['Stellanova_(part_1)_240'].material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_248" geometry={nodes['Stellanova_(part_1)_248'].geometry} material={nodes['Stellanova_(part_1)_248'].material} position={[0.06, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_241" geometry={nodes['Stellanova_(part_1)_241'].geometry} material={nodes['Stellanova_(part_1)_241'].material} position={[0, -0.04, 0]} />
          </group>
          <group name="Stellanova_(part_1)_257" position={[3.77, 3.32, -5.47]} rotation={[Math.PI, 0, Math.PI]}>
            <mesh name="Stellanova_(part_1)_250" geometry={nodes['Stellanova_(part_1)_250'].geometry} material={nodes['Stellanova_(part_1)_250'].material} position={[0, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_258" geometry={nodes['Stellanova_(part_1)_258'].geometry} material={nodes['Stellanova_(part_1)_258'].material} position={[0.06, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_251" geometry={nodes['Stellanova_(part_1)_251'].geometry} material={nodes['Stellanova_(part_1)_251'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_259" geometry={nodes['Stellanova_(part_1)_259'].geometry} material={nodes['Stellanova_(part_1)_259'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_252" geometry={nodes['Stellanova_(part_1)_252'].geometry} material={nodes['Stellanova_(part_1)_252'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_260" geometry={nodes['Stellanova_(part_1)_260'].geometry} material={nodes['Stellanova_(part_1)_260'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_254" geometry={nodes['Stellanova_(part_1)_254'].geometry} material={nodes['Stellanova_(part_1)_254'].material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_262" geometry={nodes['Stellanova_(part_1)_262'].geometry} material={nodes['Stellanova_(part_1)_262'].material} position={[0.06, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_255" geometry={nodes['Stellanova_(part_1)_255'].geometry} material={nodes['Stellanova_(part_1)_255'].material} position={[0, -0.04, 0]} />
          </group>
          <group name="Stellanova_(part_1)_271" position={[4.56, 3.32, -6.51]} rotation={[Math.PI, 0, Math.PI]}>
            <mesh name="Stellanova_(part_1)_264" geometry={nodes['Stellanova_(part_1)_264'].geometry} material={nodes['Stellanova_(part_1)_264'].material} position={[0, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_272" geometry={nodes['Stellanova_(part_1)_272'].geometry} material={nodes['Stellanova_(part_1)_272'].material} position={[0.06, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_265" geometry={nodes['Stellanova_(part_1)_265'].geometry} material={nodes['Stellanova_(part_1)_265'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_273" geometry={nodes['Stellanova_(part_1)_273'].geometry} material={nodes['Stellanova_(part_1)_273'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_266" geometry={nodes['Stellanova_(part_1)_266'].geometry} material={nodes['Stellanova_(part_1)_266'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_274" geometry={nodes['Stellanova_(part_1)_274'].geometry} material={nodes['Stellanova_(part_1)_274'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_268" geometry={nodes['Stellanova_(part_1)_268'].geometry} material={nodes['Stellanova_(part_1)_268'].material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_276" geometry={nodes['Stellanova_(part_1)_276'].geometry} material={nodes['Stellanova_(part_1)_276'].material} position={[0.06, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_269" geometry={nodes['Stellanova_(part_1)_269'].geometry} material={nodes['Stellanova_(part_1)_269'].material} position={[0, -0.04, 0]} />
          </group>
          <group name="Stellanova_(part_1)_285" position={[6.39, 3.32, -6.82]} rotation={[Math.PI, 0, Math.PI]}>
            <mesh name="Stellanova_(part_1)_278" geometry={nodes['Stellanova_(part_1)_278'].geometry} material={nodes['Stellanova_(part_1)_278'].material} position={[0, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_279" geometry={nodes['Stellanova_(part_1)_279'].geometry} material={nodes['Stellanova_(part_1)_279'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_280" geometry={nodes['Stellanova_(part_1)_280'].geometry} material={nodes['Stellanova_(part_1)_280'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_282" geometry={nodes['Stellanova_(part_1)_282'].geometry} material={nodes['Stellanova_(part_1)_282'].material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_283" geometry={nodes['Stellanova_(part_1)_283'].geometry} material={nodes['Stellanova_(part_1)_283'].material} position={[0, -0.04, 0]} />
          </group>
          <group name="Stellanova_(part_1)_293" position={[2.78, 3.32, -6.82]} rotation={[Math.PI, 0, Math.PI]}>
            <mesh name="Stellanova_(part_1)_286" geometry={nodes['Stellanova_(part_1)_286'].geometry} material={nodes['Stellanova_(part_1)_286'].material} position={[0, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_287" geometry={nodes['Stellanova_(part_1)_287'].geometry} material={nodes['Stellanova_(part_1)_287'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_288" geometry={nodes['Stellanova_(part_1)_288'].geometry} material={nodes['Stellanova_(part_1)_288'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_290" geometry={nodes['Stellanova_(part_1)_290'].geometry} material={nodes['Stellanova_(part_1)_290'].material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_291" geometry={nodes['Stellanova_(part_1)_291'].geometry} material={nodes['Stellanova_(part_1)_291'].material} position={[0, -0.04, 0]} />
          </group>
          <group name="light_curcle001" position={[6.71, 3.33, -1.6]} scale={[0.62, -0.62, 0.62]}>
            <mesh name="Circle524" geometry={nodes.Circle524.geometry} material={nodes.Circle524.material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Circle010" geometry={nodes.Circle010.geometry} material={nodes.Circle010.material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Circle011" geometry={nodes.Circle011.geometry} material={nodes.Circle011.material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Circle012" geometry={nodes.Circle012.geometry} material={nodes.Circle012.material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Circle013" geometry={nodes.Circle013.geometry} material={nodes.Circle013.material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Sn105" geometry={nodes.Sn105.geometry} material={nodes.Sn105.material} position={[0, 0.05, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0} />
          </group>
          <group name="Group003" position={[5.46, 1.22, 1.62]}>
            <mesh name="Cylinder297" geometry={nodes.Cylinder297.geometry} material={nodes.Cylinder297.material} position={[0, -0.07, 0]} />
          </group>
          <group name="Group004" position={[3.74, 1.22, 1.62]}>
            <mesh name="Cylinder2572" geometry={nodes.Cylinder2572.geometry} material={nodes.Cylinder2572.material} position={[0, -0.07, 0]} />
          </group>
          <group name="Group005" position={[4.54, 2.81, -0.07]} rotation={[-Math.PI, 0.87, -Math.PI]}>
            <mesh name="Sphere004" geometry={nodes.Sphere004.geometry} material={nodes.Sphere004.material} position={[0, -0.93, 0]} scale={[0.69, -0.69, 0.69]} />
            <mesh name="Cylinder738" geometry={nodes.Cylinder738.geometry} material={nodes.Cylinder738.material} position={[0, 0.47, 0]} />
          </group>
          <group name="Stellanova_(part_1)_229" position={[3.77, 3.32, -4.08]} rotation={[Math.PI, 0, Math.PI]}>
            <mesh name="Stellanova_(part_1)_222" geometry={nodes['Stellanova_(part_1)_222'].geometry} material={nodes['Stellanova_(part_1)_222'].material} position={[0, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_230" geometry={nodes['Stellanova_(part_1)_230'].geometry} material={nodes['Stellanova_(part_1)_230'].material} position={[0.06, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_223" geometry={nodes['Stellanova_(part_1)_223'].geometry} material={nodes['Stellanova_(part_1)_223'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_231" geometry={nodes['Stellanova_(part_1)_231'].geometry} material={nodes['Stellanova_(part_1)_231'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_224" geometry={nodes['Stellanova_(part_1)_224'].geometry} material={nodes['Stellanova_(part_1)_224'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_232" geometry={nodes['Stellanova_(part_1)_232'].geometry} material={nodes['Stellanova_(part_1)_232'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_226" geometry={nodes['Stellanova_(part_1)_226'].geometry} material={nodes['Stellanova_(part_1)_226'].material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_234" geometry={nodes['Stellanova_(part_1)_234'].geometry} material={nodes['Stellanova_(part_1)_234'].material} position={[0.06, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_227" geometry={nodes['Stellanova_(part_1)_227'].geometry} material={nodes['Stellanova_(part_1)_227'].material} position={[0, -0.04, 0]} />
          </group>
          <group name="Group013" position={[4.54, 2.81, -0.07]} rotation={[-Math.PI, -Math.PI / 9, 0]} scale={[1, -1, 1]}>
            <mesh name="Sphere1580" geometry={nodes.Sphere1580.geometry} material={nodes.Sphere1580.material} position={[0, -0.93, 0]} scale={[0.69, -0.69, 0.69]} />
            <mesh name="Cylinder2573" geometry={nodes.Cylinder2573.geometry} material={nodes.Cylinder2573.material} position={[0, 0.47, 0]} />
          </group>
          <group name="HUDSON_PIERRE_COFFE_TABLE" position={[4.54, 0.22, -4.32]} rotation={[0, 1.57, 0]}>
            <mesh name="Box006" geometry={nodes.Box006.geometry} material={nodes.Box006.material} position={[0, -0.1, 0]} />
            <mesh name="Box007" geometry={nodes.Box007.geometry} material={nodes.Box007.material} position={[-0.56, 0.04, -0.6]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Box008" geometry={nodes.Box008.geometry} material={nodes.Box008.material} position={[-0.57, 0, 0.5]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Box009" geometry={nodes.Box009.geometry} material={nodes.Box009.material} position={[-0.03, -0.15, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Box010" geometry={nodes.Box010.geometry} material={nodes.Box010.material} position={[-0.03, -0.15, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[1, -1, 1]} />
            <mesh name="Box005" geometry={nodes.Box005.geometry} material={nodes.Box005.material} position={[0, 0.2, 0]} />
          </group>
          <group name="Tessa_Skirted_Arm_Chair_002" position={[6.45, 0.54, 1.55]}>
            <group name="Auberge001" rotation={[-Math.PI, 0, -Math.PI]}>
              <mesh name="Box3840" geometry={nodes.Box3840.geometry} material={nodes.Box3840.material} position={[0, -0.12, 0.03]} />
              <mesh name="Box3841" geometry={nodes.Box3841.geometry} material={nodes.Box3841.material} position={[0, -0.12, -0.01]} />
              <mesh name="Box3842" geometry={nodes.Box3842.geometry} material={nodes.Box3842.material} position={[0, -0.53, 0.01]} />
            </group>
            <mesh name="Plane2308" geometry={nodes.Plane2308.geometry} material={nodes.Plane2308.material} position={[0.25, 0, 0.07]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
            <mesh name="Plane2309" geometry={nodes.Plane2309.geometry} material={nodes.Plane2309.material} position={[-0.25, 0, 0.07]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
          </group>
          <group name="Winston_Dining_Table_Sandro" position={[4.54, 0.55, -0.39]} scale={1.41}>
            <group name="Messina_Nesting_Side_Tables_West_Elm" position={[0, -0.07, -0.19]}>
              <group name="Calla_White" position={[-0.05, 0.4, 0.15]} rotation={[0, 1.22, 0]}>
                <mesh name="Rectangle001" geometry={nodes.Rectangle001.geometry} material={nodes.Rectangle001.material} position={[-0.01, -0.14, -0.02]} rotation={[Math.PI / 2, 0, 0]} />
                <mesh name="Object001" geometry={nodes.Object001.geometry} material={nodes.Object001.material} position={[-0.01, -0.04, -0.02]} rotation={[Math.PI / 2, 0, 0]} />
                <mesh name="Circle008" geometry={nodes.Circle008.geometry} material={nodes.Circle008.material} position={[-0.04, -0.23, -0.03]} rotation={[Math.PI, 1.57, 0]} scale={[0.96, -0.96, 0.96]} />
                <mesh name="Circle009" geometry={nodes.Circle009.geometry} material={nodes.Circle009.material} position={[-0.04, -0.23, -0.03]} rotation={[-Math.PI, -Math.PI / 3, 0]} scale={[0.96, -0.96, 0.96]} />
                <mesh name="Circle011_1" geometry={nodes.Circle011_1.geometry} material={nodes.Circle011_1.material} position={[-0.03, -0.23, -0.01]} rotation={[-Math.PI, -1.4, 0]} scale={[0.96, -0.96, 0.96]} />
                <mesh name="Circle012_1" geometry={nodes.Circle012_1.geometry} material={nodes.Circle012_1.material} position={[-0.03, -0.23, -0.02]} rotation={[0, -0.61, Math.PI]} scale={[0.96, -0.96, 0.96]} />
                <mesh name="Circle013_1" geometry={nodes.Circle013_1.geometry} material={nodes.Circle013_1.material} position={[-0.02, -0.23, -0.03]} rotation={[-Math.PI, 1.31, -Math.PI]} scale={0.96} />
                <mesh name="Circle014" geometry={nodes.Circle014.geometry} material={nodes.Circle014.material} position={[-0.01, -0.23, -0.02]} rotation={[0, 0, Math.PI]} scale={[0.96, -0.96, 0.96]} />
                <mesh name="Circle015" geometry={nodes.Circle015.geometry} material={nodes.Circle015.material} position={[-0.02, -0.23, -0.03]} rotation={[0, -Math.PI / 9, Math.PI]} scale={[0.96, -0.96, 0.96]} />
                <mesh name="Circle016" geometry={nodes.Circle016.geometry} material={nodes.Circle016.material} position={[0.01, -0.23, -0.01]} rotation={[-0.11, -0.01, 3.06]} scale={[0.96, -0.96, 0.96]} />
                <mesh name="Circle017" geometry={nodes.Circle017.geometry} material={nodes.Circle017.material} position={[-0.01, -0.23, -0.03]} rotation={[0, -1.13, Math.PI]} scale={[0.96, -0.96, 0.96]} />
                <mesh name="Circle018" geometry={nodes.Circle018.geometry} material={nodes.Circle018.material} position={[0, -0.23, -0.05]} rotation={[0, Math.PI / 4, 0]} scale={0.96} />
                <mesh name="Circle019" geometry={nodes.Circle019.geometry} material={nodes.Circle019.material} position={[-0.01, -0.23, -0.05]} rotation={[0, Math.PI / 3, -Math.PI]} scale={[0.96, -0.96, 0.96]} />
                <mesh name="Circle020" geometry={nodes.Circle020.geometry} material={nodes.Circle020.material} position={[0.01, -0.23, 0.02]} rotation={[-Math.PI, 0.96, 0]} scale={[0.96, -0.96, 0.96]} />
                <mesh name="Circle021" geometry={nodes.Circle021.geometry} material={nodes.Circle021.material} position={[-0.01, -0.23, 0.01]} rotation={[-0.32, -1.21, 2.75]} scale={[0.96, -0.96, 0.96]} />
                <mesh name="Circle022" geometry={nodes.Circle022.geometry} material={nodes.Circle022.material} position={[0, -0.23, 0.01]} rotation={[-Math.PI, -Math.PI / 4, 0]} scale={[0.96, -0.96, 0.96]} />
                <mesh name="Circle023" geometry={nodes.Circle023.geometry} material={nodes.Circle023.material} position={[-0.02, -0.23, -0.04]} rotation={[0, 0, Math.PI]} scale={[0.96, -0.96, 0.96]} />
              </group>
              <group name="Aces_002" position={[0.03, 0.22, 0.2]} rotation={[0, -Math.PI / 9, 0]} scale={1.23}>
                <mesh name="Shape002" geometry={nodes.Shape002.geometry} material={nodes.Shape002.material} />
                <mesh name="Sphere002" geometry={nodes.Sphere002.geometry} material={nodes.Sphere002.material} />
              </group>
            </group>
          </group>
          <group name="KOMOMBO" position={[3.09, 0.58, -2.29]} rotation={[0, Math.PI / 6, 0]}>
            <mesh name="Line133910361" geometry={nodes.Line133910361.geometry} material={nodes.Line133910361.material} position={[0.07, 0.55, -0.16]} rotation={[Math.PI / 2, 0, -Math.PI]} scale={[1.2, 1.2, 1]} />
            <mesh name="Rectangle294" geometry={nodes.Rectangle294.geometry} material={nodes.Rectangle294.material} position={[0.01, 0.56, 0.16]} rotation={[1.4, 0.77, -2.9]} scale={[1.94, 1.39, 1.77]} />
            <mesh name="Cylinder2574" geometry={nodes.Cylinder2574.geometry} material={nodes.Cylinder2574.material} position={[0, -0.57, -0.03]} />
            <mesh name="Line133910358" geometry={nodes.Line133910358.geometry} material={nodes.Line133910358.material} position={[0, -0.01, -0.03]} rotation={[Math.PI / 2, 0, -Math.PI]} />
          </group>
          <group name="Stellanova_(part_1)_301" position={[5.34, 3.32, -2.14]} rotation={[Math.PI, 0, Math.PI]}>
            <mesh name="Stellanova_(part_1)_294" geometry={nodes['Stellanova_(part_1)_294'].geometry} material={nodes['Stellanova_(part_1)_294'].material} position={[0, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_302" geometry={nodes['Stellanova_(part_1)_302'].geometry} material={nodes['Stellanova_(part_1)_302'].material} position={[0.06, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_295" geometry={nodes['Stellanova_(part_1)_295'].geometry} material={nodes['Stellanova_(part_1)_295'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_303" geometry={nodes['Stellanova_(part_1)_303'].geometry} material={nodes['Stellanova_(part_1)_303'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_296" geometry={nodes['Stellanova_(part_1)_296'].geometry} material={nodes['Stellanova_(part_1)_296'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_304" geometry={nodes['Stellanova_(part_1)_304'].geometry} material={nodes['Stellanova_(part_1)_304'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_298" geometry={nodes['Stellanova_(part_1)_298'].geometry} material={nodes['Stellanova_(part_1)_298'].material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_306" geometry={nodes['Stellanova_(part_1)_306'].geometry} material={nodes['Stellanova_(part_1)_306'].material} position={[0.06, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_299" geometry={nodes['Stellanova_(part_1)_299'].geometry} material={nodes['Stellanova_(part_1)_299'].material} position={[0, -0.04, 0]} />
          </group>
          <group name="Stellanova_(part_1)_315" position={[3.77, 3.32, -2.14]} rotation={[Math.PI, 0, Math.PI]}>
            <mesh name="Stellanova_(part_1)_308" geometry={nodes['Stellanova_(part_1)_308'].geometry} material={nodes['Stellanova_(part_1)_308'].material} position={[0, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_316" geometry={nodes['Stellanova_(part_1)_316'].geometry} material={nodes['Stellanova_(part_1)_316'].material} position={[0.06, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_309" geometry={nodes['Stellanova_(part_1)_309'].geometry} material={nodes['Stellanova_(part_1)_309'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_317" geometry={nodes['Stellanova_(part_1)_317'].geometry} material={nodes['Stellanova_(part_1)_317'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_310" geometry={nodes['Stellanova_(part_1)_310'].geometry} material={nodes['Stellanova_(part_1)_310'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_318" geometry={nodes['Stellanova_(part_1)_318'].geometry} material={nodes['Stellanova_(part_1)_318'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_312" geometry={nodes['Stellanova_(part_1)_312'].geometry} material={nodes['Stellanova_(part_1)_312'].material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_320" geometry={nodes['Stellanova_(part_1)_320'].geometry} material={nodes['Stellanova_(part_1)_320'].material} position={[0.06, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_313" geometry={nodes['Stellanova_(part_1)_313'].geometry} material={nodes['Stellanova_(part_1)_313'].material} position={[0, -0.04, 0]} />
          </group>
          <group name="Fruit_Platter_VID_02_011" position={[4.01, 0.87, -0.45]} scale={0.82}>
            <group name="Fruit_Platter_VID_02_002" position={[0, -0.04, 0]} scale={[1, 0.79, 1]}>
              <mesh name="Fruit_Platter_VID_02_003" geometry={nodes.Fruit_Platter_VID_02_003.geometry} material={nodes.Fruit_Platter_VID_02_003.material} position={[0.19, -0.08, -0.02]} rotation={[1.45, -0.19, 0.13]} scale={160.42} />
              <mesh name="Fruit_Platter_VID_02_004" geometry={nodes.Fruit_Platter_VID_02_004.geometry} material={nodes.Fruit_Platter_VID_02_004.material} position={[0.08, 0.02, -0.04]} rotation={[-0.82, -0.14, -1.56]} scale={160.42} />
              <mesh name="Fruit_Platter_VID_02_005" geometry={nodes.Fruit_Platter_VID_02_005.geometry} material={nodes.Fruit_Platter_VID_02_005.material} position={[-0.04, -0.08, -0.14]} rotation={[-1.43, -0.08, -2]} scale={160.42} />
              <mesh name="Fruit_Platter_VID_02_001" geometry={nodes.Fruit_Platter_VID_02_001.geometry} material={nodes.Fruit_Platter_VID_02_001.material} position={[0.08, -0.13, 0.11]} rotation={[2.71, -1.01, 2.74]} scale={160.42} />
              <mesh name="Fruit_Platter_VID_02_007" geometry={nodes.Fruit_Platter_VID_02_007.geometry} material={nodes.Fruit_Platter_VID_02_007.material} position={[-0.07, -0.05, -0.17]} rotation={[1.13, 1.25, 0.36]} scale={160.42} />
              <mesh name="Fruit_Platter_VID_02_010" geometry={nodes.Fruit_Platter_VID_02_010.geometry} material={nodes.Fruit_Platter_VID_02_010.material} position={[0.17, -0.06, -0.17]} rotation={[3.06, 0.62, -1.4]} scale={160.42} />
            </group>
          </group>
          <group name="Stellanova_(part_1)_329" position={[2.65, 3.32, -3.43]} rotation={[0, -1.57, 0]}>
            <mesh name="Stellanova_(part_1)_322" geometry={nodes['Stellanova_(part_1)_322'].geometry} material={nodes['Stellanova_(part_1)_322'].material} position={[0, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_330" geometry={nodes['Stellanova_(part_1)_330'].geometry} material={nodes['Stellanova_(part_1)_330'].material} position={[0.06, 0.02, 0]} rotation={[Math.PI, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_323" geometry={nodes['Stellanova_(part_1)_323'].geometry} material={nodes['Stellanova_(part_1)_323'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_331" geometry={nodes['Stellanova_(part_1)_331'].geometry} material={nodes['Stellanova_(part_1)_331'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_324" geometry={nodes['Stellanova_(part_1)_324'].geometry} material={nodes['Stellanova_(part_1)_324'].material} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_332" geometry={nodes['Stellanova_(part_1)_332'].geometry} material={nodes['Stellanova_(part_1)_332'].material} position={[0.06, 0, 0]} rotation={[Math.PI, 0, 0]} />
            <mesh name="Stellanova_(part_1)_326" geometry={nodes['Stellanova_(part_1)_326'].geometry} material={nodes['Stellanova_(part_1)_326'].material} position={[0, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_334" geometry={nodes['Stellanova_(part_1)_334'].geometry} material={nodes['Stellanova_(part_1)_334'].material} position={[0.06, 0.01, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_327" geometry={nodes['Stellanova_(part_1)_327'].geometry} material={nodes['Stellanova_(part_1)_327'].material} position={[0, -0.04, 0]} />
          </group>
          <group name="Jalusee001" position={[0.42, 2.3, 2.06]} rotation={[-Math.PI, 0, -Math.PI]} scale={1.39}>
            <mesh name="Capsule1172" geometry={nodes.Capsule1172.geometry} material={nodes.Capsule1172.material} position={[-0.28, 0.67, 0.01]} rotation={[0.02, 0, 0]} scale={[1.81, 1.21, 1.79]} />
            <mesh name="Cylinder2575" geometry={nodes.Cylinder2575.geometry} material={nodes.Cylinder2575.material} position={[-0.28, 0.72, 0.01]} rotation={[0.02, -0.87, 0]} />
            <mesh name="Rectangle298" geometry={nodes.Rectangle298.geometry} material={nodes.Rectangle298.material} position={[0, 0.69, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle299" geometry={nodes.Rectangle299.geometry} material={nodes.Rectangle299.material} position={[0, 0.68, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle571" geometry={nodes.Rectangle571.geometry} material={nodes.Rectangle571.material} position={[0, 0.49, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle300" geometry={nodes.Rectangle300.geometry} material={nodes.Rectangle300.material} position={[0, 0.66, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle572" geometry={nodes.Rectangle572.geometry} material={nodes.Rectangle572.material} position={[0, 0.48, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle301" geometry={nodes.Rectangle301.geometry} material={nodes.Rectangle301.material} position={[0, 0.64, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle573" geometry={nodes.Rectangle573.geometry} material={nodes.Rectangle573.material} position={[0, 0.46, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle302" geometry={nodes.Rectangle302.geometry} material={nodes.Rectangle302.material} position={[0, 0.63, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle574" geometry={nodes.Rectangle574.geometry} material={nodes.Rectangle574.material} position={[0, 0.44, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle303" geometry={nodes.Rectangle303.geometry} material={nodes.Rectangle303.material} position={[0, 0.61, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle575" geometry={nodes.Rectangle575.geometry} material={nodes.Rectangle575.material} position={[0, 0.43, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle304" geometry={nodes.Rectangle304.geometry} material={nodes.Rectangle304.material} position={[0, 0.59, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle576" geometry={nodes.Rectangle576.geometry} material={nodes.Rectangle576.material} position={[0, 0.41, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle578" geometry={nodes.Rectangle578.geometry} material={nodes.Rectangle578.material} position={[0, 0.38, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle305" geometry={nodes.Rectangle305.geometry} material={nodes.Rectangle305.material} position={[0, 0.58, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle577" geometry={nodes.Rectangle577.geometry} material={nodes.Rectangle577.material} position={[0, 0.39, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle579" geometry={nodes.Rectangle579.geometry} material={nodes.Rectangle579.material} position={[0, 0.36, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle306" geometry={nodes.Rectangle306.geometry} material={nodes.Rectangle306.material} position={[0, 0.56, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle307" geometry={nodes.Rectangle307.geometry} material={nodes.Rectangle307.material} position={[0, 0.54, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle308" geometry={nodes.Rectangle308.geometry} material={nodes.Rectangle308.material} position={[0, 0.53, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle309" geometry={nodes.Rectangle309.geometry} material={nodes.Rectangle309.material} position={[0, 0.51, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle317" geometry={nodes.Rectangle317.geometry} material={nodes.Rectangle317.material} position={[0, 0.34, 0]} rotation={[-1.92, 0, 0]} />
            <mesh name="Rectangle361" geometry={nodes.Rectangle361.geometry} material={nodes.Rectangle361.material} position={[0, 0.32, 0]} rotation={[-2.19, 0, 0]} />
            <mesh name="Rectangle362" geometry={nodes.Rectangle362.geometry} material={nodes.Rectangle362.material} position={[0, 0.31, 0]} rotation={[-2.79, 0, 0]} />
            <mesh name="Rectangle368" geometry={nodes.Rectangle368.geometry} material={nodes.Rectangle368.material} position={[-0.17, 0.69, 0]} rotation={[2.79, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
            <mesh name="Rectangle369" geometry={nodes.Rectangle369.geometry} material={nodes.Rectangle369.material} position={[0.18, 0.69, 0]} rotation={[2.79, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
            <mesh name="Box7203" geometry={nodes.Box7203.geometry} material={nodes.Box7203.material} position={[-0.17, 0.71, 0]} rotation={[-Math.PI, 0, 0]} />
            <mesh name="Box7204" geometry={nodes.Box7204.geometry} material={nodes.Box7204.material} position={[0.18, 0.71, 0]} rotation={[-Math.PI, 0, 0]} />
            <mesh name="Box7205" geometry={nodes.Box7205.geometry} material={nodes.Box7205.material} position={[-0.21, 0.72, 0.01]} />
            <mesh name="Box7206" geometry={nodes.Box7206.geometry} material={nodes.Box7206.material} position={[0.27, 0.72, 0.01]} />
            <mesh name="Cylinder2576" geometry={nodes.Cylinder2576.geometry} material={nodes.Cylinder2576.material} position={[-0.28, 0.71, 0]} rotation={[2.62, 0, 0]} scale={[1, 1, 0.96]} />
            <mesh name="Line1789529827" geometry={nodes.Line1789529827.geometry} material={nodes.Line1789529827.material} position={[-0.31, 0.15, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Line1789529828" geometry={nodes.Line1789529828.geometry} material={nodes.Line1789529828.material} position={[0.31, 0.1, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Line1789529829" geometry={nodes.Line1789529829.geometry} material={nodes.Line1789529829.material} position={[-0.28, 0.69, 0.01]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Line1789529830" geometry={nodes.Line1789529830.geometry} material={nodes.Line1789529830.material} position={[-0.24, 0.71, 0.01]} rotation={[0.17, 0, 0]} />
            <mesh name="Line1789529831" geometry={nodes.Line1789529831.geometry} material={nodes.Line1789529831.material} position={[-0.25, 0.02, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={0.49} />
            <mesh name="Line1789529832" geometry={nodes.Line1789529832.geometry} material={nodes.Line1789529832.material} position={[-0.25, 0.26, 0.05]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Line1789529833" geometry={nodes.Line1789529833.geometry} material={nodes.Line1789529833.material} position={[-0.18, -0.04, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Line1789529834" geometry={nodes.Line1789529834.geometry} material={nodes.Line1789529834.material} position={[-0.31, 0.15, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Line1789529835" geometry={nodes.Line1789529835.geometry} material={nodes.Line1789529835.material} position={[0.31, 0.1, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Line1789529836" geometry={nodes.Line1789529836.geometry} material={nodes.Line1789529836.material} position={[-0.18, -0.04, -0.01]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
            <mesh name="Rectangle297" geometry={nodes.Rectangle297.geometry} material={nodes.Rectangle297.material} position={[0, 0.72, 0.01]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Rectangle363" geometry={nodes.Rectangle363.geometry} material={nodes.Rectangle363.material} position={[0, 0.3, 0]} scale={[1, -1, 1]} />
            <mesh name="Rectangle364" geometry={nodes.Rectangle364.geometry} material={nodes.Rectangle364.material} position={[0, 0.3, 0]} rotation={[-Math.PI, 0, 0]} />
            <mesh name="Rectangle365" geometry={nodes.Rectangle365.geometry} material={nodes.Rectangle365.material} position={[0.32, 0.29, 0]} rotation={[1.55, 0, 1.57]} scale={[0.97, 1, 1]} />
            <mesh name="Rectangle366" geometry={nodes.Rectangle366.geometry} material={nodes.Rectangle366.material} position={[-0.31, 0.31, -0.02]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Rectangle367" geometry={nodes.Rectangle367.geometry} material={nodes.Rectangle367.material} position={[0.31, 0.3, -0.02]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Rectangle370" geometry={nodes.Rectangle370.geometry} material={nodes.Rectangle370.material} position={[-0.33, 0.72, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Star3321" geometry={nodes.Star3321.geometry} material={nodes.Star3321.material} position={[-0.24, 0.71, 0]} rotation={[1.07, 0, 0]} />
            <mesh name="Tube019" geometry={nodes.Tube019.geometry} material={nodes.Tube019.material} position={[-0.31, 0.71, 0]} />
            <mesh name="Tube020" geometry={nodes.Tube020.geometry} material={nodes.Tube020.material} position={[0.31, 0.71, 0]} />
            <mesh name="Tube021" geometry={nodes.Tube021.geometry} material={nodes.Tube021.material} position={[-0.31, 0.31, 0]} />
          </group>
          <group name="Stellanova_(part_1)_343" position={[-0.96, 3.34, -1.23]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh name="Stellanova_(part_1)_341" geometry={nodes['Stellanova_(part_1)_341'].geometry} material={nodes['Stellanova_(part_1)_341'].material} position={[0, 0, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_340" geometry={nodes['Stellanova_(part_1)_340'].geometry} material={nodes['Stellanova_(part_1)_340'].material} position={[0, 0, -0.02]} />
            <mesh name="Stellanova_(part_1)_338" geometry={nodes['Stellanova_(part_1)_338'].geometry} material={nodes['Stellanova_(part_1)_338'].material} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_337" geometry={nodes['Stellanova_(part_1)_337'].geometry} material={nodes['Stellanova_(part_1)_337'].material} position={[0, 0, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_336" geometry={nodes['Stellanova_(part_1)_336'].geometry} material={nodes['Stellanova_(part_1)_336'].material} position={[0, 0, 0.03]} rotation={[-Math.PI / 2, 0, 0]} />
          </group>
          <group name="Stellanova_(part_1)_351" position={[-0.96, 3.34, -0.04]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh name="Stellanova_(part_1)_349" geometry={nodes['Stellanova_(part_1)_349'].geometry} material={nodes['Stellanova_(part_1)_349'].material} position={[0, 0, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_348" geometry={nodes['Stellanova_(part_1)_348'].geometry} material={nodes['Stellanova_(part_1)_348'].material} position={[0, 0, -0.02]} />
            <mesh name="Stellanova_(part_1)_346" geometry={nodes['Stellanova_(part_1)_346'].geometry} material={nodes['Stellanova_(part_1)_346'].material} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_345" geometry={nodes['Stellanova_(part_1)_345'].geometry} material={nodes['Stellanova_(part_1)_345'].material} position={[0, 0, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_344" geometry={nodes['Stellanova_(part_1)_344'].geometry} material={nodes['Stellanova_(part_1)_344'].material} position={[0, 0, 0.03]} rotation={[-Math.PI / 2, 0, 0]} />
          </group>
          <group name="Stellanova_(part_1)_359" position={[-0.96, 3.34, 1.21]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh name="Stellanova_(part_1)_357" geometry={nodes['Stellanova_(part_1)_357'].geometry} material={nodes['Stellanova_(part_1)_357'].material} position={[0, 0, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={[1.3, 1, 1.3]} />
            <mesh name="Stellanova_(part_1)_356" geometry={nodes['Stellanova_(part_1)_356'].geometry} material={nodes['Stellanova_(part_1)_356'].material} position={[0, 0, -0.02]} />
            <mesh name="Stellanova_(part_1)_354" geometry={nodes['Stellanova_(part_1)_354'].geometry} material={nodes['Stellanova_(part_1)_354'].material} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_353" geometry={nodes['Stellanova_(part_1)_353'].geometry} material={nodes['Stellanova_(part_1)_353'].material} position={[0, 0, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="Stellanova_(part_1)_352" geometry={nodes['Stellanova_(part_1)_352'].geometry} material={nodes['Stellanova_(part_1)_352'].material} position={[0, 0, 0.03]} rotation={[-Math.PI / 2, 0, 0]} />
          </group>
          <group name="а3а" position={[2.87, 0.48, 1.39]} rotation={[-Math.PI, 0, -Math.PI]} scale={1.23}>
            <mesh name="Box003" geometry={nodes.Box003.geometry} material={nodes.Box003.material} position={[0, 0.04, -0.2]} />
            <mesh name="Box7230" geometry={nodes.Box7230.geometry} material={nodes.Box7230.material} position={[0, -0.39, 0.06]} />
          </group>
          <group name="stolik_003" position={[-1.83, 0.36, 1.34]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh name="Box020" geometry={nodes.Box020.geometry} material={nodes.Box020.material} position={[0, -0.36, 0]} rotation={[0, Math.PI / 2, 0]} />
            <mesh name="Box021" geometry={nodes.Box021.geometry} material={nodes.Box021.material} position={[-0.22, 0.27, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Box022" geometry={nodes.Box022.geometry} material={nodes.Box022.material} position={[-0.22, 0.27, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.59} />
            <mesh name="Box023" geometry={nodes.Box023.geometry} material={nodes.Box023.material} position={[-0.22, -0.1, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.59} />
            <mesh name="Line034" geometry={nodes.Line034.geometry} material={nodes.Line034.material} position={[-0.22, 0.27, -0.02]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.59, -0.59, 0.59]} />
            <mesh name="Line035" geometry={nodes.Line035.geometry} material={nodes.Line035.material} position={[-0.22, -0.11, -0.02]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.59, -0.59, 0.59]} />
            <mesh name="Object5579_(1)" geometry={nodes['Object5579_(1)'].geometry} material={nodes['Object5579_(1)'].material} rotation={[0, 1.57, 0]} />
          </group>
          <group name="Lamp" position={[-1.85, 1, -1.55]} rotation={[-Math.PI, 0, -Math.PI]}>
            <group name="CoronaLight002" position={[0, 0.14, 0]} />
            <mesh name="Sweeney_Lamp" geometry={nodes.Sweeney_Lamp.geometry} material={nodes.Sweeney_Lamp.material} rotation={[0, 1.4, 0]} scale={1.89} />
          </group>
          <group name="Lamp001" position={[-1.85, 1, 1.37]} rotation={[-Math.PI, 0, -Math.PI]}>
            <group name="CoronaLight003" position={[0, 0.14, 0]} />
            <mesh name="Sweeney_Lamp001" geometry={nodes.Sweeney_Lamp001.geometry} material={nodes.Sweeney_Lamp001.material} rotation={[0, 1.4, 0]} scale={1.89} />
          </group>
          <group name="Group1871013737" position={[1.19, 0.46, 1.52]} rotation={[-Math.PI, -0.96, 0]} scale={[0.77, -1, 0.77]}>
            <mesh name="Object5581" geometry={nodes.Object5581.geometry} material={nodes.Object5581.material} position={[0.25, -0.31, 0.03]} scale={[1.65, 1.57, 1.82]} />
            <mesh name="armchair002" geometry={nodes.armchair002.geometry} material={nodes.armchair002.material} position={[0.25, -0.31, 0.03]} scale={[1.65, 1.57, 1.82]} />
          </group>
          <group name="Group2069533100" position={[0.02, 1.69, 1.86]} rotation={[0, -1.57, 0]} scale={[0.6, 0.93, 0.96]}>
            <mesh name="Plane1857" geometry={nodes.Plane1857.geometry} material={nodes.Plane1857.material} position={[-0.02, -0.28, -1.87]} />
            <mesh name="Plane1875_(1)" geometry={nodes['Plane1875_(1)'].geometry} material={nodes['Plane1875_(1)'].material} position={[-0.02, -0.28, 2.21]} rotation={[-Math.PI, 0, 0]} scale={[1, -1, 1]} />
          </group>
          <group name="Group2069533101" position={[-0.13, 2.7, -0.14]} rotation={[-Math.PI, 1.31, -Math.PI]}>
            <group name="CoronaLight013" position={[0.15, -0.16, -0.12]} scale={1.22} />
            <group name="CoronaLight016" position={[-0.14, -0.16, 0.12]} scale={1.22} />
            <group name="CoronaLight015" position={[0.15, -0.16, 0.12]} scale={1.22} />
            <group name="CoronaLight014" position={[-0.14, -0.16, -0.12]} scale={1.22} />
            <mesh name="Cylinder238" geometry={nodes.Cylinder238.geometry} material={nodes.Cylinder238.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 1.52]} scale={1.32} />
            <mesh name="Cylinder237" geometry={nodes.Cylinder237.geometry} material={nodes.Cylinder237.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 1.78]} scale={1.32} />
            <mesh name="Cylinder236" geometry={nodes.Cylinder236.geometry} material={nodes.Cylinder236.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 2.04]} scale={1.32} />
            <mesh name="Cylinder235" geometry={nodes.Cylinder235.geometry} material={nodes.Cylinder235.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 2.31]} scale={1.32} />
            <mesh name="Cylinder234" geometry={nodes.Cylinder234.geometry} material={nodes.Cylinder234.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 2.57]} scale={1.32} />
            <mesh name="Cylinder233" geometry={nodes.Cylinder233.geometry} material={nodes.Cylinder233.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 2.83]} scale={1.32} />
            <mesh name="Cylinder232" geometry={nodes.Cylinder232.geometry} material={nodes.Cylinder232.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 3.09]} scale={1.32} />
            <mesh name="Cylinder226" geometry={nodes.Cylinder226.geometry} material={nodes.Cylinder226.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, -1.62]} scale={1.32} />
            <mesh name="Cylinder225" geometry={nodes.Cylinder225.geometry} material={nodes.Cylinder225.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, -1.36]} scale={1.32} />
            <mesh name="Cylinder224" geometry={nodes.Cylinder224.geometry} material={nodes.Cylinder224.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, -1.1]} scale={1.32} />
            <mesh name="Cylinder223" geometry={nodes.Cylinder223.geometry} material={nodes.Cylinder223.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, -0.84]} scale={1.32} />
            <mesh name="Cylinder222" geometry={nodes.Cylinder222.geometry} material={nodes.Cylinder222.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, -0.57]} scale={1.32} />
            <mesh name="Cylinder221" geometry={nodes.Cylinder221.geometry} material={nodes.Cylinder221.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, -0.31]} scale={1.32} />
            <mesh name="Cylinder220" geometry={nodes.Cylinder220.geometry} material={nodes.Cylinder220.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, -0.05]} scale={1.32} />
            <mesh name="Cylinder219" geometry={nodes.Cylinder219.geometry} material={nodes.Cylinder219.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 0.21]} scale={1.32} />
            <mesh name="Cylinder218" geometry={nodes.Cylinder218.geometry} material={nodes.Cylinder218.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 0.47]} scale={1.32} />
            <mesh name="Cylinder217" geometry={nodes.Cylinder217.geometry} material={nodes.Cylinder217.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 0.73]} scale={1.32} />
            <mesh name="Cylinder216" geometry={nodes.Cylinder216.geometry} material={nodes.Cylinder216.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 1]} scale={1.32} />
            <mesh name="Cylinder215" geometry={nodes.Cylinder215.geometry} material={nodes.Cylinder215.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 1.26]} scale={1.32} />
            <mesh name="Cylinder214" geometry={nodes.Cylinder214.geometry} material={nodes.Cylinder214.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 1.52]} scale={1.32} />
            <mesh name="Cylinder213" geometry={nodes.Cylinder213.geometry} material={nodes.Cylinder213.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 1.78]} scale={1.32} />
            <mesh name="Cylinder212" geometry={nodes.Cylinder212.geometry} material={nodes.Cylinder212.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 2.04]} scale={1.32} />
            <mesh name="Cylinder211" geometry={nodes.Cylinder211.geometry} material={nodes.Cylinder211.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 2.31]} scale={1.32} />
            <mesh name="Cylinder210" geometry={nodes.Cylinder210.geometry} material={nodes.Cylinder210.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 2.57]} scale={1.32} />
            <mesh name="Cylinder209" geometry={nodes.Cylinder209.geometry} material={nodes.Cylinder209.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, 2.83]} scale={1.32} />
            <mesh name="Cylinder207" geometry={nodes.Cylinder207.geometry} material={nodes.Cylinder207.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, -2.93]} scale={1.32} />
            <mesh name="Cylinder206" geometry={nodes.Cylinder206.geometry} material={nodes.Cylinder206.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, -2.67]} scale={1.32} />
            <mesh name="Cylinder205" geometry={nodes.Cylinder205.geometry} material={nodes.Cylinder205.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, -2.41]} scale={1.32} />
            <mesh name="Cylinder204" geometry={nodes.Cylinder204.geometry} material={nodes.Cylinder204.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, -2.15]} scale={1.32} />
            <mesh name="Cylinder014" geometry={nodes.Cylinder014.geometry} material={nodes.Cylinder014.material} position={[0, -0.16, 0]} rotation={[Math.PI / 2, 0, -1.88]} scale={1.32} />
            <mesh name="Cylinder199" geometry={nodes.Cylinder199.geometry} material={nodes.Cylinder199.material} position={[0, -0.3, 0]} scale={1.16} />
            <mesh name="Cylinder200" geometry={nodes.Cylinder200.geometry} material={nodes.Cylinder200.material} position={[0, -0.31, 0]} scale={1.16} />
            <mesh name="Cylinder195" geometry={nodes.Cylinder195.geometry} material={nodes.Cylinder195.material} position={[0, -0.29, 0]} rotation={[0, 0.16, 0]} scale={[0.77, 0.28, 0.77]} />
            <mesh name="Cylinder194" geometry={nodes.Cylinder194.geometry} material={nodes.Cylinder194.material} position={[0, -0.03, 0]} rotation={[0, 0.16, 0]} scale={[0.77, 0.36, 0.77]} />
            <mesh name="Cylinder193" geometry={nodes.Cylinder193.geometry} material={nodes.Cylinder193.material} position={[0, -0.07, 0]} rotation={[0, 0.16, 0]} scale={[0.99, 0.38, 0.99]} />
            <mesh name="Cylinder197" geometry={nodes.Cylinder197.geometry} material={nodes.Cylinder197.material} position={[0, -0.26, 0]} rotation={[0, 0.16, 0]} scale={[0.99, 0.36, 0.99]} />
            <mesh name="Cylinder191" geometry={nodes.Cylinder191.geometry} material={nodes.Cylinder191.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 0.22]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder190" geometry={nodes.Cylinder190.geometry} material={nodes.Cylinder190.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 0.43]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder189" geometry={nodes.Cylinder189.geometry} material={nodes.Cylinder189.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 0.65]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder188" geometry={nodes.Cylinder188.geometry} material={nodes.Cylinder188.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 0.88]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder187" geometry={nodes.Cylinder187.geometry} material={nodes.Cylinder187.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 1.08]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder186" geometry={nodes.Cylinder186.geometry} material={nodes.Cylinder186.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 1.28]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder185" geometry={nodes.Cylinder185.geometry} material={nodes.Cylinder185.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 1.48]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder184" geometry={nodes.Cylinder184.geometry} material={nodes.Cylinder184.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 1.68]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder183" geometry={nodes.Cylinder183.geometry} material={nodes.Cylinder183.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 1.88]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder182" geometry={nodes.Cylinder182.geometry} material={nodes.Cylinder182.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 2.08]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder181" geometry={nodes.Cylinder181.geometry} material={nodes.Cylinder181.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 2.28]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder180" geometry={nodes.Cylinder180.geometry} material={nodes.Cylinder180.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 2.48]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder179" geometry={nodes.Cylinder179.geometry} material={nodes.Cylinder179.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 2.68]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder178" geometry={nodes.Cylinder178.geometry} material={nodes.Cylinder178.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 2.88]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder177" geometry={nodes.Cylinder177.geometry} material={nodes.Cylinder177.material} position={[0, -0.29, 0]} rotation={[1.57, 0, 3.08]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder176" geometry={nodes.Cylinder176.geometry} material={nodes.Cylinder176.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -3]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder175" geometry={nodes.Cylinder175.geometry} material={nodes.Cylinder175.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -2.8]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder174" geometry={nodes.Cylinder174.geometry} material={nodes.Cylinder174.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -2.6]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder173" geometry={nodes.Cylinder173.geometry} material={nodes.Cylinder173.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -2.4]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder172" geometry={nodes.Cylinder172.geometry} material={nodes.Cylinder172.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -2.2]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder171" geometry={nodes.Cylinder171.geometry} material={nodes.Cylinder171.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -2]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder170" geometry={nodes.Cylinder170.geometry} material={nodes.Cylinder170.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -1.8]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder169" geometry={nodes.Cylinder169.geometry} material={nodes.Cylinder169.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -1.6]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder168" geometry={nodes.Cylinder168.geometry} material={nodes.Cylinder168.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -1.4]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder167" geometry={nodes.Cylinder167.geometry} material={nodes.Cylinder167.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -1.2]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder166" geometry={nodes.Cylinder166.geometry} material={nodes.Cylinder166.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -1]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder165" geometry={nodes.Cylinder165.geometry} material={nodes.Cylinder165.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -0.8]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder164" geometry={nodes.Cylinder164.geometry} material={nodes.Cylinder164.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -0.6]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder163" geometry={nodes.Cylinder163.geometry} material={nodes.Cylinder163.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -0.4]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder162" geometry={nodes.Cylinder162.geometry} material={nodes.Cylinder162.material} position={[0, -0.29, 0]} rotation={[1.57, 0, -0.2]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder161" geometry={nodes.Cylinder161.geometry} material={nodes.Cylinder161.material} position={[0, -0.29, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[0.6, 0.6, 0.61]} />
            <mesh name="Cylinder160" geometry={nodes.Cylinder160.geometry} material={nodes.Cylinder160.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 0.22]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder159" geometry={nodes.Cylinder159.geometry} material={nodes.Cylinder159.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 0.43]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder158" geometry={nodes.Cylinder158.geometry} material={nodes.Cylinder158.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 0.65]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder157" geometry={nodes.Cylinder157.geometry} material={nodes.Cylinder157.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 0.88]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder156" geometry={nodes.Cylinder156.geometry} material={nodes.Cylinder156.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 1.08]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder155" geometry={nodes.Cylinder155.geometry} material={nodes.Cylinder155.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 1.28]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder154" geometry={nodes.Cylinder154.geometry} material={nodes.Cylinder154.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 1.48]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder153" geometry={nodes.Cylinder153.geometry} material={nodes.Cylinder153.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 1.68]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder152" geometry={nodes.Cylinder152.geometry} material={nodes.Cylinder152.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 1.88]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder151" geometry={nodes.Cylinder151.geometry} material={nodes.Cylinder151.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 2.08]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder150" geometry={nodes.Cylinder150.geometry} material={nodes.Cylinder150.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 2.28]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder149" geometry={nodes.Cylinder149.geometry} material={nodes.Cylinder149.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 2.48]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder148" geometry={nodes.Cylinder148.geometry} material={nodes.Cylinder148.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 2.68]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder147" geometry={nodes.Cylinder147.geometry} material={nodes.Cylinder147.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 2.88]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder146" geometry={nodes.Cylinder146.geometry} material={nodes.Cylinder146.material} position={[0, -0.03, 0]} rotation={[1.57, 0, 3.08]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder145" geometry={nodes.Cylinder145.geometry} material={nodes.Cylinder145.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -3]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder144" geometry={nodes.Cylinder144.geometry} material={nodes.Cylinder144.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -2.8]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder143" geometry={nodes.Cylinder143.geometry} material={nodes.Cylinder143.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -2.6]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder142" geometry={nodes.Cylinder142.geometry} material={nodes.Cylinder142.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -2.4]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder141" geometry={nodes.Cylinder141.geometry} material={nodes.Cylinder141.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -2.2]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder140" geometry={nodes.Cylinder140.geometry} material={nodes.Cylinder140.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -2]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder139" geometry={nodes.Cylinder139.geometry} material={nodes.Cylinder139.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -1.8]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder138" geometry={nodes.Cylinder138.geometry} material={nodes.Cylinder138.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -1.6]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder137" geometry={nodes.Cylinder137.geometry} material={nodes.Cylinder137.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -1.4]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder136" geometry={nodes.Cylinder136.geometry} material={nodes.Cylinder136.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -1.2]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder135" geometry={nodes.Cylinder135.geometry} material={nodes.Cylinder135.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -1]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder134" geometry={nodes.Cylinder134.geometry} material={nodes.Cylinder134.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -0.8]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder133" geometry={nodes.Cylinder133.geometry} material={nodes.Cylinder133.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -0.6]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder132" geometry={nodes.Cylinder132.geometry} material={nodes.Cylinder132.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -0.4]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder131" geometry={nodes.Cylinder131.geometry} material={nodes.Cylinder131.material} position={[0, -0.03, 0]} rotation={[1.57, 0, -0.2]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder130" geometry={nodes.Cylinder130.geometry} material={nodes.Cylinder130.material} position={[0, -0.03, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[0.61, 0.61, 0.62]} />
            <mesh name="Cylinder129" geometry={nodes.Cylinder129.geometry} material={nodes.Cylinder129.material} position={[0, -0.25, 0]} rotation={[1.57, 0, 0.65]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder128" geometry={nodes.Cylinder128.geometry} material={nodes.Cylinder128.material} position={[0, -0.25, 0]} rotation={[1.57, 0, 0.9]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder127" geometry={nodes.Cylinder127.geometry} material={nodes.Cylinder127.material} position={[0, -0.25, 0]} rotation={[1.57, 0, 1.15]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder126" geometry={nodes.Cylinder126.geometry} material={nodes.Cylinder126.material} position={[0, -0.25, 0]} rotation={[1.57, 0, 1.4]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder125" geometry={nodes.Cylinder125.geometry} material={nodes.Cylinder125.material} position={[0, -0.25, 0]} rotation={[1.57, 0, 1.65]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder124" geometry={nodes.Cylinder124.geometry} material={nodes.Cylinder124.material} position={[0, -0.25, 0]} rotation={[1.57, 0, 1.9]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder123" geometry={nodes.Cylinder123.geometry} material={nodes.Cylinder123.material} position={[0, -0.25, 0]} rotation={[1.57, 0, 2.14]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder122" geometry={nodes.Cylinder122.geometry} material={nodes.Cylinder122.material} position={[0, -0.25, 0]} rotation={[1.57, 0, 2.39]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder121" geometry={nodes.Cylinder121.geometry} material={nodes.Cylinder121.material} position={[0, -0.25, 0]} rotation={[1.57, 0, 2.64]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder120" geometry={nodes.Cylinder120.geometry} material={nodes.Cylinder120.material} position={[0, -0.25, 0]} rotation={[1.57, 0, 2.89]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder119" geometry={nodes.Cylinder119.geometry} material={nodes.Cylinder119.material} position={[0, -0.25, 0]} rotation={[Math.PI / 2, 0, 3.14]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder118" geometry={nodes.Cylinder118.geometry} material={nodes.Cylinder118.material} position={[0, -0.25, 0]} rotation={[1.57, 0, -2.9]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder117" geometry={nodes.Cylinder117.geometry} material={nodes.Cylinder117.material} position={[0, -0.25, 0]} rotation={[1.57, 0, -2.65]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder116" geometry={nodes.Cylinder116.geometry} material={nodes.Cylinder116.material} position={[0, -0.25, 0]} rotation={[1.57, 0, -2.4]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder115" geometry={nodes.Cylinder115.geometry} material={nodes.Cylinder115.material} position={[0, -0.25, 0]} rotation={[1.57, 0, -2.15]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder114" geometry={nodes.Cylinder114.geometry} material={nodes.Cylinder114.material} position={[0, -0.25, 0]} rotation={[1.57, 0, -1.9]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder113" geometry={nodes.Cylinder113.geometry} material={nodes.Cylinder113.material} position={[0, -0.25, 0]} rotation={[1.57, 0, -1.66]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder112" geometry={nodes.Cylinder112.geometry} material={nodes.Cylinder112.material} position={[0, -0.25, 0]} rotation={[1.57, 0, -1.41]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder111" geometry={nodes.Cylinder111.geometry} material={nodes.Cylinder111.material} position={[0, -0.25, 0]} rotation={[1.57, 0, -1.16]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder110" geometry={nodes.Cylinder110.geometry} material={nodes.Cylinder110.material} position={[0, -0.25, 0]} rotation={[1.57, 0, -0.91]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder109" geometry={nodes.Cylinder109.geometry} material={nodes.Cylinder109.material} position={[0, -0.25, 0]} rotation={[1.57, 0, -0.66]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder108" geometry={nodes.Cylinder108.geometry} material={nodes.Cylinder108.material} position={[0, -0.25, 0]} rotation={[1.57, 0, -0.41]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder107" geometry={nodes.Cylinder107.geometry} material={nodes.Cylinder107.material} position={[0, -0.25, 0]} rotation={[Math.PI / 2, 0, -0.17]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder106" geometry={nodes.Cylinder106.geometry} material={nodes.Cylinder106.material} position={[0, -0.25, 0]} rotation={[Math.PI / 2, 0, 0.08]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder105" geometry={nodes.Cylinder105.geometry} material={nodes.Cylinder105.material} position={[0, -0.25, 0]} rotation={[1.57, 0, 0.33]} scale={[0.75, 0.75, 0.73]} />
            <mesh name="Cylinder104" geometry={nodes.Cylinder104.geometry} material={nodes.Cylinder104.material} position={[0, -0.07, 0]} rotation={[1.57, 0, 0.32]} scale={0.74} />
            <mesh name="Cylinder102" geometry={nodes.Cylinder102.geometry} material={nodes.Cylinder102.material} position={[0, -0.07, 0]} rotation={[1.57, 0, 0.82]} scale={0.74} />
            <mesh name="Cylinder101" geometry={nodes.Cylinder101.geometry} material={nodes.Cylinder101.material} position={[0, -0.07, 0]} rotation={[1.57, 0, 1.07]} scale={0.74} />
            <mesh name="Cylinder100" geometry={nodes.Cylinder100.geometry} material={nodes.Cylinder100.material} position={[0, -0.07, 0]} rotation={[1.57, 0, 1.32]} scale={0.74} />
            <mesh name="Cylinder099" geometry={nodes.Cylinder099.geometry} material={nodes.Cylinder099.material} position={[0, -0.07, 0]} rotation={[Math.PI / 2, 0, 1.56]} scale={0.74} />
            <mesh name="Cylinder098" geometry={nodes.Cylinder098.geometry} material={nodes.Cylinder098.material} position={[0, -0.07, 0]} rotation={[1.57, 0, 1.81]} scale={0.74} />
            <mesh name="Cylinder097" geometry={nodes.Cylinder097.geometry} material={nodes.Cylinder097.material} position={[0, -0.07, 0]} rotation={[1.57, 0, 2.06]} scale={0.74} />
            <mesh name="Cylinder096" geometry={nodes.Cylinder096.geometry} material={nodes.Cylinder096.material} position={[0, -0.07, 0]} rotation={[1.57, 0, 2.31]} scale={0.74} />
            <mesh name="Cylinder095" geometry={nodes.Cylinder095.geometry} material={nodes.Cylinder095.material} position={[0, -0.07, 0]} rotation={[1.57, 0, 2.56]} scale={0.74} />
            <mesh name="Cylinder094" geometry={nodes.Cylinder094.geometry} material={nodes.Cylinder094.material} position={[0, -0.07, 0]} rotation={[1.57, 0, 2.81]} scale={0.74} />
            <mesh name="Cylinder093" geometry={nodes.Cylinder093.geometry} material={nodes.Cylinder093.material} position={[0, -0.07, 0]} rotation={[Math.PI / 2, 0, 3.05]} scale={0.74} />
            <mesh name="Cylinder092" geometry={nodes.Cylinder092.geometry} material={nodes.Cylinder092.material} position={[0, -0.07, 0]} rotation={[Math.PI / 2, 0, -2.98]} scale={0.74} />
            <mesh name="Cylinder091" geometry={nodes.Cylinder091.geometry} material={nodes.Cylinder091.material} position={[0, -0.07, 0]} rotation={[1.57, 0, -2.73]} scale={0.74} />
            <mesh name="Cylinder090" geometry={nodes.Cylinder090.geometry} material={nodes.Cylinder090.material} position={[0, -0.07, 0]} rotation={[1.57, 0, -2.48]} scale={0.74} />
            <mesh name="Cylinder089" geometry={nodes.Cylinder089.geometry} material={nodes.Cylinder089.material} position={[0, -0.07, 0]} rotation={[1.57, 0, -2.24]} scale={0.74} />
            <mesh name="Cylinder088" geometry={nodes.Cylinder088.geometry} material={nodes.Cylinder088.material} position={[0, -0.07, 0]} rotation={[1.57, 0, -1.99]} scale={0.74} />
            <mesh name="Cylinder087" geometry={nodes.Cylinder087.geometry} material={nodes.Cylinder087.material} position={[0, -0.07, 0]} rotation={[1.57, 0, -1.74]} scale={0.74} />
            <mesh name="Cylinder086" geometry={nodes.Cylinder086.geometry} material={nodes.Cylinder086.material} position={[0, -0.07, 0]} rotation={[1.57, 0, -1.49]} scale={0.74} />
            <mesh name="Cylinder085" geometry={nodes.Cylinder085.geometry} material={nodes.Cylinder085.material} position={[0, -0.07, 0]} rotation={[1.57, 0, -1.24]} scale={0.74} />
            <mesh name="Cylinder084" geometry={nodes.Cylinder084.geometry} material={nodes.Cylinder084.material} position={[0, -0.07, 0]} rotation={[1.57, 0, -0.99]} scale={0.74} />
            <mesh name="Cylinder083" geometry={nodes.Cylinder083.geometry} material={nodes.Cylinder083.material} position={[0, -0.07, 0]} rotation={[1.57, 0, -0.75]} scale={0.74} />
            <mesh name="Cylinder082" geometry={nodes.Cylinder082.geometry} material={nodes.Cylinder082.material} position={[0, -0.07, 0]} rotation={[1.57, 0, -0.5]} scale={0.74} />
            <mesh name="Cylinder081" geometry={nodes.Cylinder081.geometry} material={nodes.Cylinder081.material} position={[0, -0.07, 0]} rotation={[1.57, 0, -0.25]} scale={0.74} />
            <mesh name="Cylinder080" geometry={nodes.Cylinder080.geometry} material={nodes.Cylinder080.material} position={[0, -0.07, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.74} />
            <mesh name="Cylinder103" geometry={nodes.Cylinder103.geometry} material={nodes.Cylinder103.material} position={[0, -0.07, 0]} rotation={[1.57, 0, 0.57]} scale={0.74} />
            <mesh name="Cylinder198" geometry={nodes.Cylinder198.geometry} material={nodes.Cylinder198.material} position={[0, -0.28, 0]} scale={1.13} />
            <mesh name="Cylinder239" geometry={nodes.Cylinder239.geometry} material={nodes.Cylinder239.material} position={[0, -0.02, 0]} scale={1.13} />
            <mesh name="Cylinder079" geometry={nodes.Cylinder079.geometry} material={nodes.Cylinder079.material} position={[0, -0.2, 0]} rotation={[1.57, 0, 0.23]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder078" geometry={nodes.Cylinder078.geometry} material={nodes.Cylinder078.material} position={[0, -0.2, 0]} rotation={[1.57, 0, 0.47]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder077" geometry={nodes.Cylinder077.geometry} material={nodes.Cylinder077.material} position={[0, -0.2, 0]} rotation={[1.57, 0, 0.71]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder076" geometry={nodes.Cylinder076.geometry} material={nodes.Cylinder076.material} position={[0, -0.2, 0]} rotation={[1.57, 0, 0.95]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder075" geometry={nodes.Cylinder075.geometry} material={nodes.Cylinder075.material} position={[0, -0.2, 0]} rotation={[1.57, 0, 1.2]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder074" geometry={nodes.Cylinder074.geometry} material={nodes.Cylinder074.material} position={[0, -0.2, 0]} rotation={[1.57, 0, 1.44]} scale={[0.84, 0.84, 0.86]} />
            <mesh name="Cylinder073" geometry={nodes.Cylinder073.geometry} material={nodes.Cylinder073.material} position={[0, -0.2, 0]} rotation={[1.57, 0, 1.68]} scale={[0.84, 0.84, 0.86]} />
            <mesh name="Cylinder072" geometry={nodes.Cylinder072.geometry} material={nodes.Cylinder072.material} position={[0, -0.2, 0]} rotation={[1.57, 0, 1.92]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder071" geometry={nodes.Cylinder071.geometry} material={nodes.Cylinder071.material} position={[0, -0.2, 0]} rotation={[1.57, 0, 2.16]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder070" geometry={nodes.Cylinder070.geometry} material={nodes.Cylinder070.material} position={[0, -0.2, 0]} rotation={[1.57, 0, 2.41]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder069" geometry={nodes.Cylinder069.geometry} material={nodes.Cylinder069.material} position={[0, -0.2, 0]} rotation={[1.57, 0, 2.65]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder068" geometry={nodes.Cylinder068.geometry} material={nodes.Cylinder068.material} position={[0, -0.2, 0]} rotation={[1.57, 0, 2.89]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder067" geometry={nodes.Cylinder067.geometry} material={nodes.Cylinder067.material} position={[0, -0.2, 0]} rotation={[Math.PI / 2, 0, 3.13]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder066" geometry={nodes.Cylinder066.geometry} material={nodes.Cylinder066.material} position={[0, -0.2, 0]} rotation={[1.57, 0, -2.91]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder065" geometry={nodes.Cylinder065.geometry} material={nodes.Cylinder065.material} position={[0, -0.2, 0]} rotation={[1.57, 0, -2.67]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder064" geometry={nodes.Cylinder064.geometry} material={nodes.Cylinder064.material} position={[0, -0.2, 0]} rotation={[1.57, 0, -2.42]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder063" geometry={nodes.Cylinder063.geometry} material={nodes.Cylinder063.material} position={[0, -0.2, 0]} rotation={[1.57, 0, -2.18]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder062" geometry={nodes.Cylinder062.geometry} material={nodes.Cylinder062.material} position={[0, -0.2, 0]} rotation={[1.57, 0, -1.94]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder061" geometry={nodes.Cylinder061.geometry} material={nodes.Cylinder061.material} position={[0, -0.2, 0]} rotation={[1.57, 0, -1.7]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder060" geometry={nodes.Cylinder060.geometry} material={nodes.Cylinder060.material} position={[0, -0.2, 0]} rotation={[1.57, 0, -1.45]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder059" geometry={nodes.Cylinder059.geometry} material={nodes.Cylinder059.material} position={[0, -0.2, 0]} rotation={[1.57, 0, -1.21]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder058" geometry={nodes.Cylinder058.geometry} material={nodes.Cylinder058.material} position={[0, -0.2, 0]} rotation={[1.57, 0, -0.97]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder057" geometry={nodes.Cylinder057.geometry} material={nodes.Cylinder057.material} position={[0, -0.2, 0]} rotation={[1.57, 0, -0.73]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder056" geometry={nodes.Cylinder056.geometry} material={nodes.Cylinder056.material} position={[0, -0.2, 0]} rotation={[1.57, 0, -0.48]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder055" geometry={nodes.Cylinder055.geometry} material={nodes.Cylinder055.material} position={[0, -0.2, 0]} rotation={[1.57, 0, -0.24]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder054" geometry={nodes.Cylinder054.geometry} material={nodes.Cylinder054.material} position={[0, -0.2, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder053" geometry={nodes.Cylinder053.geometry} material={nodes.Cylinder053.material} position={[0, -0.12, 0]} rotation={[1.57, 0, 0.13]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder052" geometry={nodes.Cylinder052.geometry} material={nodes.Cylinder052.material} position={[0, -0.12, 0]} rotation={[1.57, 0, 0.37]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder051" geometry={nodes.Cylinder051.geometry} material={nodes.Cylinder051.material} position={[0, -0.12, 0]} rotation={[1.57, 0, 0.62]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder050" geometry={nodes.Cylinder050.geometry} material={nodes.Cylinder050.material} position={[0, -0.12, 0]} rotation={[1.57, 0, 0.86]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder049" geometry={nodes.Cylinder049.geometry} material={nodes.Cylinder049.material} position={[0, -0.12, 0]} rotation={[1.57, 0, 1.1]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder048" geometry={nodes.Cylinder048.geometry} material={nodes.Cylinder048.material} position={[0, -0.12, 0]} rotation={[1.57, 0, 1.34]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder047" geometry={nodes.Cylinder047.geometry} material={nodes.Cylinder047.material} position={[0, -0.12, 0]} rotation={[1.57, 0, 1.59]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder046" geometry={nodes.Cylinder046.geometry} material={nodes.Cylinder046.material} position={[0, -0.12, 0]} rotation={[1.57, 0, 1.83]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder045" geometry={nodes.Cylinder045.geometry} material={nodes.Cylinder045.material} position={[0, -0.12, 0]} rotation={[1.57, 0, 2.07]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder044" geometry={nodes.Cylinder044.geometry} material={nodes.Cylinder044.material} position={[0, -0.12, 0]} rotation={[1.57, 0, 2.31]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder043" geometry={nodes.Cylinder043.geometry} material={nodes.Cylinder043.material} position={[0, -0.12, 0]} rotation={[1.57, 0, 2.55]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder042" geometry={nodes.Cylinder042.geometry} material={nodes.Cylinder042.material} position={[0, -0.12, 0]} rotation={[1.57, 0, 2.8]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder041" geometry={nodes.Cylinder041.geometry} material={nodes.Cylinder041.material} position={[0, -0.12, 0]} rotation={[1.57, 0, 3.04]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder040" geometry={nodes.Cylinder040.geometry} material={nodes.Cylinder040.material} position={[0, -0.12, 0]} rotation={[1.57, 0, -3]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder039" geometry={nodes.Cylinder039.geometry} material={nodes.Cylinder039.material} position={[0, -0.12, 0]} rotation={[1.57, 0, -2.76]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder038" geometry={nodes.Cylinder038.geometry} material={nodes.Cylinder038.material} position={[0, -0.12, 0]} rotation={[1.57, 0, -2.52]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder037" geometry={nodes.Cylinder037.geometry} material={nodes.Cylinder037.material} position={[0, -0.12, 0]} rotation={[1.57, 0, -2.27]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder036" geometry={nodes.Cylinder036.geometry} material={nodes.Cylinder036.material} position={[0, -0.12, 0]} rotation={[1.57, 0, -2.03]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder035" geometry={nodes.Cylinder035.geometry} material={nodes.Cylinder035.material} position={[0, -0.12, 0]} rotation={[1.57, 0, -1.79]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder034" geometry={nodes.Cylinder034.geometry} material={nodes.Cylinder034.material} position={[0, -0.12, 0]} rotation={[1.57, 0, -1.55]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder033" geometry={nodes.Cylinder033.geometry} material={nodes.Cylinder033.material} position={[0, -0.12, 0]} rotation={[1.57, 0, -1.31]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder032" geometry={nodes.Cylinder032.geometry} material={nodes.Cylinder032.material} position={[0, -0.12, 0]} rotation={[1.57, 0, -1.06]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder031" geometry={nodes.Cylinder031.geometry} material={nodes.Cylinder031.material} position={[0, -0.12, 0]} rotation={[1.57, 0, -0.82]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder030" geometry={nodes.Cylinder030.geometry} material={nodes.Cylinder030.material} position={[0, -0.12, 0]} rotation={[1.57, 0, -0.58]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder029" geometry={nodes.Cylinder029.geometry} material={nodes.Cylinder029.material} position={[0, -0.12, 0]} rotation={[1.57, 0, -0.34]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder028" geometry={nodes.Cylinder028.geometry} material={nodes.Cylinder028.material} position={[0, -0.12, 0]} rotation={[1.57, 0, -0.09]} scale={[0.89, 0.89, 0.92]} />
            <mesh name="Cylinder007" geometry={nodes.Cylinder007.geometry} material={nodes.Cylinder007.material} position={[0, -0.16, 0]} rotation={[0, 0.16, 0]} scale={[1.32, 0.63, 1.32]} />
            <mesh name="Cylinder192" geometry={nodes.Cylinder192.geometry} material={nodes.Cylinder192.material} position={[0, -0.12, 0]} rotation={[0, 0.16, 0]} scale={[1.18, 0.43, 1.18]} />
            <mesh name="Cylinder201" geometry={nodes.Cylinder201.geometry} material={nodes.Cylinder201.material} position={[0, -0.79, 0]} />
            <mesh name="Cylinder203" geometry={nodes.Cylinder203.geometry} material={nodes.Cylinder203.material} position={[0, 0.57, 0]} scale={1.45} />
            <mesh name="Cylinder202" geometry={nodes.Cylinder202.geometry} material={nodes.Cylinder202.material} position={[0, 0.58, 0]} scale={1.45} />
          </group>
          <group name="stolik_004" position={[-1.83, 0.36, -1.56]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh name="Box025" geometry={nodes.Box025.geometry} material={nodes.Box025.material} position={[0, -0.36, 0]} rotation={[0, Math.PI / 2, 0]} />
            <mesh name="Box026" geometry={nodes.Box026.geometry} material={nodes.Box026.material} position={[-0.22, 0.27, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            <mesh name="Box027" geometry={nodes.Box027.geometry} material={nodes.Box027.material} position={[-0.22, 0.27, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.59} />
            <mesh name="Box028" geometry={nodes.Box028.geometry} material={nodes.Box028.material} position={[-0.22, -0.1, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.59} />
            <mesh name="Line1789534626" geometry={nodes.Line1789534626.geometry} material={nodes.Line1789534626.material} position={[-0.22, 0.27, -0.02]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.59, -0.59, 0.59]} />
            <mesh name="Line1789534627" geometry={nodes.Line1789534627.geometry} material={nodes.Line1789534627.material} position={[-0.22, -0.11, -0.02]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.59, -0.59, 0.59]} />
            <mesh name="Object5691" geometry={nodes.Object5691.geometry} material={nodes.Object5691.material} rotation={[0, 1.57, 0]} />
          </group>
          <group name="Group2069533103" position={[1.54, 0.6, 0.14]} rotation={[-Math.PI, 0, -Math.PI]}>
            <group name="Group2069533102" position={[-0.02, 0.34, -0.51]} scale={0.48}>
              <mesh name="Plane2125" geometry={nodes.Plane2125.geometry} material={nodes.Plane2125.material} position={[-0.06, 0.12, 0.03]} rotation={[-3.14, -1.15, 2.56]} scale={1.06} />
              <mesh name="Object57" geometry={nodes.Object57.geometry} material={nodes.Object57.material} position={[0.25, 0.19, -0.04]} rotation={[2.31, 0.01, 1.41]} scale={[0.21, 0.21, 0.13]} />
              <mesh name="Object145" geometry={nodes.Object145.geometry} material={nodes.Object145.material} position={[0.17, 0.34, 0.05]} rotation={[-0.15, -0.32, -0.46]} scale={1.06} />
              <mesh name="Plane2121" geometry={nodes.Plane2121.geometry} material={nodes.Plane2121.material} position={[0.11, 0.09, 0.06]} rotation={[0.22, 0.06, -0.76]} scale={1.06} />
              <mesh name="Object47" geometry={nodes.Object47.geometry} material={nodes.Object47.material} position={[-0.16, 0.33, 0]} rotation={[0.74, -0.51, -0.43]} scale={0.3} />
              <mesh name="Plane01" geometry={nodes.Plane01.geometry} material={nodes.Plane01.material} position={[0.04, 0.07, 0.01]} rotation={[0, -Math.PI / 9, -0.44]} scale={0.46} />
              <mesh name="Object146" geometry={nodes.Object146.geometry} material={nodes.Object146.material} position={[0.16, 0.2, 0.07]} rotation={[Math.PI / 2, 0, -2.09]} scale={0.34} />
              <mesh name="Plane144" geometry={nodes.Plane144.geometry} material={nodes.Plane144.material} position={[0, 0.09, 0.05]} rotation={[1.44, -0.64, 0.8]} scale={0.46} />
              <mesh name="Line09" geometry={nodes.Line09.geometry} material={nodes.Line09.material} position={[0.02, 0.03, 0.09]} rotation={[2.06, 0.08, 1.01]} scale={0.15} />
              <mesh name="Plane146" geometry={nodes.Plane146.geometry} material={nodes.Plane146.material} position={[0.01, 0.08, -0.06]} rotation={[2.07, -1.06, 2.36]} scale={0.46} />
              <mesh name="Plane147_(1)" geometry={nodes['Plane147_(1)'].geometry} material={nodes['Plane147_(1)'].material} position={[0.07, 0.19, -0.03]} rotation={[-0.3, -0.92, -0.26]} scale={0.28} />
              <mesh name="Plane148_(1)" geometry={nodes['Plane148_(1)'].geometry} material={nodes['Plane148_(1)'].material} position={[0.02, 0.12, -0.03]} rotation={[0, -Math.PI / 9, 0.26]} scale={0.35} />
              <mesh name="Plane149" geometry={nodes.Plane149.geometry} material={nodes.Plane149.material} position={[0.04, 0.17, -0.1]} rotation={[1.9, 0.01, -2.67]} scale={0.35} />
              <mesh name="Object22" geometry={nodes.Object22.geometry} material={nodes.Object22.material} position={[-0.19, 0.26, 0.17]} rotation={[0.9, -0.22, -0.6]} scale={0.34} />
              <mesh name="Plane151" geometry={nodes.Plane151.geometry} material={nodes.Plane151.material} position={[0.02, 0.09, 0.1]} rotation={[3.1, -0.51, -2.64]} scale={0.48} />
              <mesh name="Object23" geometry={nodes.Object23.geometry} material={nodes.Object23.material} position={[0.08, 0.11, 0.31]} rotation={[1.8, 0.88, -1.95]} scale={0.3} />
              <mesh name="Object32" geometry={nodes.Object32.geometry} material={nodes.Object32.material} position={[-0.17, 0.22, -0.19]} rotation={[1.2, -0.69, 0.51]} scale={0.3} />
              <mesh name="Plane186" geometry={nodes.Plane186.geometry} material={nodes.Plane186.material} position={[-0.01, 0.14, -0.06]} rotation={[0.51, 1.02, 0.34]} scale={0.48} />
              <mesh name="Plane221" geometry={nodes.Plane221.geometry} material={nodes.Plane221.material} position={[0.12, 0.16, 0.01]} rotation={[0.07, -1.05, 0.58]} scale={0.48} />
              <mesh name="Object41" geometry={nodes.Object41.geometry} material={nodes.Object41.material} position={[0.33, 0.18, -0.06]} rotation={[2.47, -0.15, 2.95]} scale={0.3} />
              <mesh name="Plane222" geometry={nodes.Plane222.geometry} material={nodes.Plane222.material} position={[0.08, 0.09, 0.02]} rotation={[2.69, -0.6, -2.39]} scale={0.55} />
              <mesh name="Object42" geometry={nodes.Object42.geometry} material={nodes.Object42.material} position={[0.19, 0.21, 0.21]} rotation={[1.29, 0.65, -2]} scale={0.34} />
              <mesh name="Plane223" geometry={nodes.Plane223.geometry} material={nodes.Plane223.material} position={[0.07, 0.11, 0.03]} rotation={[0.93, 0.1, -0.69]} scale={0.46} />
              <mesh name="Plane241" geometry={nodes.Plane241.geometry} material={nodes.Plane241.material} position={[0.01, 0.19, -0.01]} rotation={[1.97, 0.96, -1.19]} scale={0.48} />
              <mesh name="Object53" geometry={nodes.Object53.geometry} material={nodes.Object53.material} position={[-0.08, 0.3, 0.01]} rotation={[1.63, -0.87, -1.48]} scale={[0.21, 0.21, 0.13]} />
              <mesh name="Object52" geometry={nodes.Object52.geometry} material={nodes.Object52.material} position={[-0.11, 0.21, -0.13]} rotation={[2.04, -0.4, -0.62]} scale={[0.21, 0.21, 0.13]} />
              <mesh name="Line13" geometry={nodes.Line13.geometry} material={nodes.Line13.material} position={[-0.01, 0.14, -0.01]} rotation={[1.54, -0.14, 2.92]} scale={0.15} />
              <mesh name="Line08" geometry={nodes.Line08.geometry} material={nodes.Line08.material} position={[0.04, 0.13, -0.07]} rotation={[1.2, 0.07, -1.05]} scale={0.13} />
              <mesh name="Line10" geometry={nodes.Line10.geometry} material={nodes.Line10.material} position={[-0.01, 0.08, -0.07]} rotation={[1.45, 0.11, -2.61]} scale={0.15} />
              <mesh name="Line12" geometry={nodes.Line12.geometry} material={nodes.Line12.material} position={[0.11, 0.1, 0]} rotation={[1.66, -0.49, -0.52]} scale={0.15} />
              <mesh name="Line07" geometry={nodes.Line07.geometry} material={nodes.Line07.material} position={[0.07, 0.1, -0.02]} rotation={[1.61, 0.09, -0.7]} scale={0.18} />
              <mesh name="Line14" geometry={nodes.Line14.geometry} material={nodes.Line14.material} position={[0.03, 0.12, -0.04]} rotation={[1.3, 0.27, -1.64]} scale={0.13} />
              <mesh name="Line04" geometry={nodes.Line04.geometry} material={nodes.Line04.material} position={[-0.02, 0.07, 0.06]} rotation={[1.73, -0.06, 2.61]} scale={0.18} />
              <mesh name="Line11" geometry={nodes.Line11.geometry} material={nodes.Line11.material} position={[0.07, 0.03, 0.04]} rotation={[1.51, -0.16, 1.03]} scale={0.18} />
              <mesh name="Object51" geometry={nodes.Object51.geometry} material={nodes.Object51.material} position={[-0.01, 0.21, -0.15]} rotation={[-2.13, 1.11, -2.11]} scale={[0.21, 0.21, 0.13]} />
              <mesh name="Plane145" geometry={nodes.Plane145.geometry} material={nodes.Plane145.material} position={[0.01, 0.13, 0.06]} rotation={[2.58, 0.83, -1.68]} scale={0.55} />
              <mesh name="Cylinder01" geometry={nodes.Cylinder01.geometry} material={nodes.Cylinder01.material} position={[0.03, -0.37, -0.01]} rotation={[0, 1.22, 0]} scale={2.19} />
              <mesh name="Object54" geometry={nodes.Object54.geometry} material={nodes.Object54.material} position={[-0.1, 0.22, 0.14]} rotation={[1.18, -0.66, -2.18]} scale={[0.21, 0.21, 0.13]} />
              <mesh name="Object55" geometry={nodes.Object55.geometry} material={nodes.Object55.material} position={[0.06, 0.12, 0.22]} rotation={[1.42, 0.58, 2.91]} scale={[0.21, 0.21, 0.13]} />
              <mesh name="Object56" geometry={nodes.Object56.geometry} material={nodes.Object56.material} position={[0.16, 0.19, 0.13]} rotation={[1.28, 0.8, 2.64]} scale={[0.21, 0.21, 0.13]} />
              <mesh name="Object58" geometry={nodes.Object58.geometry} material={nodes.Object58.material} position={[0.15, 0.28, -0.08]} rotation={[2.71, 0.05, 0.73]} scale={[0.21, 0.21, 0.13]} />
              <mesh name="Object59" geometry={nodes.Object59.geometry} material={nodes.Object59.material} position={[0.07, 0.22, -0.18]} rotation={[-1.36, 0.02, 2.79]} scale={[0.21, 0.21, 0.13]} />
              <mesh name="Plane2122" geometry={nodes.Plane2122.geometry} material={nodes.Plane2122.material} position={[-0.02, 0.19, 0]} rotation={[0.08, 0.21, 0.2]} scale={1.06} />
              <mesh name="Object147" geometry={nodes.Object147.geometry} material={nodes.Object147.material} position={[-0.19, 0.39, -0.01]} rotation={[0.18, -0.3, 0.55]} scale={1.06} />
              <mesh name="Object148" geometry={nodes.Object148.geometry} material={nodes.Object148.material} position={[0.06, 0.35, -0.03]} rotation={[-0.19, 0.67, 0.06]} scale={1.06} />
              <mesh name="Plane2123" geometry={nodes.Plane2123.geometry} material={nodes.Plane2123.material} position={[0.11, 0.1, -0.02]} rotation={[0.17, 1.15, -0.59]} scale={1.06} />
              <mesh name="Object149" geometry={nodes.Object149.geometry} material={nodes.Object149.material} position={[0.07, 0.1, -0.22]} rotation={[-1.53, 0.08, 2.79]} scale={[0.21, 0.21, 0.13]} />
              <mesh name="Line15" geometry={nodes.Line15.geometry} material={nodes.Line15.material} position={[0.04, 0.03, -0.1]} rotation={[1.03, 0.02, -1.08]} scale={0.13} />
              <mesh name="Object025" geometry={nodes.Object025.geometry} material={nodes.Object025.material} position={[0.22, 0.02, -0.04]} rotation={[1.19, 0.02, -1.54]} scale={0.15} />
              <mesh name="Line018" geometry={nodes.Line018.geometry} material={nodes.Line018.material} position={[0.18, 0.03, -0.04]} rotation={[2.63, -1.48, 0.8]} scale={1.38} />
              <mesh name="Line19" geometry={nodes.Line19.geometry} material={nodes.Line19.material} position={[0.13, 0.06, -0.02]} rotation={[2.05, -1.2, -1.26]} scale={1.38} />
              <mesh name="Object150" geometry={nodes.Object150.geometry} material={nodes.Object150.material} position={[0.17, 0.08, -0.03]} rotation={[2.71, 0.31, -1.85]} scale={0.15} />
              <mesh name="Object022" geometry={nodes.Object022.geometry} material={nodes.Object022.material} position={[0.23, 0.19, 0.05]} rotation={[0.08, 0.83, -2.47]} scale={0.32} />
              <mesh name="Plane2124" geometry={nodes.Plane2124.geometry} material={nodes.Plane2124.material} position={[-0.07, 0.13, -0.06]} rotation={[3.1, -0.06, 2.38]} scale={1.06} />
              <mesh name="Object151" geometry={nodes.Object151.geometry} material={nodes.Object151.material} position={[0.15, 0.08, 0.15]} rotation={[0.84, 0.3, -2.43]} scale={0.32} />
              <mesh name="Line20" geometry={nodes.Line20.geometry} material={nodes.Line20.material} position={[0.17, 0.06, 0.17]} rotation={[-0.46, -0.73, -1.2]} scale={3.01} />
              <mesh name="Line21" geometry={nodes.Line21.geometry} material={nodes.Line21.material} position={[0.1, 0.23, 0.1]} rotation={[1.42, 0.52, 1.31]} scale={1.38} />
              <mesh name="Object152" geometry={nodes.Object152.geometry} material={nodes.Object152.material} position={[0.08, 0.26, 0.11]} rotation={[0.03, -0.33, 0.49]} scale={0.15} />
              <mesh name="Line22" geometry={nodes.Line22.geometry} material={nodes.Line22.material} position={[0.21, 0.2, -0.12]} rotation={[1.48, -0.41, -0.61]} scale={1.38} />
              <mesh name="Object153" geometry={nodes.Object153.geometry} material={nodes.Object153.material} position={[0.23, 0.23, -0.13]} rotation={[1.4, 1.17, -1.56]} scale={0.15} />
              <mesh name="Object154" geometry={nodes.Object154.geometry} material={nodes.Object154.material} position={[-0.13, 0.37, 0]} rotation={[-2.81, 0.32, 2.68]} scale={1.06} />
              <mesh name="Object155" geometry={nodes.Object155.geometry} material={nodes.Object155.material} position={[-0.02, 0.37, 0.08]} rotation={[-2.78, -0.67, -3.08]} scale={1.06} />
              <mesh name="Object014" geometry={nodes.Object014.geometry} material={nodes.Object014.material} position={[-0.09, -0.52, 0.07]} rotation={[Math.PI, -1.13, Math.PI]} scale={1.52} />
              <mesh name="Line016" geometry={nodes.Line016.geometry} material={nodes.Line016.material} position={[0.25, 0.19, 0.06]} rotation={[-0.92, -0.48, -0.63]} scale={3.01} />
            </group>
            <group name="BarbaraBerry_LadysDesk" position={[0, -0.21, 0]} rotation={[Math.PI, 0, Math.PI]}>
              <group name="Group08" position={[-0.23, 0.28, -0.54]}>
                <mesh name="Box09" geometry={nodes.Box09.geometry} material={nodes.Box09.material} position={[0, 0.02, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
                <mesh name="Ellipse08" geometry={nodes.Ellipse08.geometry} material={nodes.Ellipse08.material} position={[-0.01, 0, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
              </group>
              <group name="Group09" position={[-0.23, 0.15, -0.54]}>
                <mesh name="Box10" geometry={nodes.Box10.geometry} material={nodes.Box10.material} position={[0, 0.02, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
                <mesh name="Ellipse09" geometry={nodes.Ellipse09.geometry} material={nodes.Ellipse09.material} position={[-0.01, 0, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
              </group>
              <group name="Group10" position={[-0.23, 0.03, -0.54]}>
                <mesh name="Ellipse10" geometry={nodes.Ellipse10.geometry} material={nodes.Ellipse10.material} position={[-0.01, 0, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
                <mesh name="Box11" geometry={nodes.Box11.geometry} material={nodes.Box11.material} position={[0, 0.02, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
              </group>
              <group name="Group11" position={[-0.23, 0.03, 0.54]}>
                <mesh name="Ellipse11" geometry={nodes.Ellipse11.geometry} material={nodes.Ellipse11.material} position={[-0.01, 0, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
                <mesh name="Box12" geometry={nodes.Box12.geometry} material={nodes.Box12.material} position={[0, 0.02, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
              </group>
              <group name="Group12" position={[-0.23, 0.15, 0.54]}>
                <mesh name="Ellipse12" geometry={nodes.Ellipse12.geometry} material={nodes.Ellipse12.material} position={[-0.01, 0, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
                <mesh name="Box13" geometry={nodes.Box13.geometry} material={nodes.Box13.material} position={[0, 0.02, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
              </group>
              <group name="Group13" position={[-0.23, 0.28, 0.54]}>
                <mesh name="Ellipse13" geometry={nodes.Ellipse13.geometry} material={nodes.Ellipse13.material} position={[-0.01, 0, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
                <mesh name="Box14" geometry={nodes.Box14.geometry} material={nodes.Box14.material} position={[0, 0.02, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
              </group>
              <group name="Group14" position={[-0.18, 0.28, 0]}>
                <mesh name="Ellipse14" geometry={nodes.Ellipse14.geometry} material={nodes.Ellipse14.material} position={[-0.01, 0, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
                <mesh name="Box15" geometry={nodes.Box15.geometry} material={nodes.Box15.material} position={[0, 0.02, -0.01]} rotation={[Math.PI / 2, 0, 0]} />
              </group>
              <mesh name="Rectangle04" geometry={nodes.Rectangle04.geometry} material={nodes.Rectangle04.material} position={[0.03, 0.34, 0.29]} rotation={[-Math.PI, 0, 0]} scale={[1, -1, 1]} />
              <mesh name="Rectangle05" geometry={nodes.Rectangle05.geometry} material={nodes.Rectangle05.material} position={[0.03, 0.35, 0.29]} rotation={[-Math.PI, 0, 0]} scale={[1, -1, 1]} />
              <mesh name="Rectangle06" geometry={nodes.Rectangle06.geometry} material={nodes.Rectangle06.material} position={[0.03, -0.04, 0.29]} rotation={[-Math.PI, 0, 0]} scale={[1, -1, 1]} />
              <mesh name="Star03" geometry={nodes.Star03.geometry} material={nodes.Star03.material} position={[-0.2, 0.15, -0.54]} scale={[0.52, 1, 1.14]} />
              <mesh name="Star04" geometry={nodes.Star04.geometry} material={nodes.Star04.material} position={[-0.2, 0.28, -0.02]} scale={[0.52, 0.42, 1.14]} />
              <mesh name="Ob01" geometry={nodes.Ob01.geometry} material={nodes.Ob01.material} position={[0.02, -0.03, 0.29]} rotation={[-Math.PI, 0, 0]} scale={[1, -1, 1]} />
              <mesh name="Cone10" geometry={nodes.Cone10.geometry} material={nodes.Cone10.material} position={[-0.16, -0.37, -0.79]} />
              <mesh name="Cone11" geometry={nodes.Cone11.geometry} material={nodes.Cone11.material} position={[-0.16, -0.37, -0.28]} />
              <mesh name="Cone12" geometry={nodes.Cone12.geometry} material={nodes.Cone12.material} position={[0.22, -0.37, -0.79]} />
              <mesh name="Cone13" geometry={nodes.Cone13.geometry} material={nodes.Cone13.material} position={[0.22, -0.37, -0.28]} />
              <mesh name="Cone14" geometry={nodes.Cone14.geometry} material={nodes.Cone14.material} position={[-0.16, -0.37, 0.79]} />
              <mesh name="Cone15" geometry={nodes.Cone15.geometry} material={nodes.Cone15.material} position={[0.22, -0.37, 0.28]} />
              <mesh name="Cone16" geometry={nodes.Cone16.geometry} material={nodes.Cone16.material} position={[0.22, -0.37, 0.79]} />
              <mesh name="Cone17" geometry={nodes.Cone17.geometry} material={nodes.Cone17.material} position={[-0.16, -0.37, 0.28]} />
              <mesh name="Cone18" geometry={nodes.Cone18.geometry} material={nodes.Cone18.material} position={[-0.2, 0.14, 0]} scale={1.74} />
              <mesh name="Line03" geometry={nodes.Line03.geometry} material={nodes.Line03.material} position={[-0.2, 0.22, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
              <mesh name="Line04_(1)" geometry={nodes['Line04_(1)'].geometry} material={nodes['Line04_(1)'].material} position={[-0.2, 0.24, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
            </group>
            <group name="Group001" position={[-0.07, 0.37, 0.57]} rotation={[-Math.PI, 1.4, -Math.PI]} scale={1.81}>
              <mesh name="Torus002" geometry={nodes.Torus002.geometry} material={nodes.Torus002.material} position={[-0.01, -0.11, -0.01]} scale={12.03} />
              <mesh name="Line002_(1)" geometry={nodes['Line002_(1)'].geometry} material={nodes['Line002_(1)'].material} position={[-0.02, 0.06, -0.01]} rotation={[Math.PI / 2, 0, 0.7]} scale={[3.05, 4.38, 4.38]} />
              <mesh name="Object001_(1)" geometry={nodes['Object001_(1)'].geometry} material={nodes['Object001_(1)'].material} position={[0.01, 0.07, 0.01]} rotation={[1.38, 0.22, 0.72]} scale={[3.71, 3.71, 6.68]} />
              <mesh name="Line001_(1)" geometry={nodes['Line001_(1)'].geometry} material={nodes['Line001_(1)'].material} position={[0.03, 0.08, 0.02]} rotation={[1.38, 0.22, 0.72]} scale={3.71} />
              <mesh name="Line003" geometry={nodes.Line003.geometry} material={nodes.Line003.material} position={[-0.03, 0, -0.02]} rotation={[Math.PI / 2, 0, 0.7]} scale={1.23} />
            </group>
            <mesh name="decor_box_02" geometry={nodes.decor_box_02.geometry} material={nodes.decor_box_02.material} position={[-0.13, 0.16, -0.3]} rotation={[-Math.PI, 1.48, -Math.PI]} />
            <mesh name="decor_box" geometry={nodes.decor_box.geometry} material={nodes.decor_box.material} position={[-0.13, 0.16, -0.18]} rotation={[-Math.PI, 1.48, -Math.PI]} />
            <mesh name="05_Custom_design_books_18" geometry={nodes['05_Custom_design_books_18'].geometry} material={nodes['05_Custom_design_books_18'].material} position={[-0.1, 0.17, 0.16]} rotation={[Math.PI / 2, 1.57, 0]} />
            <mesh name="08_Taschen_Icons_25" geometry={nodes['08_Taschen_Icons_25'].geometry} material={nodes['08_Taschen_Icons_25'].material} position={[-0.11, 0.2, 0.17]} rotation={[Math.PI / 2, 1.57, 0]} />
          </group>
          <group name="Кровать" position={[-0.62, 0.47, -0.1]} rotation={[-Math.PI, 0, -Math.PI]}>
            <group name="daybed" position={[-1.14, -0.14, 0]}>
              <group name="Group021" position={[-0.01, 0, 0.05]} rotation={[0, -1.57, 0]} scale={[1.15, 1, 0.79]}>
                <mesh name="Plane1874_(1)" geometry={nodes['Plane1874_(1)'].geometry} material={nodes['Plane1874_(1)'].material} position={[0.59, 0.06, 0.27]} rotation={[Math.PI / 2, 0, 0]} />
                <mesh name="Line030" geometry={nodes.Line030.geometry} material={nodes.Line030.material} position={[0.58, -0.25, 0.24]} rotation={[Math.PI / 2, 0, 0]} />
              </group>
              <group name="armchair_pianca_dialogo" position={[0.04, 0.12, 0.2]} rotation={[-Math.PI, 0, 0]} scale={[1, -1, 1]}>
                <group name="plaid" position={[-0.03, -0.22, 0.55]}>
                  <mesh name="plaid1" geometry={nodes.plaid1.geometry} material={nodes.plaid1.material} position={[0.22, 1.02, -1.72]} rotation={[Math.PI / 2, 0, 0]} />
                  <mesh name="brushes1" geometry={nodes.brushes1.geometry} material={nodes.brushes1.material} position={[-0.59, 1.31, -1.72]} rotation={[Math.PI / 2, 0, 0]} />
                </group>
              </group>
            </group>
            <group name="FB_BD_MES_3" position={[0.27, 0.06, 0]} rotation={[0, -Math.PI / 2, 0]}>
              <mesh name="Buttons_mes_025" geometry={nodes.Buttons_mes_025.geometry} material={nodes.Buttons_mes_025.material} position={[-0.19, 0.11, -1.03]} rotation={[1.49, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_026" geometry={nodes.Buttons_mes_026.geometry} material={nodes.Buttons_mes_026.material} position={[0.38, 0.11, -1.03]} rotation={[1.49, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_027" geometry={nodes.Buttons_mes_027.geometry} material={nodes.Buttons_mes_027.material} position={[0.57, 0.11, -1.03]} rotation={[1.49, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_028" geometry={nodes.Buttons_mes_028.geometry} material={nodes.Buttons_mes_028.material} position={[0.76, 0.11, -1.03]} rotation={[1.49, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_029" geometry={nodes.Buttons_mes_029.geometry} material={nodes.Buttons_mes_029.material} position={[-0.38, 0.11, -1.03]} rotation={[1.49, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_030" geometry={nodes.Buttons_mes_030.geometry} material={nodes.Buttons_mes_030.material} position={[-0.57, 0.11, -1.03]} rotation={[1.49, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_031" geometry={nodes.Buttons_mes_031.geometry} material={nodes.Buttons_mes_031.material} position={[0, 0.11, -1.03]} rotation={[1.49, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_2" geometry={nodes.Buttons_mes_2.geometry} material={nodes.Buttons_mes_2.material} position={[0.85, 0.23, -1.05]} rotation={[1.5, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_003" geometry={nodes.Buttons_mes_003.geometry} material={nodes.Buttons_mes_003.material} position={[0.67, 0.23, -1.05]} rotation={[1.5, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_014" geometry={nodes.Buttons_mes_014.geometry} material={nodes.Buttons_mes_014.material} position={[-0.47, -0.02, -1.03]} rotation={[1.56, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_015" geometry={nodes.Buttons_mes_015.geometry} material={nodes.Buttons_mes_015.material} position={[-0.28, -0.02, -1.03]} rotation={[1.56, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_016" geometry={nodes.Buttons_mes_016.geometry} material={nodes.Buttons_mes_016.material} position={[-0.09, -0.02, -1.03]} rotation={[1.56, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_017" geometry={nodes.Buttons_mes_017.geometry} material={nodes.Buttons_mes_017.material} position={[0.1, -0.02, -1.03]} rotation={[1.56, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_018" geometry={nodes.Buttons_mes_018.geometry} material={nodes.Buttons_mes_018.material} position={[0.29, -0.02, -1.03]} rotation={[1.56, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_019" geometry={nodes.Buttons_mes_019.geometry} material={nodes.Buttons_mes_019.material} position={[0.48, -0.02, -1.03]} rotation={[1.56, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_020" geometry={nodes.Buttons_mes_020.geometry} material={nodes.Buttons_mes_020.material} position={[0.67, -0.02, -1.03]} rotation={[1.56, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_021" geometry={nodes.Buttons_mes_021.geometry} material={nodes.Buttons_mes_021.material} position={[0.85, -0.02, -1.03]} rotation={[1.56, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_022" geometry={nodes.Buttons_mes_022.geometry} material={nodes.Buttons_mes_022.material} position={[0.19, 0.11, -1.03]} rotation={[1.49, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_024" geometry={nodes.Buttons_mes_024.geometry} material={nodes.Buttons_mes_024.material} position={[-0.76, 0.11, -1.03]} rotation={[1.49, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_004" geometry={nodes.Buttons_mes_004.geometry} material={nodes.Buttons_mes_004.material} position={[0.48, 0.23, -1.05]} rotation={[1.5, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_005" geometry={nodes.Buttons_mes_005.geometry} material={nodes.Buttons_mes_005.material} position={[0.28, 0.23, -1.05]} rotation={[1.5, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_006" geometry={nodes.Buttons_mes_006.geometry} material={nodes.Buttons_mes_006.material} position={[0.1, 0.23, -1.05]} rotation={[1.5, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_007" geometry={nodes.Buttons_mes_007.geometry} material={nodes.Buttons_mes_007.material} position={[-0.85, 0.23, -1.05]} rotation={[1.5, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_008" geometry={nodes.Buttons_mes_008.geometry} material={nodes.Buttons_mes_008.material} position={[-0.66, 0.23, -1.05]} rotation={[1.5, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_009" geometry={nodes.Buttons_mes_009.geometry} material={nodes.Buttons_mes_009.material} position={[-0.47, 0.23, -1.05]} rotation={[1.5, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_010" geometry={nodes.Buttons_mes_010.geometry} material={nodes.Buttons_mes_010.material} position={[-0.28, 0.23, -1.05]} rotation={[1.5, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_011" geometry={nodes.Buttons_mes_011.geometry} material={nodes.Buttons_mes_011.material} position={[-0.09, 0.23, -1.05]} rotation={[1.5, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_012" geometry={nodes.Buttons_mes_012.geometry} material={nodes.Buttons_mes_012.material} position={[-0.85, -0.02, -1.03]} rotation={[1.56, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="Buttons_mes_013" geometry={nodes.Buttons_mes_013.geometry} material={nodes.Buttons_mes_013.material} position={[-0.66, -0.02, -1.03]} rotation={[1.56, 0, 0]} scale={[1.13, 1, 1.13]} />
              <mesh name="FB_BD_MES_3_support003" geometry={nodes.FB_BD_MES_3_support003.geometry} material={nodes.FB_BD_MES_3_support003.material} position={[-0.89, -0.51, -1.08]} />
              <mesh name="FB_BD_MES_3_support" geometry={nodes.FB_BD_MES_3_support.geometry} material={nodes.FB_BD_MES_3_support.material} position={[-0.89, -0.51, 1.06]} />
              <mesh name="FB_BD_MES_3_support001" geometry={nodes.FB_BD_MES_3_support001.geometry} material={nodes.FB_BD_MES_3_support001.material} position={[0.89, -0.51, 1.06]} />
              <mesh name="FB_BD_MES_3_support002" geometry={nodes.FB_BD_MES_3_support002.geometry} material={nodes.FB_BD_MES_3_support002.material} position={[0.89, -0.51, -1.08]} />
              <mesh name="Box013" geometry={nodes.Box013.geometry} material={nodes.Box013.material} position={[0, 0, 0.01]} />
              <mesh name="Box029" geometry={nodes.Box029.geometry} material={nodes.Box029.material} position={[0, -0.03, -1.16]} rotation={[Math.PI / 2, 0, 0]} />
              <mesh name="Prost" geometry={nodes.Prost.geometry} material={nodes.Prost.material} position={[0, -0.19, -0.49]} />
              <mesh name="Odeyalo" geometry={nodes.Odeyalo.geometry} material={nodes.Odeyalo.material} position={[0.01, -0.41, 0.44]} />
              <mesh name="Pattern2D_21998" geometry={nodes.Pattern2D_21998.geometry} material={nodes.Pattern2D_21998.material} position={[-0.38, 0.16, -0.87]} rotation={[1.31, -0.01, -0.02]} scale={0.86} />
              <mesh name="Pattern2D_21999" geometry={nodes.Pattern2D_21999.geometry} material={nodes.Pattern2D_21999.material} position={[-0.39, 0.14, -0.71]} rotation={[1.18, -0.01, -0.02]} scale={0.74} />
              <mesh name="Pattern2D_21993" geometry={nodes.Pattern2D_21993.geometry} material={nodes.Pattern2D_21993.material} position={[0.46, 0.14, -0.68]} rotation={[1.18, -0.01, -0.02]} scale={0.74} />
              <mesh name="Pattern2D_21997" geometry={nodes.Pattern2D_21997.geometry} material={nodes.Pattern2D_21997.material} position={[0.47, 0.16, -0.86]} rotation={[1.31, -0.01, -0.02]} scale={0.86} />
            </group>
          </group>
          <group name="Particle_View_01" position={[13.92, 0, -14.95]} />
          <group name="Particle_View_01_(1)" position={[11.93, 0, -16.95]} />
          <group name="Particle_View_01_(2)" position={[18.44, 0, -17.57]} rotation={[0, 1.57, 0]} />
          <group name="Particle_View_002" position={[61.86, 0, -2.56]} rotation={[0, -Math.PI / 6, 0]} />
          <group name="Particle_View_01_(3)" position={[67.68, 0, -5.97]} />
          <group name="Particle_View_01_(4)" position={[66.66, 0, -5.97]} />
          <group name="arch24_058_001" position={[301.81, -0.96, -19.92]} rotation={[0, -1.57, 0]} scale={[0.86, 1, 1]} />
          <group name="arch24_059_001" position={[301.77, -0.96, -19.99]} rotation={[Math.PI, -0.26, Math.PI]} scale={[0.99, 1, 0.87]} />
          <group name="arch24_060_018" position={[301.87, -0.96, -20.01]} rotation={[0, 1.13, 0]} scale={[0.89, 1, 0.98]} />
          <group name="arch24_062_003" position={[301.84, -0.96, -19.97]} scale={[1, 1, 0.86]} />
          <group name="arch24_062_002" position={[301.84, -0.96, -19.97]} scale={[1, 1, 0.86]} />
          <group name="arch24_063_006" position={[301.84, -0.96, -19.97]} scale={[1, 1, 0.86]} />
          <group name="Particle_View_01_(5)" position={[306.94, -0.96, -16.06]} rotation={[0, -1.57, 0]} scale={[0.86, 1, 1]} />
          <group name="arch24_058_002" position={[301.81, -0.96, -19.92]} rotation={[0, -1.57, 0]} scale={[0.86, 1, 1]} />
          <group name="arch24_059_002" position={[301.77, -0.96, -19.99]} rotation={[Math.PI, -0.26, Math.PI]} scale={[0.99, 1, 0.87]} />
          <group name="arch24_060_019" position={[301.87, -0.96, -20.01]} rotation={[0, 1.13, 0]} scale={[0.89, 1, 0.98]} />
          <group name="arch24_062_004" position={[301.84, -0.96, -19.97]} scale={[1, 1, 0.86]} />
          <group name="arch24_062_005" position={[301.84, -0.96, -19.97]} scale={[1, 1, 0.86]} />
          <group name="arch24_063_007" position={[301.84, -0.96, -19.97]} scale={[1, 1, 0.86]} />
          <group name="Particle_View_002_(1)" position={[306.94, -0.96, -16.06]} rotation={[0, -1.57, 0]} scale={[0.86, 1, 1]} />
          <group name="arch24_063_05" position={[301.07, 0.78, -9.03]} rotation={[-Math.PI, 0, 0]} scale={[1, -1, 1]} />
          <group name="arch24_062_00" position={[301.07, 0.78, -9.03]} rotation={[-Math.PI, 0, 0]} scale={[1, -1, 1]} />
          <group name="arch24_062_01" position={[301.07, 0.78, -9.03]} rotation={[-Math.PI, 0, 0]} scale={[1, -1, 1]} />
          <group name="arch24_060_17" position={[301.1, 0.78, -8.98]} rotation={[-Math.PI, 1.13, 0]} scale={[1, -1, 1]} />
          <group name="arch24_059_00" position={[300.99, 0.78, -9.01]} rotation={[0, -0.26, -Math.PI]} scale={[1, -1, 1]} />
          <group name="arch24_057_00" position={[301.07, 0.78, -9.03]} rotation={[-Math.PI, 0, 0]} scale={[1, -1, 1]} />
          <group name="arch24_056_00" position={[301.05, 0.78, -9.05]} rotation={[0, -1.4, Math.PI]} scale={[1, -1, 1]} />
          <group name="Particle_View_01_(6)" position={[66.66, 0, -5.97]} />
          <group name="Particle_View_01_(7)" position={[66.66, 0, -5.97]} />
          <group name="Particle_View_01_(8)" position={[66.66, 0, -5.97]} />
          <group name="Particle_View_01_(9)" position={[66.66, 0, -5.97]} />
          <group name="Particle_View_01_(10)" position={[66.66, 0, -5.97]} />
          <group name="Particle_View_01_(11)" position={[66.66, 0, -5.97]} />
          <group name="Particle_View_01_(12)" position={[66.66, 0, -5.97]} />
          <group name="Particle_View_01_(13)" position={[66.66, 0, -5.97]} />
          <PerspectiveCamera name="CoronaCamera003" makeDefault={false} far={15800} near={2510.31} fov={64.15} position={[3.24, 1.51, -1.6]} rotation={[3.09, 1.09, -3.1]} />
          <PerspectiveCamera name="CoronaCamera005" makeDefault={false} far={18236} near={2744.14} fov={55} position={[4.46, 1.6, 0.13]} rotation={[3.14, 1.57, -3.14]} />
          <mesh name="Plane001" geometry={nodes.Plane001.geometry} material={nodes.Plane001.material} position={[-0.6, 0, -2.7]} />
          <mesh name="Object072" geometry={nodes.Object072.geometry} material={nodes.Object072.material} position={[-0.06, 0.54, 2.43]} />
          <mesh name="Object5578" geometry={nodes.Object5578.geometry} material={nodes.Object5578.material} position={[0.64, 1.26, -2.67]} rotation={[Math.PI / 2, 0, -Math.PI]} scale={[0.67, -0.67, 0.67]} />
          <mesh name="Box7171" geometry={nodes.Box7171.geometry} material={nodes.Box7171.material} position={[0.93, -0.03, -2.71]} />
          <mesh name="Object5579" geometry={nodes.Object5579.geometry} material={nodes.Object5579.material} position={[-0.6, 0, -2.7]} />
          <mesh name="Ceilinng" geometry={nodes.Ceilinng.geometry} material={nodes.Ceilinng.material} position={[0.06, 3.42, 0.07]} />
          <mesh name="Box7179" geometry={nodes.Box7179.geometry} material={nodes.Box7179.material} position={[0.51, 1.36, -2.74]} rotation={[Math.PI / 2, 0, Math.PI]} />
          <mesh name="Object0012" geometry={nodes.Object0012.geometry} material={nodes.Object0012.material} position={[1.24, 0, 0.71]} rotation={[0, 1.57, 0]} />
          <mesh name="reh_3a" geometry={nodes.reh_3a.geometry} material={nodes.reh_3a.material} position={[-0.61, 0.23, 2.1]} rotation={[0, Math.PI / 2, 0]} />
          <mesh name="Rectangle022" geometry={nodes.Rectangle022.geometry} material={nodes.Rectangle022.material} position={[0.16, 3.26, 0.04]} />
          <mesh name="Box7180" geometry={nodes.Box7180.geometry} material={nodes.Box7180.material} position={[-1.75, 0, 1.99]} />
          <mesh name="Box7181" geometry={nodes.Box7181.geometry} material={nodes.Box7181.material} position={[1.49, 0, 1.99]} />
          <mesh name="Box7191" geometry={nodes.Box7191.geometry} material={nodes.Box7191.material} position={[-2.05, 3.54, -1.38]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="Plane4244" geometry={nodes.Plane4244.geometry} material={nodes.Plane4244.material} position={[7.88, 0, 2.03]} />
          <mesh name="Plane4245" geometry={nodes.Plane4245.geometry} material={nodes.Plane4245.material} position={[5.07, 0, -2.66]} />
          <mesh name="Line002" geometry={nodes.Line002.geometry} material={nodes.Line002.material} position={[5.01, 0.15, -2.95]} />
          <mesh name="Line133910300" geometry={nodes.Line133910300.geometry} material={nodes.Line133910300.material} position={[8.33, 0.6, -6.63]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910305" geometry={nodes.Line133910305.geometry} material={nodes.Line133910305.material} position={[8.33, 0.6, 0.36]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910306" geometry={nodes.Line133910306.geometry} material={nodes.Line133910306.material} position={[8.33, 0.6, 0.92]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910307" geometry={nodes.Line133910307.geometry} material={nodes.Line133910307.material} position={[7.91, 0.6, -6.63]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910308" geometry={nodes.Line133910308.geometry} material={nodes.Line133910308.material} position={[7.91, 0.6, 0.93]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910309" geometry={nodes.Line133910309.geometry} material={nodes.Line133910309.material} position={[8.33, 0.6, -0.95]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910310" geometry={nodes.Line133910310.geometry} material={nodes.Line133910310.material} position={[8.33, 0.6, -1.26]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910313" geometry={nodes.Line133910313.geometry} material={nodes.Line133910313.material} position={[8.33, 0.6, -0.63]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910314" geometry={nodes.Line133910314.geometry} material={nodes.Line133910314.material} position={[8.33, 0.6, -0.31]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910315" geometry={nodes.Line133910315.geometry} material={nodes.Line133910315.material} position={[8.33, 0.6, 0.02]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910316" geometry={nodes.Line133910316.geometry} material={nodes.Line133910316.material} position={[8.33, 0.6, 0.66]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910317" geometry={nodes.Line133910317.geometry} material={nodes.Line133910317.material} position={[8.33, 0.6, -2.33]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910318" geometry={nodes.Line133910318.geometry} material={nodes.Line133910318.material} position={[8.33, 0.6, -3.64]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910320" geometry={nodes.Line133910320.geometry} material={nodes.Line133910320.material} position={[8.33, 0.6, -3.32]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910321" geometry={nodes.Line133910321.geometry} material={nodes.Line133910321.material} position={[8.33, 0.6, -3]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910322" geometry={nodes.Line133910322.geometry} material={nodes.Line133910322.material} position={[8.33, 0.6, -2.67]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910323" geometry={nodes.Line133910323.geometry} material={nodes.Line133910323.material} position={[8.33, 0.6, -2.03]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910324" geometry={nodes.Line133910324.geometry} material={nodes.Line133910324.material} position={[8.33, 0.6, -5.03]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910325" geometry={nodes.Line133910325.geometry} material={nodes.Line133910325.material} position={[8.33, 0.6, -6.34]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910326" geometry={nodes.Line133910326.geometry} material={nodes.Line133910326.material} position={[8.33, 0.6, -6.02]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910327" geometry={nodes.Line133910327.geometry} material={nodes.Line133910327.material} position={[8.33, 0.6, -5.7]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910328" geometry={nodes.Line133910328.geometry} material={nodes.Line133910328.material} position={[8.33, 0.6, -5.37]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910329" geometry={nodes.Line133910329.geometry} material={nodes.Line133910329.material} position={[8.33, 0.6, -4.73]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910330" geometry={nodes.Line133910330.geometry} material={nodes.Line133910330.material} position={[8.33, 0.6, -4.44]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Shape010" geometry={nodes.Shape010.geometry} material={nodes.Shape010.material} position={[8.42, 0.96, -4.27]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[1, -1, 1]} />
          <mesh name="Plane1848" geometry={nodes.Plane1848.geometry} material={nodes.Plane1848.material} position={[7.81, 1.56, -5.3]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="Plane1849" geometry={nodes.Plane1849.geometry} material={nodes.Plane1849.material} position={[7.81, 1.56, -2.86]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="Plane1850" geometry={nodes.Plane1850.geometry} material={nodes.Plane1850.material} position={[7.81, 1.56, -0.32]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="Object5576" geometry={nodes.Object5576.geometry} material={nodes.Object5576.material} position={[8.42, 0.45, -2.83]} />
          <mesh name="Plane1852" geometry={nodes.Plane1852.geometry} material={nodes.Plane1852.material} position={[4.53, 0.58, -7.03]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Line133910333" geometry={nodes.Line133910333.geometry} material={nodes.Line133910333.material} position={[4, 0, -8.4]} scale={0.25} />
          <mesh name="Door-case-A_006" geometry={nodes['Door-case-A_006'].geometry} material={nodes['Door-case-A_006'].material} position={[1.84, 1.31, -1.78]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[1, 0.8, 1]} />
          <mesh name="Object10424742" geometry={nodes.Object10424742.geometry} material={nodes.Object10424742.material} position={[1.85, 1.26, -1.5]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
          <mesh name="Door-case-A_007" geometry={nodes['Door-case-A_007'].geometry} material={nodes['Door-case-A_007'].material} position={[1.86, 1.31, -0.91]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
          <mesh name="Box3824" geometry={nodes.Box3824.geometry} material={nodes.Box3824.material} position={[1.85, 1.37, -6.37]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="Object10424743" geometry={nodes.Object10424743.geometry} material={nodes.Object10424743.material} position={[1.84, 1.26, -7.03]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
          <mesh name="www_mzgi_ru_M09_115x40mm" geometry={nodes.www_mzgi_ru_M09_115x40mm.geometry} material={nodes.www_mzgi_ru_M09_115x40mm.material} position={[8.47, 0, -7.55]} rotation={[0, 0, Math.PI / 2]} />
          <mesh name="Plane001_(1)" geometry={nodes['Plane001_(1)'].geometry} material={nodes['Plane001_(1)'].material} position={[7.14, 1.5, -3.81]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.04, 0.02, 0.04]} />
          <mesh name="Plane1856" geometry={nodes.Plane1856.geometry} material={nodes.Plane1856.material} position={[7.14, 1.5, -1.37]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.04, 0.02, 0.04]} />
          <mesh name="Plane002" geometry={nodes.Plane002.geometry} material={nodes.Plane002.material} position={[7.14, 1.5, 0.56]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.04, 0.02, 0.04]} />
          <mesh name="Plane4246" geometry={nodes.Plane4246.geometry} material={nodes.Plane4246.material} position={[7.15, 2.92, -3.03]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[2.26, 1.52, 2.26]} />
          <mesh name="Plane1858" geometry={nodes.Plane1858.geometry} material={nodes.Plane1858.material} position={[7.15, 2.92, -0.59]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[2.26, 1.52, 2.26]} />
          <mesh name="Plane4247" geometry={nodes.Plane4247.geometry} material={nodes.Plane4247.material} position={[7.14, 1.5, -7.24]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.04, 0.02, 0.04]} />
          <mesh name="Plane1859" geometry={nodes.Plane1859.geometry} material={nodes.Plane1859.material} position={[7.15, 2.92, -5.49]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[2.26, 1.52, 2.26]} />
          <mesh name="Line133910335" geometry={nodes.Line133910335.geometry} material={nodes.Line133910335.material} position={[2.78, 1.66, -7.58]} scale={0.34} />
          <mesh name="Line133910336" geometry={nodes.Line133910336.geometry} material={nodes.Line133910336.material} position={[6.28, 1.66, -7.58]} scale={0.34} />
          <mesh name="Ceiling" geometry={nodes.Ceiling.geometry} material={nodes.Ceiling.material} position={[5.07, 3.6, -2.66]} />
          <mesh name="Cylinder737" geometry={nodes.Cylinder737.geometry} material={nodes.Cylinder737.material} position={[6.13, 0, -2.44]} scale={1.48} />
          <mesh name="Plane101" geometry={nodes.Plane101.geometry} material={nodes.Plane101.material} position={[3.88, -1.25, -2.09]} rotation={[Math.PI / 2, 0, 0]} scale={[0.95, 1, 1]} />
          <mesh name="Plane148" geometry={nodes.Plane148.geometry} material={nodes.Plane148.material} position={[5.15, -1.25, -2.09]} rotation={[Math.PI / 2, 0, 0]} scale={[0.95, 1, 1]} />
          <mesh name="Plane147" geometry={nodes.Plane147.geometry} material={nodes.Plane147.material} position={[4.49, 0, -4.26]} />
          <mesh name="Line133910343" geometry={nodes.Line133910343.geometry} material={nodes.Line133910343.material} position={[4.53, 0.58, -6.84]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Object5586" geometry={nodes.Object5586.geometry} material={nodes.Object5586.material} position={[4.53, 0.58, -7.03]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Plane2289" geometry={nodes.Plane2289.geometry} material={nodes.Plane2289.material} position={[2.08, 2.93, -1.51]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="Plane2290" geometry={nodes.Plane2290.geometry} material={nodes.Plane2290.material} position={[2.08, 2.93, -7.07]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="Line133910344" geometry={nodes.Line133910344.geometry} material={nodes.Line133910344.material} position={[3.02, 0.01, -3.5]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.73, 0.73, 0.99]} />
          <mesh name="Plane2291" geometry={nodes.Plane2291.geometry} material={nodes.Plane2291.material} position={[4.62, 0, 1.63]} rotation={[Math.PI / 2, 0, -Math.PI]} scale={2.56} />
          <mesh name="Object5587" geometry={nodes.Object5587.geometry} material={nodes.Object5587.material} position={[1.93, 2.08, -1.48]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={8.34} />
          <mesh name="Object5588" geometry={nodes.Object5588.geometry} material={nodes.Object5588.material} position={[3, 1.66, 1.92]} rotation={[Math.PI / 2, 0, Math.PI]} scale={8.34} />
          <mesh name="Box3825" geometry={nodes.Box3825.geometry} material={nodes.Box3825.material} position={[4.55, 1.91, 1.89]} rotation={[Math.PI / 2, 0, 0]} scale={[0.84, 1, 0.84]} />
          <mesh name="Object5589" geometry={nodes.Object5589.geometry} material={nodes.Object5589.material} position={[6.28, 1.66, -7.58]} scale={0.34} />
          <mesh name="ArteVineziana6741w110h170" geometry={nodes.ArteVineziana6741w110h170.geometry} material={nodes.ArteVineziana6741w110h170.material} position={[4.53, 1.98, -7.96]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Box3795" geometry={nodes.Box3795.geometry} material={nodes.Box3795.material} position={[3.67, 0.35, -1.26]} rotation={[0, -1.23, 0]} />
          <mesh name="Box3827" geometry={nodes.Box3827.geometry} material={nodes.Box3827.material} position={[4.26, 0.35, -1.37]} rotation={[0, -1.48, 0]} />
          <mesh name="Box3828" geometry={nodes.Box3828.geometry} material={nodes.Box3828.material} position={[4.96, 0.35, -1.4]} rotation={[Math.PI, -1.56, Math.PI]} />
          <mesh name="Box3829" geometry={nodes.Box3829.geometry} material={nodes.Box3829.material} position={[5.59, 0.35, -1.25]} rotation={[Math.PI, -1.14, Math.PI]} />
          <mesh name="Box3830" geometry={nodes.Box3830.geometry} material={nodes.Box3830.material} position={[5.69, 0.35, 0.1]} rotation={[-Math.PI, 1.13, -Math.PI]} />
          <mesh name="Box3831" geometry={nodes.Box3831.geometry} material={nodes.Box3831.material} position={[4.94, 0.35, 0.3]} rotation={[-Math.PI, 1.49, -Math.PI]} />
          <mesh name="Box3832" geometry={nodes.Box3832.geometry} material={nodes.Box3832.material} position={[4.14, 0.35, 0.27]} rotation={[0, 1.4, 0]} />
          <mesh name="Box3833" geometry={nodes.Box3833.geometry} material={nodes.Box3833.material} position={[3.4, 0.35, 0.06]} rotation={[0, 1.14, 0]} />
          <mesh name="Table" geometry={nodes.Table.geometry} material={nodes.Table.material} position={[4.63, 0, -0.54]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={3.85} />
          <mesh name="Recumbent_Figure" geometry={nodes.Recumbent_Figure.geometry} material={nodes.Recumbent_Figure.material} position={[4.3, 0.87, 1.56]} rotation={[-Math.PI, 0, 0]} scale={[0.5, -0.5, 0.5]} />
          <mesh name="Box3837" geometry={nodes.Box3837.geometry} material={nodes.Box3837.material} position={[4.43, 1.87, -6.99]} rotation={[Math.PI / 2, 0, 0]} scale={[0.85, 1, 0.85]} />
          <mesh name="Object5591" geometry={nodes.Object5591.geometry} material={nodes.Object5591.material} position={[4.43, 1.87, -6.99]} rotation={[Math.PI / 2, 0, 0]} scale={[0.85, 1, 0.85]} />
          <mesh name="Plane2310" geometry={nodes.Plane2310.geometry} material={nodes.Plane2310.material} position={[4.69, 0, -6.7]} />
          <mesh name="Plane2311" geometry={nodes.Plane2311.geometry} material={nodes.Plane2311.material} position={[4.25, 0, -4.36]} />
          <mesh name="POT" geometry={nodes.POT.geometry} material={nodes.POT.material} position={[4.43, 0.76, -0.63]} scale={0.84} />
          <mesh name="BRONZE_PLATE" geometry={nodes.BRONZE_PLATE.geometry} material={nodes.BRONZE_PLATE.material} position={[4.11, 0.76, -0.49]} rotation={[1.57, 0, -Math.PI / 2]} scale={[0.97, 0.97, 1]} />
          <mesh name="Stellag_1_283" geometry={nodes.Stellag_1_283.geometry} material={nodes.Stellag_1_283.material} position={[5.32, -0.5, -2.07]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={1.25} />
          <mesh name="Stellag_1_284" geometry={nodes.Stellag_1_284.geometry} material={nodes.Stellag_1_284.material} position={[3.99, -1.17, -2.13]} rotation={[Math.PI / 2, 1.57, 0]} scale={[1.25, 1, 1]} />
          <mesh name="Stellag_1_285" geometry={nodes.Stellag_1_285.geometry} material={nodes.Stellag_1_285.material} position={[3.88, -0.5, -2.07]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1.13, 0.82, 0.8]} />
          <mesh name="Stellag_1_286" geometry={nodes.Stellag_1_286.geometry} material={nodes.Stellag_1_286.material} position={[5.13, 0.4, -4.67]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={1.25} />
          <mesh name="Stellag_1_287" geometry={nodes.Stellag_1_287.geometry} material={nodes.Stellag_1_287.material} position={[3.98, 0.4, -4.64]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={[1.25, 1.28, 1.28]} />
          <mesh name="Stellag_1_288" geometry={nodes.Stellag_1_288.geometry} material={nodes.Stellag_1_288.material} position={[5.13, 0.4, -4.32]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1.13, 0.82, 0.8]} />
          <mesh name="Stellag_1_289" geometry={nodes.Stellag_1_289.geometry} material={nodes.Stellag_1_289.material} position={[5.13, 0.4, -3.95]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={1.25} />
          <mesh name="Stellag_1_290" geometry={nodes.Stellag_1_290.geometry} material={nodes.Stellag_1_290.material} position={[4.66, 0.38, -3.98]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1.25, 1.28, 1.28]} />
          <mesh name="Stellag_1_291" geometry={nodes.Stellag_1_291.geometry} material={nodes.Stellag_1_291.material} position={[4.28, 0.21, -4.57]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={1.25} />
          <mesh name="Object10424677" geometry={nodes.Object10424677.geometry} material={nodes.Object10424677.material} position={[1.85, 1.31, 3.65]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1, -1, 1]} />
          <mesh name="Object10424678" geometry={nodes.Object10424678.geometry} material={nodes.Object10424678.material} position={[1.86, 1.31, -1.79]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="2***60*60*3*591" geometry={nodes['2***60*60*3*591'].geometry} material={nodes['2***60*60*3*591'].material} position={[2.1, 1.44, -3.75]} rotation={[0, 1.57, 0]} scale={[1.95, 1.95, 0.78]} />
          <mesh name="2***60*60*3*592" geometry={nodes['2***60*60*3*592'].geometry} material={nodes['2***60*60*3*592'].material} position={[2.1, 2.06, -3.75]} rotation={[0, 1.57, 0]} scale={[1.95, 1.95, 0.78]} />
          <mesh name="2***60*60*3*593" geometry={nodes['2***60*60*3*593'].geometry} material={nodes['2***60*60*3*593'].material} position={[2.1, 1.44, -3]} rotation={[0, 1.57, 0]} scale={[1.95, 1.95, 0.78]} />
          <mesh name="2***60*60*3*594" geometry={nodes['2***60*60*3*594'].geometry} material={nodes['2***60*60*3*594'].material} position={[2.1, 2.06, -3]} rotation={[0, 1.57, 0]} scale={[1.95, 1.95, 0.78]} />
          <mesh name="Box7195" geometry={nodes.Box7195.geometry} material={nodes.Box7195.material} position={[-3.86, 0, -5.14]} scale={[0.45, 1, 1]} />
          <mesh name="Box7196" geometry={nodes.Box7196.geometry} material={nodes.Box7196.material} position={[1.94, -0.05, 2.81]} />
          <mesh name="Plane4248" geometry={nodes.Plane4248.geometry} material={nodes.Plane4248.material} position={[1.86, 0, -1.01]} />
          <mesh name="Plane4253" geometry={nodes.Plane4253.geometry} material={nodes.Plane4253.material} position={[-0.06, 0, 2.67]} />
          <mesh name="Plane4261" geometry={nodes.Plane4261.geometry} material={nodes.Plane4261.material} position={[2.88, 2.12, 1.9]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="Box7253" geometry={nodes.Box7253.geometry} material={nodes.Box7253.material} position={[1.64, 0, -2.33]} />
          <mesh name="Object5600" geometry={nodes.Object5600.geometry} material={nodes.Object5600.material} position={[-0.36, 0, -0.22]} />
          <mesh name="Line001" geometry={nodes.Line001.geometry} material={nodes.Line001.material} position={[1.77, 1.97, 0.14]} rotation={[Math.PI, 0, Math.PI]} />
          <mesh name="Side_Table" geometry={nodes.Side_Table.geometry} material={nodes.Side_Table.material} position={[0.76, 0.01, 1.62]} rotation={[0, 0, -Math.PI]} scale={[1, -1, 1]} />
          <mesh name="Box030" geometry={nodes.Box030.geometry} material={nodes.Box030.material} position={[-2.06, 1.81, -0.11]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={1.65} />
          <mesh name="pillows_004" geometry={nodes.pillows_004.geometry} material={nodes.pillows_004.material} position={[1.35, 0.63, 1.38]} rotation={[0.06, -0.52, 3.03]} scale={[0.64, -0.64, 0.64]} />
          <mesh name="Plane2126" geometry={nodes.Plane2126.geometry} material={nodes.Plane2126.material} position={[-0.88, 0, -0.24]} rotation={[-Math.PI, 0, -Math.PI]} />
        </group>
      </group>
    )
  }
  