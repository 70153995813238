import { Canvas } from '@react-three/fiber'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Suspense } from "react";
import { useGLTF } from '@react-three/drei'


export function Model(props) {
  const { nodes, materials } = useGLTF('/123456789.gltf')
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0, 0]}>
        <group position={[0.01, 0, 0]}>
          <mesh geometry={nodes.vertex.geometry} material={materials.newArbat} position={[-14.91, -6.49, 11.03]} rotation={[0, 1.57, 0]} />
          <mesh geometry={nodes.doubleside.geometry} material={materials['14 - Default']} position={[12.28, -6.86, 9.13]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.lightmap.geometry} material={materials.newArbat} position={[-10.8, -3.04, 10.92]} />
        </group>
      </group>
    </group>
  )
}