import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";




export default function Navbar() {
  return (
    <Stack spacing={2} direction="row">
      <Link to='/'><Button variant="contained">Вариант 1</Button></Link>
      <Link to='/home1'><Button variant="contained">Вариант 2</Button></Link>
      <Link to='/home2'> <Button variant="contained">Вариант 3</Button></Link>
    </Stack>
  );
}